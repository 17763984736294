import { useCallback, useEffect, useState } from 'react'
import { getEdificios } from '../apis/TurnosAPI'

const defaultSelFecha = [{ eID: 0, Nombre: 'Seleccionar Fecha y gerencia', Direccion: '' }]

export const useEdificios = (fechaAux, IdGerencia) => {
    const [edificios, setEdificios] = useState(defaultSelFecha)
    const [loadingEdificios, setLoadingEdificios] = useState(false)

    const cargarEdificios = useCallback(() => {
        if (IdGerencia) {
            setLoadingEdificios(true)
            getEdificios(fechaAux, IdGerencia)
                .then(resp => {
                    setEdificios(resp.data)
                    setLoadingEdificios(false)
                })
                .catch(err => {
                    console.error(err.message)
                    setLoadingEdificios(false)
                })
        }
    }, [IdGerencia, fechaAux])

    useEffect(() => {
        cargarEdificios()
    }, [fechaAux, IdGerencia, cargarEdificios])

    return { edificios, cargarEdificios, loadingEdificios }
}
