import { ErrorMessage, useField } from 'formik';
import { PropTypes } from 'prop-types';
export const MyCheckbox = ({ label, ...props } ) => {

    const [ field ] = useField({ ...props, type: 'checkbox' });

    return (
        <>
            <label>
                <input type="checkbox" { ...field } { ...props } />
                { label }            
            </label>
            <ErrorMessage name={ props.name } component="em" className='error-message'/>
        </>
    )
}

MyCheckbox.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
}