import { v4 as uuid } from 'uuid';

export default [{
  id: uuid(),
  country: 'piso 1',
  value: 13
}, {
  id: uuid(),
  country: 'piso 2',
  value: 25
}, {
  id: uuid(),
  country: 'piso 3',
  value: 19
}, {
  id: uuid(),
  country: 'piso 4',
  value: 12
}, {
  id: uuid(),
  country: 'piso 5',
  value: 22
}, {
  id: uuid(),
  country: 'piso 6',
  value: 13
}, {
  id: uuid(),
  country: 'piso 7',
  value: 3
}, {
  id: uuid(),
  country: 'piso 12',
  value: 2
}, {
  id: uuid(),
  country: 'Sin posicion',
  value: 14
}];
