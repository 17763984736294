import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Container from "@material-ui/core/Container"
import { style } from '../../styles/StylesObject'
import { MenuItem, Button } from '@material-ui/core'
import { useTranslation } from "react-i18next";
import { FormSelect } from '../../components/FormSelect'
export function DesactivarUsuario({formik, usuarios}) {
        const { t } = useTranslation();

  return (
      <div>
        <br />
        <Container maxWidth="sm">
          <h1>Reporte</h1>
          <p >Desactivar Usuario</p>
          <br />
          <form onSubmit={formik.handleSubmit}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}>

            <FormSelect
            listaVisible={2}
            formik={formik}
            id="reporte"
            formikValue={formik.values.reporte}
            text={t("reportes>label>choseReport")}

            >
              {/* // solamente se muestra turnos por dia Y reservaciones, se siguen teniendo todos los reportes ocultos. */}
                    <MenuItem
                      style={style.MenuItemStyle}
                      key={`reporte_5`}
                      value={6}
                      
                    >
                      {'Desactivar Usuario'}
                    </MenuItem>
            </FormSelect>
            <FormSelect 
              lista={usuarios}
              listaVisible={2}
              formik={formik}
              id="usuario"
              formikValue={formik.values.usuario}
              text={t("reportes>label>choseCollaborator")}
              >
                {usuarios &&
                    usuarios.map((usuario) => usuario.DNI && (
                      <MenuItem
                        style={style.MenuItemStyle}
                        key={`usuario_${usuario.IdUsuario}`}
                        value={usuario.IdUsuario}
                      >
                        {usuario.Nombre+' - ('+usuario.Email+')'}
                      </MenuItem>
                ))}
              </FormSelect>
            <div style={{ marginTop: "3%", alignSelf: "center" }}>
              <Button
                  style={{ alignSelf: "center", textTransform: "none", textAlign: "center", backgroundColor: "#2f4050", color: "white" }}
                  variant="contained"
                  type='submit'>{t("reportes>button>confirm")}
              </Button>
            </div>
          </form>
        </Container>
      </div>
);
}

DesactivarUsuario.propTypes = {}


