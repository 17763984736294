import React, { useEffect, useState } from "react";
import {
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Button,
    Input,
    FormHelperText,
    FormLabel
} from "@material-ui/core";
import ReactLoading from 'react-loading';
import { makeStyles } from "@material-ui/core/styles";
import { Container, Checkbox, FormGroup, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useFormik } from "formik";
import * as Api from '../../apis/DiagnosticosAPI';
import { useAlert } from 'react-alert';
import { getVacunasinvitados } from "../../apis/VacunasAPI";
import Header from "../../shared/Header";
import { Redirect } from "react-router";
import { useTranslation } from "react-i18next";



const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    btn: {
        alignSelf: "center",
        textTransform: "none",
        textAlign: 'center',
        backgroundColor: "#2f4050",
        color: "white"
    }
}));

const TEMPERATURA = "chktemperatura";
const GUSTO = "chkgusto";
const CONTACTO = "chkcontacto";
const EMBARAZADA = "chkembarazada";
const CANCER = "chkcancer";
const DIABETES = "chkdiabetes";
const HEPATICA = "chkhepatica";
const OLFATO = "chkolfato";
const GARGANTA = "chkgarganta";
const RESPIRATORIA = "chkrespiratoria";
const SINSINTOMAS = "chksinsintomas";
const VACUNADO = "chkvacunado";
const TIPOVACUNA = "chktipoVacuna";
const DOSDOSIS = "chkdosDosis";
const QUIEROVACUNARME = "chkquieroVacunarme";
// const INFOACTUALIZADA = "chkinfoActualizada";

const defaultSel = [{ Id: 0, Nombre: 'Seleccionar Vacuna' }]

const Invitado = (props) => {
    const { t } = useTranslation();
    const alert = useAlert();
    const classes = useStyles();
    const [done, setDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false)

    const guardarDiagnostico = (temperatura, gusto, contacto, embarazada, cancer, diabetes, hepatica, olfato, garganta, respiratoria, sinsintomas, vacunado, tipoVacuna, dosDosis, quieroVacunarme, apellido, email, empresa) => {
        if (sinsintomas && (temperatura || gusto || contacto || embarazada || cancer || diabetes || hepatica || olfato || garganta || respiratoria)) {
            alert.show(`${t("invitados>alert>notSymptom")}`);
            setSubmitting(false)
            return;
        }

        if (!sinsintomas && !temperatura && !gusto && !contacto && !embarazada && !cancer && !diabetes && !hepatica && !olfato && !garganta && !respiratoria) {
            alert.show(`${t("invitados>alert>selectSymptom")}`);
            setSubmitting(false)
            return;
        }

        Api.saveInvitado(temperatura, gusto, contacto, embarazada, cancer, diabetes, hepatica, olfato, garganta, respiratoria, vacunado, tipoVacuna, dosDosis, quieroVacunarme, apellido, email, empresa)
            .then(response => {
                alert.show(`${t("invitados>alert>sentDiagnosis")}`);
                setSubmitting(false)
                setDone(true);
            })
            .catch(function (error) {
                setSubmitting(false)
                if (error.response == undefined)
                    alert.show("" + error);
                else
                    alert.show("" + error.response.data.error);
            });
    };


    return (
        <div>
            <Header />
            {loading || submitting ? (
                <Container maxWidth="sm">
                    <ReactLoading type={"spin"} color={"#fff"} height={'50px'} width={'50px'} />
                </Container>
            ) : (
                <div>
                    <Container className='HomeDescr' maxWidth="sm">
                        <h1 className='ExpertaText'>{t("invitados>title>guests")}</h1>
                        <div className={classes.root}>
                            <DiagnosticoForm
                                guardarDiagnostico={guardarDiagnostico}
                                handleSubmitting={() => setSubmitting(true)}
                            />
                        </div>
                    </Container>
                </div>)}
            {done ? <Redirect to="/MisReservas" /> : null}
        </div>
    );
}

const DiagnosticoForm = ({ guardarDiagnostico, handleSubmitting }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [vacunas, setVacunas] = useState(defaultSel);
    //vacuna
    const [vacunado, setVacunado] = useState(false)
    const [tipoVacuna, setTipoVacuna] = useState(null)
    const [dosDosis, setDosDosis] = useState(false)
    const [quieroVacunarme, setQuieroVacunarme] = useState(false)
    //sintomas
    const [temperatura, setTemperatura] = useState(false)
    const [gusto, setGusto] = useState(false)
    const [contacto, setContacto] = useState(false);
    const [embarazada, setEmbarazada] = useState(false);
    const [cancer, setCancer] = useState(false);
    const [diabetes, setDiabetes] = useState(false);
    const [hepatica, setHepatica] = useState(false);
    const [olfato, setOlfato] = useState(false);
    const [garganta, setGarganta] = useState(false);
    const [respiratoria, setRespiratoria] = useState(false);
    const [sinsintomas, setSinSintomas] = useState(false);

    const setConfig = (nombreConfig, valorConfig) => {

        switch (nombreConfig) {
            case TEMPERATURA:
                setTemperatura(valorConfig);
                break;
            case GUSTO:
                setGusto(valorConfig);
                break;
            case CONTACTO:
                setContacto(valorConfig);
                break;
            case EMBARAZADA:
                setEmbarazada(valorConfig);
                break;
            case CANCER:
                setCancer(valorConfig);
                break;
            case DIABETES:
                setDiabetes(valorConfig);
                break;
            case HEPATICA:
                setHepatica(valorConfig);
                break;
            case OLFATO:
                setOlfato(valorConfig);
                break;
            case GARGANTA:
                setGarganta(valorConfig);
                break;
            case RESPIRATORIA:
                setRespiratoria(valorConfig);
                break;
            case SINSINTOMAS:
                setSinSintomas(valorConfig);
                break;
            case VACUNADO:
                setVacunado(valorConfig);
                setQuieroVacunarme(false);
                break;
            case TIPOVACUNA:
                setTipoVacuna(valorConfig);
                break;
            case DOSDOSIS:
                setDosDosis(valorConfig);
                break;
            case QUIEROVACUNARME:
                setQuieroVacunarme(valorConfig);
        }
    }

    const handleChange = name => event => {
        setConfig(name, event.target.checked)
    };

    const onChangeValueRadio = (event) => {
        if ("chkquierovacuna" == event.target.value) {
            setQuieroVacunarme(true);
            setDosDosis(false);
            setTipoVacuna(null);
        }
        else {
            setQuieroVacunarme(false);
            setDosDosis(false);
            setTipoVacuna(null);
        }
    }

    function cargarVacunas() {
        getVacunasinvitados().then(res => setVacunas(res.data))
    }

    useEffect(() => {
        cargarVacunas();
    }, []);

    const formik = useFormik({
        initialValues: {
            temperatura: false,
            gusto: false,
            contacto: false,
            embarazada: false,
            cancer: false,
            diabetes: false,
            hepatica: false,
            olfato: false,
            garganta: false,
            respiratoria: false,
            sinsintomas: false,
            apellido: '',
            email: '',
            empresa: ''
        },
        onSubmit: (values) => {
            const { apellido, email, empresa } = values
            handleSubmitting()
            guardarDiagnostico(temperatura, gusto, contacto, embarazada, cancer, diabetes, hepatica, olfato, garganta, respiratoria, sinsintomas, vacunado, tipoVacuna, dosDosis, quieroVacunarme, apellido, email, empresa);
        },
    });




    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState(`${t("invitados>textHelper>selectOption")}`);

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setHelperText(' ');
        setError(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (value === 'direcTv') {
            setHelperText('direcTv');
        } else if (value === 'experta') {
            setHelperText('experta');
        } else {
            setHelperText(`${t("invitados>textHelper>selectOption")}`);
        }
    };

    return (
        <div className=" m-8">
            <form style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: "10%",
                paddingLeft: "32%",
            }} >
                <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    error={error}
                    variant="standard"
                >
                    <FormLabel component="legend">{t("invitados>label>selectCompany")}</FormLabel>
                    <RadioGroup
                        aria-label="quiz"
                        name="quiz"
                        value={value}
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel value="direcTv" control={<Radio />} label="Experta" />
                        <FormControlLabel value="experta" control={<Radio />} label="DIRECTV / SKY" />
                    </RadioGroup>
                    <FormHelperText>{helperText}</FormHelperText>
                </FormControl>

            </form>

            {value === "" ? "" : value === "direcTv" ?
                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        minWidth: '50vw'
                    }}
                >
                    <FormControl component="fieldset" className={classes.formControl}>

                        <Input
                            name="apellido"
                            placeholder={t("invitados>placeholder>surnameName")}
                            type="text"
                            onChange={formik.handleChange}
                            required
                        />
                        <Input
                            name="email"
                            placeholder={t("invitados>placeholder>email")}
                            type="email"
                            onChange={formik.handleChange}
                            required
                        />
                        <Input
                            name='empresa'
                            placeholder={t("invitados>placeholder>companyCode")}
                            type='text'
                            onChange={formik.handleChange}
                            required
                        />

                        <br /><br />
                        <h2 className='ExpertaText'>{t("invitados>title>autoDiagnosis")}</h2>
                        <br /><br />

                        <h3 className='ExpertaText'>{t("invitados>title>symptoms")}</h3>

                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={contacto} onChange={handleChange(CONTACTO)} value="chkcontacto" />}
                                label={t("diagnostico>label>contactCercanoExperta")}
                            />
                        </FormGroup>

                        {/* TOMO a la temperatura para generalizar a cualquiera de los sintomas mencionados abajo. */}
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={temperatura} onChange={handleChange(TEMPERATURA)} value="chktemperatura" />}
                                label={t("diagnostico>label>sintomasGrupo")}
                            />
                        </FormGroup>
                        <div style={{ marginLeft: 60, fontWeight: 100, fontSize: 14 }}>
                            <p>- {t("diagnostico>label>temp")}</p>
                            <p>- {t("diagnostico>label>tos")}</p>
                            <p>- {t("diagnostico>label>garganta")}</p>
                            <p>- {t("diagnostico>label>nasal")}</p>
                            <p>- {t("diagnostico>label>agitacion")}</p>
                            <p>- {t("diagnostico>label>olfato")}</p>
                            <p>- {t("diagnostico>label>gusto")}</p>
                            <p>- {t("diagnostico>label>diarrea")}</p>
                            <p>- {t("diagnostico>label>cabeza")}</p>
                            <p>- {t("diagnostico>label>musculares")}</p>
                        </div>

                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={sinsintomas} onChange={handleChange(SINSINTOMAS)} value="chksinsintomas" />}
                                label={t("invitados>label>notSymptom")}
                            />
                        </FormGroup>

                    </FormControl>
                    <Button
                        className={classes.btn}
                        variant="contained"
                        type='submit'
                    >{t("invitados>button>send")}</Button>
                    <br /><br />

                </form> :
                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        minWidth: '50vw'
                    }}
                >
                    <FormControl component="fieldset" className={classes.formControl}>

                        <Input
                            name="apellido"
                            placeholder={t("invitados>placeholder>surnameName")}
                            type="text"
                            onChange={formik.handleChange}
                            required
                        />
                        <Input
                            name="email"
                            placeholder={t("invitados>placeholder>email")}
                            type="email"
                            onChange={formik.handleChange}
                            required
                        />
                        <Input
                            name='empresa'
                            placeholder={t("invitados>placeholder>companyCode")}
                            type='text'
                            onChange={formik.handleChange}
                            required
                        />

                        <br /><br />
                        <h2 className='ExpertaText'>{t("invitados>title>autoDiagnosis")}</h2>
                        <br /><br />

                        <h3 className='ExpertaText'>{t("invitados>title>symptoms")}</h3>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={contacto} onChange={handleChange(CONTACTO)} value="chkcontacto" />}
                                label={t("diagnostico>label>contactCercanoDirectv")}
                            />
                        </FormGroup>

                        {/* TOMO a la temperatura para generalizar a cualquiera de los sintomas mencionados abajo. */}
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={temperatura} onChange={handleChange(TEMPERATURA)} value="chktemperatura" />}
                                label={t("diagnostico>label>sintomasGrupo")}
                            />
                        </FormGroup>
                        <div style={{ marginLeft: 60, fontWeight: 100, fontSize: 14 }}>
                            <p>- {t("diagnostico>label>temp")}</p>
                            <p>- {t("diagnostico>label>tos")}</p>
                            <p>- {t("diagnostico>label>garganta")}</p>
                            <p>- {t("diagnostico>label>nasal")}</p>
                            <p>- {t("diagnostico>label>agitacion")}</p>
                            <p>- {t("diagnostico>label>olfato")}</p>
                            <p>- {t("diagnostico>label>gusto")}</p>
                            <p>- {t("diagnostico>label>diarrea")}</p>
                            <p>- {t("diagnostico>label>cabeza")}</p>
                            <p>- {t("diagnostico>label>musculares")}</p>
                        </div>

                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={sinsintomas} onChange={handleChange(SINSINTOMAS)} value="chksinsintomas" />}
                                label={t("invitados>label>notSymptom")}
                            />
                        </FormGroup>
                    </FormControl>
                    <Button
                        className={classes.btn}
                        variant="contained"
                        type='submit'
                    >{t("invitados>button>send")}</Button>
                    <br /><br />

                </form>}
        </div>
    )
}


export default Invitado;