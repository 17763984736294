import React from 'react';
import Container from "@material-ui/core/Container"
import Header from "../shared/Header"
import Sidebar from "./Sidebar2"
import QRCode from 'qrcode.react';
import { getUser } from '../utils/auth-helper';
import { encode as base64_encode } from 'base-64';


const QRImagen = (prop) => {
  const user = getUser();

  if (user) {
    console.log(user)
  }

  return (
    <div>
      <Header />
      <Sidebar />
      <div>
        <Container maxWidth="sm">
          <div align='center'>
      
            <p>
              <h1 className='ExpertaText' style={{ marginTop: 20 }}>{"QR Cochera"}</h1>
              {user?.IdEdificioLector && <QRCode
                // value={ user?.IdEdificioLector.toString() }
                id="1"
                size={290}
                level={"H"}
                includeMargin={true} 
                value={"" + base64_encode("" + base64_encode(JSON.stringify({ edificio: user?.IdEdificioLector, tipo: 'COCHERA' })))}
                />}
            </p>

            <p>
              <h1 className='ExpertaText' style={{ marginTop: 20 }}>{"QR Oficina"}</h1>
              {user?.IdEdificioLector && <QRCode
               // value={JSON.stringify({ edificio: user?.IdEdificioLector, tipo: 'OFICINA' })}
                id="2"
                size={290}
                level={"H"}
                includeMargin={true} 
                value={"" + base64_encode("" + base64_encode(JSON.stringify({ edificio: user?.IdEdificioLector, tipo: 'OFICINA' })))}
              />}
            </p>

          </div>

        </Container>
      </div>
    </div>
  )
}

export default QRImagen;