import React from 'react';
import AutoIcon from '@material-ui/icons/LocalHospital';
import ReservarIcon from '@material-ui/icons/InsertInvitation';
import ReservasIcon from '@material-ui/icons/ImportContacts';
import ReportesIcon from '@material-ui/icons/Assessment';
import AdminIcon from '@material-ui/icons/AssignmentInd';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { getUser } from "../utils/auth-helper";
import { FaChartLine } from 'react-icons/fa';


const OPERADOR = 1;
const SUPERVISOR = 2;
const GERENTE = 3;
const ADMINISTRADOR = 4;
const SEGURIDAD = 5;
const EDIFICIO = 7   ; //12 EN DEV;

const btnAutoDiagnostico = {
    title: "navbar>button>diagnosis",
    icon: <AutoIcon />,
    path: '/AutoDiagnostico',
    cName: 'nav-text'
};

const btnReservar = {
    title: "navbar>button>turn",
    icon: <ReservarIcon />,
    path: '/Reserva',
    cName: 'nav-text'
};

const btnMisReservas = {
    title: "navbar>button>reservation",
    icon: <ReservasIcon />,
    path: '/MisReservas',
    cName: 'nav-text'
};

const btnReportes = {
    title: "navbar>button>reports",
    icon: <ReportesIcon />,
    path: '/Reporte',
    cName: 'nav-text'
};

// const btnListadoTurnos = {
//     title: "navbar>button>listadoturnos",
//     icon: <ReportesIcon />,
//     path: '/ListadoTurnos',
//     cName: 'nav-text'
// };

const btnListadoTurnosEdificio = {
    title: "navbar>button>listadoturnos",
    icon: <ReportesIcon />,
    path: '/ListadoTurnosEdificio',
    cName: 'nav-text'
};

const btnListadoVacunaciones = {
    title: "navbar>button>vacunaciones",
    icon: <ReportesIcon />,
    path: '/ListadoVacunaciones',
    cName: 'nav-text'
}

const btnAdministracion = {
    title: "navbar>button>management",
    icon: <AdminIcon />,
    path: '/Administracion',
    cName: 'nav-text'
};

const btnAbms = {
    title: "navbar>button>abms",
    icon: <AdminIcon />,
    path: '/Abms',
    cName: 'nav-text'
};
const btnMonitoreo = {
    title: "Monitoreo",
    icon: <FaChartLine />,
    path: '/Monitoreo',
    cName: 'nav-text'
};

const btnCerrarSesion = {
    title: "navbar>button>singOff",
    icon: <LogoutIcon />,
    path: '/',
    cName: 'nav-text',
    type: 'LOGOUT'
};

const btnLectorQR = {
    title: "lectorQR",
    icon: <ReportesIcon />,
    path: '/lectorQR',
    cName: 'nav-text'
};

const btnQREstacionamiento = {
    title: "QR",
    icon: <ReportesIcon />,
    path: '/QRImagen',
    cName: 'nav-text'
};

export const SidebarData = (autoDiagnostico, reservarTurno) => {
    const menu = []

    if (!getUser())
        window.location.pathname = '/';

    const idTipoUsuario = getUser().IdTipoDeUsuario;


    switch (idTipoUsuario) {
        case OPERADOR:
            if (autoDiagnostico == 1) menu.push(btnAutoDiagnostico);
            if (reservarTurno == 1) menu.push(btnReservar);
            menu.push(btnMisReservas);
            menu.push(btnCerrarSesion);
            break;
        case SUPERVISOR:
            if (autoDiagnostico == 1) menu.push(btnAutoDiagnostico);
            if (reservarTurno == 1) menu.push(btnReservar);
            menu.push(btnMisReservas);
           // menu.push(btnReportes);
            menu.push(btnCerrarSesion);
            break;
        case GERENTE:
            if (autoDiagnostico == 1) menu.push(btnAutoDiagnostico);
            if (reservarTurno == 1) menu.push(btnReservar);
            menu.push(btnMisReservas);
            // menu.push(btnReportes);
            menu.push(btnCerrarSesion);
            break;
        case ADMINISTRADOR:
            if (autoDiagnostico == 1) menu.push(btnAutoDiagnostico);
            if (reservarTurno == 1) menu.push(btnReservar);
            menu.push(btnMisReservas);
            menu.push(btnReportes);
            // menu.push(btnLectorQR);
            // menu.push(btnListadoTurnos);
            menu.push(btnListadoVacunaciones);
            menu.push(btnAdministracion);
            menu.push(btnAbms);
            // menu.push(btnMonitoreo);
            menu.push(btnCerrarSesion);
            break;
        case SEGURIDAD:
            if (autoDiagnostico == 1) menu.push(btnAutoDiagnostico);
            if (reservarTurno == 1) menu.push(btnReservar);
            menu.push(btnMisReservas);
           //  menu.push(btnReportes);
            menu.push(btnCerrarSesion);
            break;
        case EDIFICIO:
            //if (autoDiagnostico == 1) menu.push(btnAutoDiagnostico);
            menu.push(btnLectorQR);
            menu.push(btnListadoTurnosEdificio);
            menu.push(btnQREstacionamiento)

            menu.push(btnCerrarSesion);


        default:
            //error
            menu.push(btnCerrarSesion);
            break;
    }



    return menu;

    /*[
        {
            title: "Auto Diagnostico",
            icon: <AutoIcon />,
            link: '/Diagnostico'
        },
        {
            title: "Reservar Turno",
            icon: <ReservarIcon />,
            link: '/Reserva'
        },
        {
            title: "Mis Reservas",
            icon: <ReservasIcon />,
            link: '/MisReservas'
        },
        {
            title: "Reportes",
            icon: <ReportesIcon />,
            link: '/Reporte'
        },
        {
            title: "Administracion",
            icon: <AdminIcon />,
            link: '/Administracion'
        },
        {
            title: "Cerrar Sesion",
            icon: <LogoutIcon />,
            link: '/'
        }
    ]*/


}