import { ErrorMessage, useField } from 'formik';
import { PropTypes } from 'prop-types';
export const MySelect = ( { label, ...props } ) => {

    const [ field ] = useField(props)

    return (
        <>
            <label htmlFor={ props.id || props.name }>{ label }</label>
            <select { ...field } { ...props } />
            <ErrorMessage name={ props.name } component="em" />
        </>
    )
}

MySelect.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
}
