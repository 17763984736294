import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Typography,
  Box,
  Modal,
  TextField
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Checkbox, FormGroup, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Form, Formik, useFormik } from "formik";
import * as Api from '../apis/DiagnosticosAPI';
import Header from '../shared/Header';
import Sidebar from './Sidebar2';
import { useAlert } from 'react-alert';
//import swal from '@sweetalert/with-react'
import swal2 from "sweetalert2";
import RefreshIcon from '@material-ui/icons/Refresh';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setMinutes, getDay, addDays, formatISO } from "date-fns";

import { getVacunasOperador } from '../apis/DiagnosticosAPI';
import { getVacunas } from '../apis/DiagnosticosAPI';
import { getUserDiagnosticoCovid } from '../apis/DiagnosticosAPI';
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { MySelect, MyTable, MyTextInput } from "../components";
import { agregarDosis, deleteDosisByIdDosis, deleteVacuna, editarDosis, getVacunasUsuario } from "../apis/VacunasAPI";
import { getUser } from "../utils/auth-helper";
import swal from "sweetalert2";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { UploadFile } from "../components/UploadFile";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  // formControl: {
  //   margin: theme.spacing(3),
  // },
  btn: {
    alignSelf: "center",
    textTransform: "none",
    textAlign: 'center',
    backgroundColor: "#2f4050",
    color: "white",
    justifyContent: "center",
    margin: 4,
    "&:hover": {
      backgroundColor: ({ hoverBackgroundColor, backgroundColor }) =>
        hoverBackgroundColor
          ? hoverBackgroundColor
          : '#4c4e68'
    }
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    backgroundColor: '#E0DEED',
    border: '2px solid #000',
    boxShadow: 24,
    padding: 10,
    borderRadius: 14
  }
}));

const TEMPERATURA = "chktemperatura";
const GUSTO = "chkgusto";
const CONTACTO = "chkcontacto";
const EMBARAZADA = "chkembarazada";
const CANCER = "chkcancer";
const DIABETES = "chkdiabetes";
const HEPATICA = "chkhepatica";
const OLFATO = "chkolfato";
const GARGANTA = "chkgarganta";
const RESPIRATORIA = "chkrespiratoria";
const SINSINTOMAS = "chksinsintomas";
const VACUNADO = "chkvacunado";
const TIPOVACUNA = "chktipoVacuna";
const DOSDOSIS = "chkdosDosis";
const QUIEROVACUNARME = "chkquieroVacunarme";
const INFOACTUALIZADA = "chkinfoActualizada";

const defaultSel = [{ Id: 0, Nombre: 'Seleccionar Vacuna' }]


const Diagnostico = (props) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const classes = useStyles();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false)

  const guardarDiagnostico = (temperatura, gusto, contacto, embarazada, cancer, diabetes, hepatica, olfato, garganta, respiratoria, sinsintomas, vacunado, tipoVacuna, dosDosis, quieroVacunarme, infoActualizada) => {
    if (sinsintomas && (temperatura || gusto || contacto || embarazada || cancer || diabetes || hepatica || olfato || garganta || respiratoria)) {
      alert.show("No podes tener algun sintoma y elegir la opcion sin sintomas!");
      setSubmitting(false)
      return;
    }

    if (!sinsintomas && !temperatura && !gusto && !contacto && !embarazada && !cancer && !diabetes && !hepatica && !olfato && !garganta && !respiratoria) {
      alert.show("Debes seleccionar alguna opcion en sintomas!");
      setSubmitting(false)
      return;
    }

    if (!infoActualizada) {
      alert.show("Debes verificar que la informacion de la vacunacion esta actualizada!");
      setSubmitting(false)
      return;
    }

    Api.saveDiagnostico(temperatura, gusto, contacto, embarazada, cancer, diabetes, hepatica, olfato, garganta, respiratoria, vacunado, tipoVacuna, dosDosis, quieroVacunarme)
      .then(response => {
        alert.show("Diagnostico enviado correctamente!");
        setSubmitting(false)
        setDone(true);
      })
      .catch(function (error) {
        setSubmitting(false)
        if (error.response == undefined)
          alert.show("" + error);
        else
          alert.show("" + error.response.data.error);
      });

    // getUserDiagnosticoCovid()
    //   .then(response => {
    //     if (response.data) {
    //       swal2
    //         .fire({
    //           title: "Diagnostico sospechoso",
    //           text: "Se registro un diagnostico sospechoso en los ultimos 7 dias, debes esperar para poder volver a realizar el autodiagnostico...",
    //           icon: "warning",
    //           confirmButtonColor: "#009bdb",
    //           confirmButtonText: "OK",
    //           animation: true,
    //           target: document.getElementById('form-modal'),
    //           customClass: {
    //             container: 'my-swal'
    //           }
    //         })
    //     }
    //     else {
    //       Api.saveDiagnostico(temperatura, gusto, contacto, embarazada, cancer, diabetes, hepatica, olfato, garganta, respiratoria, vacunado, tipoVacuna, dosDosis, quieroVacunarme)
    //         .then(response => {
    //           alert.show("Diagnostico enviado correctamente!");
    //           setSubmitting(false)
    //           setDone(true);
    //         })
    //         .catch(function (error) {
    //           setSubmitting(false)
    //           if (error.response == undefined)
    //             alert.show("" + error);
    //           else
    //             alert.show("" + error.response.data.error);
    //         });
    //     }
    //   })
    //   .catch(function (error) {

    //     if (error.response == undefined)
    //       alert.show("" + error);
    //     else
    //       alert.show("" + error.response.data.error);
    //   })
  };

  return (
    <div>
      <Header />
      <Sidebar />
      {loading || submitting ? (
        <Container maxWidth="sm">
          <ReactLoading type={"spin"} color={"#fff"} height={'50px'} width={'50px'} />
        </Container>
      ) : (
        <div>
          <Container className='HomeDescr' maxWidth="sm">
            <h1 className='ExpertaText'>{t("navbar>button>diagnosis")}</h1>
            <div className={classes.root}>
              <DiagnosticoForm
                guardarDiagnostico={guardarDiagnostico}
                handleSubmitting={() => setSubmitting(true)}
              />
            </div>
          </Container>
        </div>)}
      {done ? <Redirect to="/MisReservas" /> : null}
    </div>
  );
}

const DiagnosticoForm = ({ guardarDiagnostico, handleSubmitting }) => {
  const classes = useStyles();
  const [vacunas, setVacunas] = useState(defaultSel);
  //vacuna
  const [vacunado, setVacunado] = useState(false)
  const [tipoVacuna, setTipoVacuna] = useState(null)
  const [dosDosis, setDosDosis] = useState(false)
  const [quieroVacunarme, setQuieroVacunarme] = useState(false)
  //sintomas
  const [temperatura, setTemperatura] = useState(false)
  const [gusto, setGusto] = useState(false)
  const [contacto, setContacto] = useState(false);
  const [embarazada, setEmbarazada] = useState(false);
  const [cancer, setCancer] = useState(false);
  const [diabetes, setDiabetes] = useState(false);
  const [hepatica, setHepatica] = useState(false);
  const [olfato, setOlfato] = useState(false);
  const [garganta, setGarganta] = useState(false);
  const [respiratoria, setRespiratoria] = useState(false);
  const [sinsintomas, setSinSintomas] = useState(false);
  const [infoActualizada, setInfoActualizada] = useState(false);
  // const [loading, setLoading] = useState(true);

  const [vacunasUser, setVacunasUser] = useState([])

  const setConfig = (nombreConfig, valorConfig) => {

    switch (nombreConfig) {
      case TEMPERATURA:
        setTemperatura(valorConfig);
        break;
      case GUSTO:
        setGusto(valorConfig);
        break;
      case CONTACTO:
        setContacto(valorConfig);
        break;
      case EMBARAZADA:
        setEmbarazada(valorConfig);
        break;
      case CANCER:
        setCancer(valorConfig);
        break;
      case DIABETES:
        setDiabetes(valorConfig);
        break;
      case HEPATICA:
        setHepatica(valorConfig);
        break;
      case OLFATO:
        setOlfato(valorConfig);
        break;
      case GARGANTA:
        setGarganta(valorConfig);
        break;
      case RESPIRATORIA:
        setRespiratoria(valorConfig);
        break;
      case SINSINTOMAS:
        setSinSintomas(valorConfig);
        break;
      case VACUNADO:
        setVacunado(valorConfig);
        setQuieroVacunarme(false);
        break;
      case TIPOVACUNA:
        setTipoVacuna(valorConfig);
        break;
      case DOSDOSIS:
        setDosDosis(valorConfig);
        break;
      case QUIEROVACUNARME:
        setQuieroVacunarme(valorConfig);
        break;
      case INFOACTUALIZADA:
        setInfoActualizada(valorConfig);
        break;
      default:
        break;
    }
  }

  const handleChange = name => event => {
    setConfig(name, event.target.checked)
  };

  const onChangeValueRadio = (event) => {
    if ("chkquierovacuna" == event.target.value) {
      setQuieroVacunarme(true);
      setDosDosis(false);
      setTipoVacuna(null);
    }
    else {
      setQuieroVacunarme(false);
      setDosDosis(false);
      setTipoVacuna(null);
    }
  }

  function cargarVacunas() {
    getVacunas().then(res => setVacunas(res.data))
    getVacunasOperador()
      .then(resp => {
        setVacunado(resp.data.Vacunado);
        setDosDosis(resp.data.DosDosis);
        setQuieroVacunarme(resp.data.MeQuieroVacunar);
        setTipoVacuna(resp.data.VacunaId);
      })
  }

  let userID = JSON.parse(localStorage.getItem('User'))

  function cargarDosisUsuario() {
    getVacunasUsuario(userID.userId)
      .then(res => {
        if (res?.data) {
          let data = res?.data
          for (var i in data) {
            if (data[i].FechaVacunacion) {
              let array = data[i].FechaVacunacion
              let fechaVacDate = (array.substring(0, array.indexOf("T")))
              data[i].FechaVacunacion = fechaVacDate
            }
          }
          setVacunasUser(data);
        }
      })
    // setLoading(false)
  }

  useEffect(() => {
    cargarVacunas();
  }, []);


  const handleDeleteVacunaUser = (dosis) => {
    swal.fire({
      title: '¿Estás seguro que deseas eliminar?',
      text: "No podrás revertir los cambios",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDosisByIdDosis(dosis.Id)
          .then(res => cargarDosisUsuario()
          )
      }
    })
  }

  const handleEdit = (dosis) => {
    setEditar(true);
    setSelectedDos(dosis)
    setModalOpen(true)
  }



  const formik = useFormik({
    initialValues: {
      temperatura: false,
      gusto: false,
      contacto: false,
      embarazada: false,
      cancer: false,
      diabetes: false,
      hepatica: false,
      olfato: false,
      garganta: false,
      respiratoria: false,
      sinsintomas: false
    },
    onSubmit: (values) => {
      handleSubmitting()
      guardarDiagnostico(temperatura, gusto, contacto, embarazada, cancer, diabetes, hepatica, olfato, garganta, respiratoria, sinsintomas, vacunado, tipoVacuna, dosDosis, quieroVacunarme, infoActualizada);
    },
  });

  let company = localStorage.getItem("company")

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true) & cargarDosisUsuario();
  const handleClose = () => setOpen(false);

  const [modalOpen, setModalOpen] = useState(false)
  const [editar, setEditar] = useState(false)
  const [selectedDos, setSelectedDos] = useState()

  const handleRefresh = () => {
    setModalOpen(false)
    setEditar(false);
    setSelectedDos()
    cargarVacunas()
  }


  const handleNuevaDos = () => {
    setModalOpen(true)
    setEditar(false)
    setSelectedDos()
  }


  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        minWidth: '50vw'
      }}
    >
      <FormControl component="fieldset" style={{ minWidth: '50vw' }}>
        <br />
        <h3 className='ExpertaText'>{t("diagnostico>title>vaccination")}</h3>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={vacunado}
                onChange={handleChange(VACUNADO)}
                value="chkvacunado" />
            }
            label={t("diagnostico>label>vaccinated")}
          />
        </FormGroup>

        {/* {vacunado?
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={dosDosis} onChange={handleChange(DOSDOSIS)} value="chkdosDosis" />} //terminar
              label={t("diagnostico>label>doses")}
            />
          </FormGroup> : null } */}

        {vacunado ?
          // <FormGroup>
          //   <FormControl
          //     style={{
          //       marginTop: "0%",
          //       alignSelf: "center",
          //     }}
          //   >
          //     <InputLabel>
          //       <b>{t("diagnostico>bold>vaccinate")}</b>
          //     </InputLabel>
          //     <Select
          //       id="svacuna"
          //       name="svacuna"
          //       required
          //       style={{
          //         marginBottom: "20px",
          //         minWidth: "50",
          //       }}
          //       value={tipoVacuna}//formik.values.vacuna}
          //       onChange={(e) => {
          //         setTipoVacuna(e.target.value);
          //       }}
          //     >
          //       {vacunas &&
          //         vacunas.map((vacuna) => (
          //           <MenuItem
          //             style={{ fontSize: "11pt", fontFamily: "Armata" }}
          //             key={`svacuna_${vacuna.Id}`}
          //             value={vacuna.Id}
          //           >
          //             {vacuna.Nombre}
          //           </MenuItem>
          //         ))}
          //     </Select>
          //   </FormControl>
          // </FormGroup>
          <div>
            <Button onClick={handleOpen} className={classes.btn}>{t("diagnostico>button>indicarVacunas")}</Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="modal-open"
              style={{ zIndex: 100 }}
            >
              <Box className={classes.modal}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {t("diagnostico>text>vacunasCargadas")}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {t("diagnostico>text>verificarInfo")}

                </Typography>

                <div className={classes.root}>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      onClick={handleNuevaDos}
                      component="span"
                      startIcon={<AddCircleIcon />}
                      variant="contained"
                      className={classes.btn}
                    >
                      {t("diagnostico>btn>nuevaVacuna")}
                    </Button>

                    {/* <Button  onClick={cargarDosisUsuario} className={classes.btn} type="button">Recargar <RefreshIcon></RefreshIcon></Button> */}
                    <MyTable
                      data={vacunasUser}
                      headers={[t("misReserva>text>date"), t("invitados>title>vaccination")]}
                      fields={[`FechaVacunacion`, `Nombre`]}
                      borrar
                      handleDelete={handleDeleteVacunaUser}
                      edit
                      handleEdit={handleEdit}
                    />
                  </div>
                </div>
              </Box>
            </Modal>
            <Modal open={modalOpen}>
              <div className={classes.paper}>
                <Button className={classes.btnCancel} onClick={() => setModalOpen(false)} style={{ color: 'black', marginBottom: -200, padding: -20 }}>x</Button>
                <div align='center' style={{ padding: 50 }}>
                  <NuevaDosis
                    onClose={handleRefresh}
                    editar={editar}
                    selectedDos={selectedDos}
                    cargarDosisUsuario={cargarDosisUsuario}
                    classes={classes}
                    vacunas={vacunas}
                    userID={userID}
                    setModalOpen={setModalOpen}
                  />
                </div>
              </div>
            </Modal>
          </div>


          : null}

        {(!vacunado) ?
          <RadioGroup aria-label="Vacuna" name="rdvacuna" onChange={onChangeValueRadio} >
            <FormControlLabel
              control={
                <Radio value="chkquierovacuna" name="rdvacuna" checked={quieroVacunarme} />}
              label={t("diagnostico>label>yesVaccinate")}
            />
            <FormControlLabel
              control={<Radio value="chknoquierovacuna" name="rdvacuna" checked={!quieroVacunarme} />}
              label={t("diagnostico>label>notVaccinate")}
            />
          </RadioGroup> : null}

        <FormGroup>
          <FormControlLabel
            control={<Checkbox required checked={infoActualizada} onChange={handleChange(INFOACTUALIZADA)} value="chkinfoActualizada" />} //terminar
            label={t("diagnostico>label>infoUpdate")}
          />
        </FormGroup>
        <br />
        <h3 className='ExpertaText'>{t("diagnostico>title>symptoms")}</h3>
        {/* <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={temperatura} onChange={handleChange(TEMPERATURA)} value="chktemperatura" />}
            label={t("diagnostico>label>temperature")}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={gusto} onChange={handleChange(GUSTO)} value="chkgusto" />}
            label={t("diagnostico>label>taste")}
          />
        </FormGroup> */}

        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={contacto} onChange={handleChange(CONTACTO)} value="chkcontacto" />}
            label={company === "experta" ? t("diagnostico>label>contactCercanoExperta") : t("diagnostico>label>contactCercanoDirectv")}
          />
        </FormGroup>


        {/* TOMO a la temperatura para generalizar a cualquiera de los sintomas mencionados abajo. */}
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={temperatura} onChange={handleChange(TEMPERATURA)} value="chktemperatura" />}
            label={t("diagnostico>label>sintomasGrupo")}
          />
        </FormGroup>

        <div style={{ marginLeft: 60, fontWeight: 100, fontSize: 14 }}>
          <p>- {t("diagnostico>label>temp")}</p>
          <p>- {t("diagnostico>label>tos")}</p>
          <p>- {t("diagnostico>label>garganta")}</p>
          <p>- {t("diagnostico>label>nasal")}</p>
          <p>- {t("diagnostico>label>agitacion")}</p>
          <p>- {t("diagnostico>label>olfato")}</p>
          <p>- {t("diagnostico>label>gusto")}</p>
          <p>- {t("diagnostico>label>diarrea")}</p>
          <p>- {t("diagnostico>label>cabeza")}</p>
          <p>- {t("diagnostico>label>musculares")}</p>
        </div>



        {/* <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={olfato} onChange={handleChange(OLFATO)} value="chkolfato" />}
            label={t("diagnostico>label>lossSmell")}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={garganta} onChange={handleChange(GARGANTA)} value="chkgarganta" />}
            label={t("diagnostico>label>soreThroat")}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={respiratoria} onChange={handleChange(RESPIRATORIA)} value="chkrespiratoria" />}
            label={t("diagnostico>label>breathingDifficulty")}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={sinsintomas} onChange={handleChange(SINSINTOMAS)} value="chksinsintomas" />}
            label={t("diagnostico>label>notSynptom")}
          />
        </FormGroup> */}

        {/* {company === "experta" ?
          <>
            <h3 className='ExpertaText'>{t("diagnostico>title>existing")}</h3>

            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={embarazada} onChange={handleChange(EMBARAZADA)} value="chkembarazada" />}
                label={t("diagnostico>label>pregnant")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={cancer} onChange={handleChange(CANCER)} value="chkcancer" />}
                label={t("diagnostico>label>cancer")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={diabetes} onChange={handleChange(DIABETES)} value="chkdiabetes" />}
                label={t("diagnostico>label>diabetes")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={hepatica} onChange={handleChange(HEPATICA)} value="chkhepatica" />}
                label={t("diagnostico>label>disease")}
              />
            </FormGroup>

          </> : " "} */}
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={sinsintomas} onChange={handleChange(SINSINTOMAS)} value="chksinsintomas" />}
            label={t("diagnostico>label>notSynptom")}
          />
        </FormGroup>
      </FormControl>

      <Button
        className={classes.btn}
        variant="contained"
        type='submit'
      >{t("diagnostico>button>sendDiagnostic")}</Button>

    </form>


  )
}


const NuevaDosis = ({ onClose, editar, selectedDos, cargarDosisUsuario, classes, vacunas, userID, setModalOpen }) => {
  const fecha = new Date();
  const [fechaVacunacion, setFechaVacunacion] = useState(new Date());
  const [idVacuna, setIdVacuna] = useState();


  function submit(data) {
    const _fechaVacunacion = formatISO(new Date(fechaVacunacion), {
      representation: "date",
    });
    console.log({ ...data, FechaVacunacion: _fechaVacunacion })
    agregarDosis({ ...data, FechaVacunacion: _fechaVacunacion })
      .then(res => {
        res?.data?.status === 400 ? console.log("error") : console.log("logrado");
        cargarDosisUsuario()
      })
  }

  function guardarCambios(data) {
    const _fechaVacunacion = formatISO(new Date(fechaVacunacion), {
      representation: "date",
    });
    console.log({ ...data, FechaVacunacion: _fechaVacunacion })
    editarDosis({ ...data, FechaVacunacion: _fechaVacunacion })
      .then(res => {
        onClose()
        res?.data?.status === 400 ? console.log("error") : console.log("logrado");
        cargarDosisUsuario()
      })
  }


  // let formatted_date = fecha.getFullYear() + "-" + (fecha.getMonth() + 1) + "-" + fecha.getDate() + " " + fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds();

  async function handleDateChange(date) {
    setFechaVacunacion(date);
  }

  return (
    <div align='center' style={{ padding: 50, backgroundColor: 'white' }}>
      <Button className={classes.btnCancel} onClick={() => setModalOpen(false)} style={{ color: 'black', marginRight: '98%', fontSize: 20, fontWeight: 'bold' }}>x</Button>
      <h1>{editar ? t("diagnostico>title>editarVacuna") : t("diagnostico>title>guardarVacuna")}</h1>
      <Formik
        initialValues={editar ? selectedDos : {
          IdUsuario: userID.userId,
          IdVacuna: '',
          // FechaCreacion: `${formatted_date}`,
          FechaVacunacion: ``
        }}
        onSubmit={(values) => editar ? guardarCambios(values) : submit(values) & setModalOpen(false)} >

        {(formik) => (
          <Form>
            <MySelect label="" name="IdVacuna" >
              <option value='' >{t("diagnostico>label>selectVacuna")}</option>
              {vacunas && vacunas.map(vacunas => {
                return <option key={vacunas.Id} value={vacunas.Id}>{vacunas.Nombre}</option>
              })}
            </MySelect>

            {(formik?.values?.IdVacuna == 16 || formik?.values?.IdVacuna == 7) && <MyTextInput name="otraVacuna" style={{ margin: '10px 0' }} placeholder="Indicar el nombre de la vacuna" />}

            <DatePicker
              locale="es"
              name="FechaVacunacion"
              selected={fechaVacunacion}
              placeholderText={t("reserva>titlelabel>choseDate")}
              onChange={(date) => handleDateChange(date)}
              dateFormat="d/M/yyyy"
              maxDate={setMinutes(addDays(new Date(), 0), 0)}
            // showDisabledMonthNavigation
            />
            <UploadFile onClick={() => console.log("first")} title={t("diagnostico>btn>cargarImagen")} accept={'image/*'} />
            <Button type="submit" className={classes.btn}>
              {editar ? t("administracion>button>saveChanges") : t("reserva>button>confirm")}
            </Button>

          </Form>
        )
        }
      </Formik>
    </div>
  )
}

export default Diagnostico;