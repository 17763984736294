import { http } from "./api";

const endpointBase = 'api/reportes/';

export const getReportes = async (values) => {
    const url = endpointBase 
    return  http.get(url);
}

export const getReporteDinamico = async (idReporte,campos,valores,formatoAlternativo) => {
    const url = endpointBase + 'dinamic/' + idReporte;
    var data =  {"campos": campos , "valores": valores  , "formatoAlternativo": formatoAlternativo}
    return  http.post(url, data/*,{
        responseType: 'blob',}*/
    );
}

export const getConfig = async (nombreConfig) => {
    const url = endpointBase + 'configuraciones/'+nombreConfig
    return  http.get(url);
}

export const setConfig = async (nombreConfig,valor) => {
    const url = endpointBase + 'configuraciones/'+nombreConfig+'/set/'+valor
    return  http.put(url);
}

export const blanquearPassword = async (IdUsuario) => {
    const url = endpointBase + 'dinamic/8';
    var data =  {
        "campos": [
            "usuario"
        ],
        "valores": [
            IdUsuario
        ],
        "formatoAlternativo": false
    }
    return  http.post(url, data);
}