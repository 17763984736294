import React, { useEffect, useState } from "react";
import ReactLoading from 'react-loading';
import {
  Container,
  Button,
  Modal
} from '@material-ui/core';
import { Formik, Form } from 'formik';

import Header from '../../shared/Header';
import Sidebar from '../Sidebar2';
import { MyTable } from "../../components/MyTable";
import { BtnBack } from "../../components/BtnBack";
import {getPaises } from "../../apis/PaisesAPI";
import { MyTextInput } from '../../components'
import { validationPais } from "./validations";
import { useAbmStyles } from "./styles";
import { usePermisosUsuario } from "../../hooks/usePermisosUsuario";
import { useTranslation } from "react-i18next";


const NuevoPais = ({ onClose }) => {
  const { t } = useTranslation();
  const classes = useAbmStyles();

  async function submit(data) {
    // const res = await agregarVacuna(data);
    // res?.data?.status === 400 ? SWAL_ERROR() : SWAL_SUCCESS()
    onClose()
  }

  return (
    <div align='center' style={{ paddingTop: 50 }}>
      <Formik
        initialValues={{
          Nombre: ''
        }}
        onSubmit={(values) => {
          submit(values)
        }}
        validationSchema={validationPais}>

        {(formik) => (
          <Form>

            <MyTextInput
              label={t("paises>label>nameCountry")}
              name="Nombre"
              placeholder={t("paises>placeholder>enterName")}
              type='string'
            />

            <button type="submit" className={classes.btn} >{t("paises>button>confirm")}</button>

          </Form>
        )
        }
      </Formik>
    </div>
  )
}

const Paises = (props) => {
  const { t } = useTranslation();
  usePermisosUsuario([4])
  const [loading, setLoading] = useState(true)
  const classes = useAbmStyles();
  const [paises, setPaises] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    setLoading(true);
    cargarPaises();
  }, []);

  function cargarPaises() {
    getPaises()
      .then(res =>  res?.data && setPaises(res?.data))
    setLoading(false);
  }

  const handleRefresh = () => {
    setModalOpen(false)
    cargarPaises()
  }


  return (
    <div>
      <Header />
      <Sidebar />
      {loading ? (
        <Container maxWidth="sm">
          <ReactLoading type={"spin"} color={"#fff"} height={'50px'} width={'50px'} />
        </Container>
      ) : (
        <div>
          <Container maxWidth="sm">
            
            <h1 className='ExpertaText'>{t("paises>title>countries")}</h1>

            <BtnBack to='/Abms' />


            {/* <Button
              onClick={() => setModalOpen(true)}
              component="span"
              startIcon={<AddCircleIcon />}
              variant="contained"
              className={classes.btn}
            >Nuevo País</Button> */}

            <div className={classes.root}>


              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >

                <MyTable
                  data={paises}
                  // edit
                  headers={['Id', `${t("paises>header>name")}`]}
                  fields={['Id', 'Nombre']}
                />

              </div>

            </div>

          </Container>

          <Modal open={modalOpen}>
            <div className={classes.paper}>
              <Button className={classes.btnCancel} onClick={() => setModalOpen(false)}>x</Button>
              <h1>{t("paises>title>create")}</h1>
              <div align='center' style={{ paddingTop: 50 }}>
                <NuevoPais  onClose={handleRefresh} />
              </div>
            </div>
          </Modal>

        </div>)}
    </div>
  );
}

export default Paises;