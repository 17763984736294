import { useState, useEffect, useCallback } from 'react'
import {
    getUserDiagnosticoValidoSemanal,
    getUserDiagnosticoValido
} from '../apis/DiagnosticosAPI';
import * as Sentry from "@sentry/react";



export const UseDiagnosticoValidoSemanal = () => {
    const [vencido, setVencido] = useState(false)

    const cargar = useCallback(() => {
        try {
            getUserDiagnosticoValidoSemanal()
                .then(resp => setVencido(resp?.data))
        } catch (err) {
            Sentry.captureException(err);
        }

    }, [])

    useEffect(() => {
        cargar()
    }, [cargar])

    return vencido
}

export const UseDiagnosticoValido = () => {
    const [vencido, setVencido] = useState(false)

    const cargar = useCallback(() => {
        try {
            getUserDiagnosticoValido()
                .then(resp => setVencido(resp?.data))

        } catch (err) {
            Sentry.captureException(err);
        }
    }, [])

    useEffect(() => {
        cargar()
    }, [cargar])

    return vencido
}
