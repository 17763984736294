import React, {useState} from 'react'
import DefaultLayout from '../abms/container/DefaultLayout'
import { MyTable } from '../../components';
import { useListaDiagnosticosPositivos } from '../../hooks/useListaDiagnosticosPositivos';
import { InputLabel, TextField } from '@material-ui/core';


export const ListaPositivosCovid = () => {
    const [lastDays, setLastDays] = useState(7)
    const { positivos }= useListaDiagnosticosPositivos(lastDays)

    return (
        <DefaultLayout title="Listado de diagnosticos positivos" maxWidth='lg'>
            <br/><br/>
            
              <InputLabel>Dias previos a la fecha</InputLabel>
              <TextField
                onChange={(e)=> setLastDays(e.target.value)}
                style={{ marginBottom: "2%", width: 200 }}
                type="number"
                value={lastDays}
              />
            <MyTable
                data={positivos}
                headers={['IdUsuario', 'Nombre', 'Email', 'Fecha Diagnostico']}
                fields={[ 'IdUsuario', 'Nombre', 'Email', 'fechaCreacion']}
            />
        </DefaultLayout>
    )
}
