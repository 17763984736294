import { http } from "./api";

const endpointBase = 'api/turnoscocheras/';

export const saveTurno = async (idUsuario, FechaTurno, IdEdificio ) => {
    const url = endpointBase
    var data = {
        "idUsuario": idUsuario,
        "fechaTurno": FechaTurno,
        "IdEdificio": IdEdificio,
    }

    return  http.post(url, data);
}


export const getCupoEstacionamiento = async (fechaTurno, IdEdificio) => {
    const url = endpointBase + 'edificio/' + IdEdificio + '/fecha/' + fechaTurno
    return  http.get(url);
}

export const getTurnosCochera = async (values) => {
    const url = endpointBase + 'misturnos'
    return  http.get(url);
}

// export const getTurno = async (idTurno) => {
//     const url = endpointBase + idTurno
//     return  http.get(url);
// }

export const getTurnoXQr = async (idQr) => {
    const url = endpointBase + 'qr/' + idQr
    return  http.get(url);
}

export const getTurnoXQrGenerico = async (idQr) => {
    const url = endpointBase + 'qr-generico/' + idQr
    return  http.get(url);
}

// export const getEdificios = async (fecha, IdGerencia) => {
//     const url = endpointBase + 'edificios/fecha/' + fecha + '/gerencia/' + IdGerencia;
//     return  http.get(url);
// }


// export const getHoras = async (idEdificio, fecha) => {
//     const url = endpointBase + 'edificio/' + idEdificio + '/fecha/' + fecha + '/HorariosDeEntrada'
//     return  http.get(url);
// }

// export const getPisos = async (idEdificio, fecha, idGerencia) => {
//     const url = endpointBase + 'pisos/fecha/' + fecha + '/edificio/' + idEdificio + '/gerencia/' + idGerencia;
//     return  http.get(url);
// }

// export const getTurnosHistoricos = async (values) => {
//     const url = endpointBase + 'misturnoshistorico'
//     return  http.get(url);
// }

// export const deleteTurno = async (idTurno) => {
//     const url = endpointBase + idTurno
//     return  http.delete(url);
// }


// export const getTurnosByEdificio = async (data) => {
//     const url = endpointBase + 'edificiobyfecha'
//     return  http.post(url, data);
// }