import {useEffect, useState } from 'react'
import { getCupoEstacionamiento } from '../apis/TurnosCocherasAPI';

export const useCupoCocheras = (fechaTurno, IdEdificio) => {
  const [loadingCupos, setLoading] = useState(true)
  const [cupos, setCupos] = useState([])

  const cargarTurnos = (fechaTurno, IdEdificio) =>{
    setLoading(true);
    getCupoEstacionamiento(fechaTurno, IdEdificio)
      .then(res => {
        setLoading(false);
        if (res?.data) {
          setCupos(res?.data?.cupo);
        }
      })
      .catch(error => {
        console.error(error)
        setLoading(false);
      })
  }

  useEffect(() => {
    if (fechaTurno && IdEdificio) {
      cargarTurnos(fechaTurno, IdEdificio);
    }
  }, [fechaTurno, IdEdificio]);



  return [cupos, loadingCupos]
}
