import { useState, useEffect } from 'react'
import { getEdificio } from "../apis/EdificiosAPI";
import { getPisosByIdEdificio } from '../apis/PisosAPI';
import { getGerenciasByIdPiso } from '../apis/GerenciasAPI';
import { getHorariosEntradaByIdEdificio } from '../apis/HorariosEntradaAPI';

export const useEdificioDetails = (id, selectedPiso) => {
  const [edificio, setEdificio] = useState('')
  const [pisos, setPisos] = useState([])
  const [gerencias, setGerencias] = useState('')
  const [horariosEntrada, sethorariosEntrada] = useState([])

  
  useEffect(() => {
    getEdificio(id)
      .then(res => res ? setEdificio(res?.data) : setEdificio(''))
  }, [id])

  useEffect(() => {
    getPisosByIdEdificio(id)
      .then(res => res ? setPisos(res?.data) : setPisos(''))
  }, [id]);

  useEffect(() => {
    getHorariosEntradaByIdEdificio(id)
      .then(res => res ? sethorariosEntrada(res?.data) : sethorariosEntrada([]))
  }, [id]);


  useEffect(() => {
    getGerenciasByIdPiso(selectedPiso.Id)
      .then(res => res ? setGerencias(res?.data) : setGerencias(''))
  }, [selectedPiso]);

  return {
    edificio,
    pisos,
    gerencias,
    refresh: () => {
      getGerenciasByIdPiso(selectedPiso.Id).then(res => res ? setGerencias(res?.data) : setGerencias(''));
      getPisosByIdEdificio(id).then(res => res ? setPisos(res?.data) : setPisos(''));
      getHorariosEntradaByIdEdificio(id).then(res => res ? sethorariosEntrada(res?.data) : sethorariosEntrada([]));
    },
    horariosEntrada
  }
}
