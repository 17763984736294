import React, { useCallback, useEffect, useState } from "react";
import Container from "@material-ui/core/Container"
import Header from "../shared/Header"
import Sidebar from "./Sidebar2"
import { TurnosPorDia } from "./reporte/TurnosPorDia";
import { ActivarUsuario } from "./reporte/ActivarUsuario";
import { SetPassword } from "./reporte/SetPassword";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setMinutes, getDay, addDays, formatISO } from "date-fns";
import { useFormik } from "formik";
import { useAlert } from 'react-alert';
import ReactLoading from 'react-loading';
import es from "date-fns/locale/es";
import { FormSelect } from "../components/FormSelect";
import { style } from "../styles/StylesObject";
import { Router, useHistory, useParams, Switch, Route, Link, BrowserRouter, useRouteMatch, Routes } from "react-router-dom";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Zoom,
} from "@material-ui/core";

import { getReportes, getReporteDinamico } from '../apis/ReportesAPI';
import { getEdificios, getPisos, getHoras } from '../apis/TurnosAPI';
import { getGerencias, getUsuariosDependientes, getUsuarios } from '../apis/UsuariosAPI';
import { useTranslation } from "react-i18next";
import Help from "./Help";
import { CambioGerencia } from "./reporte/CambioGerencia";


registerLocale("es", es);

const Reporte = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [reportes, setReportes] = useState([]);
  const [gerencias, setGerencias] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [edificios, setEdificios] = useState([]);
  const [pisos, setPisos] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [fechaSel, setFechaSel] = useState();
  const [repoSel, setRepoSel] = useState();
  const alert = useAlert();

  const [edificiosVisible, setEdificiosVisible] = useState(0);
  const [usuariosVisible, setUsuariosVisible] = useState(0);
  const [fechaVisible, setFechaVisible] = useState(0);
  const [gerenciasVisible, setGerenciasVisible] = useState(0);
  const [pisosVisible, setPisosVisible] = useState(0);
  const [horariosVisible, setHorariosVisible] = useState(0);
  const [loading, setLoading] = useState(true);
  const [idGerencia, setIdGerencia] = useState(1)
  const { path} = useRouteMatch();

  async function cargarGerencias() {
    const res = await getGerencias();
    const defaultTodos = [{ id: null, Nombre: `${t("reportes>name>everyone")}` }]
    Array.prototype.push.apply(defaultTodos, res.data);

    if (gerenciasVisible == 1) {
      setGerencias(defaultTodos);
    }
    else {
      setGerencias(res.data);
    }
    setLoading(false);
  }

  async function cargarUsuarios() {
    const res = await getUsuarios();
    const defaultTodos = [{ DNI: null, Nombre: `${t("reportes>name>everyone")}` }]
    Array.prototype.push.apply(defaultTodos, res.data);

    if (usuariosVisible == 1)
      setUsuarios(defaultTodos);
    else
      setUsuarios(res.data);
    setLoading(false);
  }

  const cargarReportes = useCallback(async () => {
    const res = await getReportes();
    setReportes(res.data);
    setLoading(false);
  }, [])

  useEffect(() => {
    cargarReportes();
  }, [cargarReportes])

  useEffect(() => {

    if (path !== "/Reporte") {
      cargarGerencias();
      cargarUsuarios();
    }
  }, [path])


  useEffect(() => {
    const defaultSelFecha = [{ eID: 0, Nombre: `${t("reportes>name>report")}`, Direccion: '' }]
    setEdificios(defaultSelFecha);
    const defaultSelEdificio = [{ pID: 0, Nombre: `${t("reportes>name>building")}`, id: 0, horario: `${t("reportes>hours>building")}` }]
    setHorarios(defaultSelEdificio);
    setPisos(defaultSelEdificio);
  }, [reportes]);


  const formik = useFormik({
    initialValues: {
      fecha: "",
      gerencia: "",
      usuario: "",
      edificio: "",
      piso: "",
      hora: "",
    },
    onSubmit: (values) => {
      const { gerencia, usuario, edificio, piso, hora, reporte } = values;
      validarYConsumirReporte(reporte, usuario, fechaSel, piso, edificio, hora, gerencia)
    },
  });

  var campos = [];
  var valores = [];

  const validarYAgregar = (valorBack, compValor, compActivo) => {
    if (compActivo == 1) {
      campos.push(valorBack);
      valores.push(compValor == "" ? null : compValor);
    }
    else if (compActivo == 2) {
      if (compValor == null) {
        alert.show("El parametro " + valorBack + " es obligatorio!");
        return false;
      }
      campos.push(valorBack);
      valores.push(compValor == "" ? null : compValor);
    }
    return true;
  }

  function downloadFile(absoluteUrl) {
    var link = document.createElement('a');
    link.href = absoluteUrl;
    link.download = 'Rep-' + repoSel + '.CSV';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const validarYConsumirReporte = (idReporte, idUsuario, FechaTurno, IdPiso, IdEdificio, idHorarioEntrada, idGerencia) => {

    campos = [];
    valores = [];

    if (!validarYAgregar(`${t("reportes>value>management")}`, idGerencia, gerenciasVisible)) { return; };
    if (!validarYAgregar(`${t("reportes>value>username")}`, idUsuario, usuariosVisible)) { return; };
    if (!validarYAgregar(`${t("reportes>value>edifice")}`, IdEdificio, edificiosVisible)) { return; };
    if (!validarYAgregar(`${t("reportes>value>floor")}`, IdPiso, pisosVisible)) { return; };
    if (!validarYAgregar(`${t("reportes>value>schedule")}`, idHorarioEntrada, horariosVisible)) { return; };
    if (!validarYAgregar(`${t("reportes>value>date")}`, FechaTurno, fechaVisible)) { return; };


    getReporteDinamico(idReporte, campos, valores, false)
      .then(response => {
        downloadFile("data:application/octet-stream," + response.data);
      }).catch(function (error) {
        if (error.response == undefined)
          alert.show("" + error);
        else
          alert.show("" + error.response.data.error);
      });
  };

  async function cargarPisos(idEdificio) {
    const res = await getPisos(idEdificio, "2099-1-1", idGerencia);
    const defaultTodos = [{ pID: null, Nombre: `${t("reportes>name>everyone")}` }]
    Array.prototype.push.apply(defaultTodos, res.data);

    if (pisosVisible == 1)
      setPisos(defaultTodos);
    else
      setPisos(res.data);
    setLoading(false);

  }

  async function cargarHorarios(idEdificio) {

    const res = await getHoras(idEdificio, "2099-1-1");
    const defaultTodos = [{ id: null, horario: `${t("reportes>name>everyone")}` }]
    Array.prototype.push.apply(defaultTodos, res.data);

    if (horariosVisible == 1)
      setHorarios(defaultTodos);
    else
      setHorarios(res.data);

    setLoading(false);
  }

  return (
    <div>
      <Header />
      <Sidebar />
      <Container maxWidth="sm">
        <Route path={'/Reporte'}>
          {path === '/Reporte' &&
            <>
              <h1 className='ExpertaText'>{t("reportes>title>report")}</h1>
              <p >{t("reportes>title>reportSelect")}</p>
              <br />
              <FormSelect
                text={t("reportes>label>choseReport")}
              >
                <MenuItem
                  style={style.MenuItemStyle}
                  value={-1}
                  onClick={() => history.push('/ListadoTurnos')}
                >
                  Reservaciones
                </MenuItem>
                <MenuItem
                  style={style.MenuItemStyle}
                  value={-1}
                  onClick={() => history.push('/TurnosPorDia')}
                >
                  Turnos por día
                </MenuItem>
              </FormSelect>
            </>}
        </Route>
        <Route path={`/ActivarUsuario`}>
          <ActivarUsuario formik={formik} usuarios={usuarios} />
        </Route>
        <Route path={`/DesactivarUsuario`}>
          <ActivarUsuario formik={formik} usuarios={usuarios} />
        </Route>
        <Route path={`/SetPassword`}>
          <SetPassword formik={formik} usuarios={usuarios} />
        </Route>
        <Route path={`/CambioGerencia`}>
          <CambioGerencia formik={formik} usuarios={usuarios} gerencias={gerencias} />
        </Route>
      </Container>
    </div>
  );
}


export default Reporte;