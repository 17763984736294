import Header from '../shared/Header';
import Sidebar from './Sidebar2';
// import Button from "@material-ui/core/Button";
// import { getUser } from '../utils/auth-helper';
// import Paper from '@material-ui/core/Paper';
//import React, { Component, useState } from 'react'
import React, { useEffect, useState } from "react";
import QrReader from 'react-qr-reader'
import swal2 from "sweetalert2";
import * as TurnosAPI from "../apis/TurnosAPI";
// import QrReader from 'react-qr-scanner'

// function getParsedDate(date) {
//   date = String(date).split(' ');
//   var days = String(date[0]).split('-');
//   //var hours = String(date[1]).split(':'); 
//   return [parseInt(days[2]) + '/' + parseInt(days[1]) + '/' + parseInt(days[0])];
// }

// function getCurrentDate(separator = '') {

//   let newDate = new Date()
//   let date = newDate.getDate();
//   let month = newDate.getMonth() + 1;
//   let year = newDate.getFullYear();

//   /*return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`*/
//   return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`
// }

const LectorQR = () => {

  // const [scan, setScan] = useState("");
  // const [error, setError] = useState("");
  const [facing, setFacing] = useState("environment");
  // const [url] = useState("https://www.myinstants.com/media/sounds/erro.mp3");
  // const [urlOK] = useState("https://www.myinstants.com/media/sounds/26f8b9_sonic_ring_sound_effect.mp3");

  // const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  // const [audioOK] = useState(new Audio(urlOK));
  const [playingOK, setPlayingOK] = useState(false);

  const toggle = (esError) => {
    if (esError)
      setPlaying(!playing);
    else
      setPlayingOK(!playingOK);
  }

  // useEffect(() => {
  //   playingOK ? audioOK.play() : audioOK.play();
  // },
  //   [playingOK, audioOK]
  // );

  // useEffect(() => {
  //   playing ? audio.play() : audio.play();
  // },
  //   [playing, audio]
  // );

  // useEffect(() => {
  //   audio.addEventListener('ended', () => setPlaying(false));
  //   audioOK.addEventListener('ended', () => setPlayingOK(false));
  //   return () => {
  //     audio.removeEventListener('ended', () => setPlaying(false));
  //     audioOK.removeEventListener('ended', () => setPlayingOK(false));
  //   };
  // }, [audio, audioOK]);



  const handleScan = data => {
    if (data) {

      TurnosAPI.getTurnoXQr(data)
        .then(response => {

          //console.log("date" + getParsedDate(response.data.FechaTurno.substr(0,10)));
          //console.log("piso" + response.data.Piso);

          if (response.data.status === 'Correcto') {
            toggle(false);
            swal2
              .fire({
                title: response.data.status,
                text: response.data.msg,
                icon: "success",
                showConfirmButton: false,
                animation: true,
                timer: 4000
              });
          }
          else {
            toggle(true);
            swal2
              .fire({
                title: response.data.status,
                text: response.data.msg,
                icon: "error",
                showConfirmButton: false,
                animation: true,
                timer: 4000
              });
          }


        })
        .catch(function (error) {
          toggle(true);
          swal2
            .fire({
              title: "Error",
              text: "El QR ingresado es invalido.",
              icon: "error",
              showConfirmButton: false,
              animation: true,
              timer: 4000
            });
        });

      // console.log(data)
    }
  }

  const handleError = err => {
    console.error(err)
  }

  const choiceButton = () => {
    if (facing === "environment") {
      setFacing("user");
    } else {
      setFacing("environment");
    }
  }

  return (
    <div>
      <div>
        <Header />
        <Sidebar />
      </div>
      <div className='divPerfil'>
        <h1 className='ExpertaText'>LectorQR</h1>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
          facingMode={facing}
        />
        <input type="button" value="Cambiar de cámara" onClick={choiceButton} />
      </div>
    </div>
  );
}

export default LectorQR;