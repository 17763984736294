import { Select, FormControl, InputLabel } from "@material-ui/core"
import { PropTypes } from "prop-types"
// Recibe como children los valores de los items del select 
// y una funcion que se ejecuta cuando se selecciona un item -> {handleListaChange}
// El id es respectivo al tipo de lista que se esta cargando -> osea:
// lista = gerencia
// id = 'gerencia'
export function FormSelect({
  listaVisible,
  id,
  handleListaChange,
  text,
  children
}) {
  const name = id
  function changeValue(e) {
    if(handleListaChange){
        handleListaChange(e.target.value)
    }else{
    }
  }
  return (
    <FormControl
      style={{
        marginTop: "3%",
        alignSelf: "center",
      }}
    >
      <InputLabel>
        <b>{text}</b>
      </InputLabel>
      <Select
        id={id}
        name={name}
        required={listaVisible == 2}
        style={{
          marginBottom: "15px",
          minWidth: "50",
        }}
        onChange={(e) => changeValue(e)}
      >
          {children}
      </Select>
    </FormControl>
  )
}

FormSelect.propTypes = {
  listaVisible: PropTypes.number,
  id: PropTypes.string,
  text: PropTypes.string,
  formikValue: PropTypes.string,
  formik: PropTypes.object,
  handleListaChange: PropTypes.func,
}
