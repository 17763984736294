export const style = {
  datePicker: {
    marginTop: "10%",
    marginRight: "10%",
    padding: "15",
    width: "100%",
    alignSelf: "center",
    justifyContent: "center",
  },
  inputLabel: {
    fontSize: "10pt",
    textAlign: "center",
    color: "rgb(63, 80, 97)",
    marginBottom: 40,
    position: "relative",
  },
  buttonBack: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    width: "100%",
    fontWeight: "bold",
  },
  buttonConfirmarReporte:{
    alignSelf: "center",
    textTransform: "none",
    textAlign: "center",
    backgroundColor: "#2f4050",
    color: "white" 
  },
  contenedorButtonConfirmarReporte: { 
    marginTop: "3%",
    alignSelf: "center"
  },
  btnBack: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  MenuItemStyle: {
    fontSize: "11pt",
    fontFamily: "Armata"
  }
}
