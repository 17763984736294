
import React, { useEffect, useState } from "react";
import Sidebar from './Sidebar2';
import '../utils/App.css';
import Header from '../shared/Header'
import { Container, Modal, Button } from "@material-ui/core";
import { getUser } from '../utils/auth-helper';
import swal from "sweetalert2";
// import logoTmp from '../img/DIABANDERA.png';
import logoTmp from '../img/Prevenciones.jpeg';
import { useAlert } from 'react-alert';
import { useTranslation } from "react-i18next";
import { useAbmStyles } from "./abms/styles";
import { useValidateCampAntigripalModal } from "../hooks/useValidateCampAntigripalModal";

const Home = (props) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const [modalInfo, setModalInfo] = useState(false)
  const user = getUser()
  // const isUsuarioCampAntiigripal = useValidateCampAntigripalModal(user?.Email)


  useEffect(() => {

    alert.show(`${t("home>title>welcome")} ` + getUser().Nombre + (process.env.REACT_APP_API_URL == "https://safedesk-dev.werthein-tech.com/" ? " (VERSION DE TESTEO)" : ""));

    if (getUser().CambioPassObligatorio == 1) {
      swal
        .fire({
          title: "Advertencia",
          text: "Debe realizar un cambio de password antes de continuar!",
          icon: "warning",
          confirmButtonColor: "#009bdb",
          confirmButtonText: "OK",
          animation: true,
        })
        .then((result) => {
          //if (result.value) { 
          window.location.replace("/Contrasena");
          //}
        });
    }

  }, []);

  // useEffect(() => {
  //  if(isUsuarioCampAntiigripal){
  //   setModalInfo(true)
  //  }
  // }, [isUsuarioCampAntiigripal])
  

  return (
    <div className="App">
      <Header />
      <Sidebar />
      <Container maxWidth="sm">
        <div className='HomeDescr'>
          <Modal onClose={() => setModalInfo(false)} open={modalInfo}>
            <div >
              <div align='center'>
                <Button
                  style={{
                    backgroundColor: 'white',
                    alignItems: 'flex-start',
                  }}
                  onClick={() => setModalInfo(false)}>
                  {/* <img src={logoTmp} style={{ maxWidth: '100vw', maxHeight: '100vh' }}/> */}
                  <div style={{ maxWidth: 400, maxheight: 800, backgroundColor:'white' }}>
                  <h2>Campaña Antigripal</h2>
                  <p> <b>FECHAS PARA IBERÁ:</b></p>
                  <p> 2 piso, de 10 a 15 hs.</p>
                  <p> - Martes 10</p>
                  <br/>
                  <p> <b> 2 piso, de 10 a 16 hs</b></p>
                  <p>  - Miércoles 11</p>
                  <p> - Lunes 16</p>
                  <p> - Jueves 19</p>
                  <br/>
                  <p>  <b>FECHAS PARA EL SOI:</b></p>
                  <p>  Jueves 12 de 10 a 13 hs</p>
                  </div>
                  X</Button>
             
              
              </div>
            </div>
          </Modal>

          <h1 className='ExpertaText' style={{ textAlignVertical: "center", textAlign: "center", }}>
            {t("home>title>welcome")}
          </h1>
          <br />
          <br />

          <br />
          <br />
          <br />
          {t("home>h1>br1")}
          <br />
          <br />
          <br />
          {t("home>h1>br2")}
          <br />
          <br />
          <br />
          {t("home>h1>br3")}
          <br />
          <br />
          <a href="/Reserva">
            {t("home>a>turn")}
          </a>
        </div>
      </Container>


    </div>
  );
}

export default Home;