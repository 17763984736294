import React, { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import {
    Card
} from '@material-ui/core';

import { PropTypes } from 'prop-types';

am4core.useTheme(am4themesAnimated);


function ParetoChart({
    name, data, value, category
}) {
    const chart = useRef(null);

    useLayoutEffect(() => {
        const x = am4core.create(name, am4charts.XYChart);
        x.scrollbarX = new am4core.Scrollbar();
        x.data = data

        prepareParetoData();

        function prepareParetoData() {
            var total = 0;

            for (var i = 0; i < x.data.length; i++) {
                var value = x.data[i].visits;
                total += value;
            }

            var sum = 0;
            for (var i = 0; i < x.data.length; i++) {
                var value = x.data[i].visits;
                sum += value;
                x.data[i].pareto = sum / total * 100;
            }
        }

        // Create axes
        let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = category;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.tooltip.disabled = true;

        let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 50;
        valueAxis.min = 0;
        valueAxis.cursorTooltipEnabled = false;

        // Create series
        let series = x.series.push(new am4charts.ColumnSeries());
        series.sequencedInterpolation = true;
        series.dataFields.valueY = value;
        series.dataFields.categoryX = category;
        series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
        series.columns.template.strokeWidth = 0;

        series.tooltip.pointerOrientation = "vertical";

        series.columns.template.column.cornerRadiusTopLeft = 10;
        series.columns.template.column.cornerRadiusTopRight = 10;
        series.columns.template.column.fillOpacity = 0.8;

        // on hover, make corner radiuses bigger
        let hoverState = series.columns.template.column.states.create("hover");
        hoverState.properties.cornerRadiusTopLeft = 0;
        hoverState.properties.cornerRadiusTopRight = 0;
        hoverState.properties.fillOpacity = 1;

        series.columns.template.adapter.add("fill", function (fill, target) {
            return x.colors.getIndex(target.dataItem.index);
        })


        let paretoValueAxis = x.yAxes.push(new am4charts.ValueAxis());
        paretoValueAxis.renderer.opposite = true;
        paretoValueAxis.min = 0;
        paretoValueAxis.max = 100;
        paretoValueAxis.strictMinMax = true;
        paretoValueAxis.renderer.grid.template.disabled = true;
        paretoValueAxis.numberFormatter = new am4core.NumberFormatter();
        paretoValueAxis.numberFormatter.numberFormat = "#'%'"
        paretoValueAxis.cursorTooltipEnabled = false;

        let paretoSeries = x.series.push(new am4charts.LineSeries())
        paretoSeries.dataFields.valueY = "pareto";
        paretoSeries.dataFields.categoryX = value;
        paretoSeries.yAxis = paretoValueAxis;
        paretoSeries.tooltipText = "pareto: {valueY.formatNumber('#.0')}%[/]";
        paretoSeries.bullets.push(new am4charts.CircleBullet());
        paretoSeries.strokeWidth = 2;
        paretoSeries.stroke = new am4core.InterfaceColorSet().getFor("alternativeBackground");
        paretoSeries.strokeOpacity = 0.5;

        // Cursor
        x.cursor = new am4charts.XYCursor();
        x.cursor.behavior = "panX";

        chart.current = x;

        return () => {
            x.dispose();
        };
    }, [data]);

    return (
        <Card
            sx={{ height: '100%', textAlign: 'center' }}
        >
            <div id={name} style={{ width: '100%', fontSize: 8, backgroundColor: 'white' }} />
        </Card>
    );
}

ParetoChart.propTypes = {
    name: PropTypes.string,
    data: PropTypes.object,
    category: PropTypes.string,
    value: PropTypes.string
};

export default ParetoChart;
