import { useState, useEffect, useCallback } from 'react'
import { getGerencias } from '../apis/GerenciasAPI'


export const useGerencias = () => {
    const [gerencias, setGerencias] = useState([])
    const [loadingGerencias, setLoadingGerencias] = useState(false)

    const cargarGerencias = useCallback(() => {
        setLoadingGerencias(true)
        getGerencias()
            .then(resp => { 
                setGerencias(resp.data)
                setLoadingGerencias(false)
            })
            .catch(err => {
                console.error(err.message)
                setLoadingGerencias(false)
            })
    }, [])

    useEffect(() => {
        cargarGerencias()
    }, [cargarGerencias])


    return { gerencias, loadingGerencias }
}
