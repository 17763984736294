import Axios from 'axios'
import { useCallback, useEffect, useState } from 'react'

//se hardcodea hasta que se pase a produccion este endpoint.
const URL_PERMISO_ESTACIONAMIENTO = 'https://safedesk-dev.werthein-tech.com/api/permisos/estacionamiento/'
// const URL_PERMISO_ESTACIONAMIENTO = 'http://localhost:3000/api/permisos/estacionamiento/'

export const usePermisosEstacionamiento = (email) => {
    const [conEstacionamiento, setConEstacionamiento] = useState(true)
    const [loading, setLoading] = useState(true)

    const GetPermisoEstacionamiento = useCallback(() => {
        setLoading(true)
        Axios.get(URL_PERMISO_ESTACIONAMIENTO + email)
            .then((resp) => {
                setConEstacionamiento(resp?.data?.status === 1)
                setLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setLoading(false)
            })
    }, [email])

    useEffect(() => {
        if (email) {
            GetPermisoEstacionamiento()
        }
    }, [email, GetPermisoEstacionamiento])



    return [conEstacionamiento, loading]
}
