import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledButton = styled(Link)`
    position: relative;
    width: 100%;
    max-width: ${props => props.maxWidth || "300px"};
    height: 40px;
    margin: auto;
    font-style: none;
    border-radius: 5px;
    text-decoration: none;
    background: ${props => props.bgcolor || '#e2e2e2'};
    font-size: 1em;
    margin: 0;
    cursor: pointer;
    border: 1px solid #CCC;
    transition: all 0.2s ease;

    &:hover {
        background: lightgray;
        border: 1px solid #000;
    }
`;