import { useEffect, useState } from 'react'
import { getVacunados } from '../apis/MonitoreoApi'

export const useGetVacMonitor = () => {
    const [LoadingVacunas, setLoading] = useState(true)
    const [vacunados, setVacunados] = useState([])

    useEffect(() => {
        setLoading(true);
        cargarVacunados();
    }, []);

    function cargarVacunados() {
        setLoading(true)
        getVacunados()
            .then(res => {
                let vacunados = [
                    {
                        dosis: "1er Dosis",
                        value: res?.data[0].Pdosis
                    },
                    {
                        dosis: "2da Dosis",
                        value: res?.data[0].Sdosis
                    },
                    {
                        dosis: "3er Dosis",
                        value: res?.data[0].Tdosis
                    },
                    {
                        dosis: "Mas de 3 Dosis",
                        value: res?.data[0].MasdeTdosis
                    }
                ]
                // console.log(vacunados);
                setVacunados(vacunados);
                setLoading(false)
            })
            .catch(err => {
                console.error(err)
                setLoading(false)
            })
    }

    return { vacunados, LoadingVacunas }
}
