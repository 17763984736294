import { http } from "./api";

const endpointBase = 'api/gerencias/';

export const getGerencias = async (id) => {
    const url = endpointBase
    return  http.get(url);
}

export const agregarGerencia = async (data) => {
    const url = endpointBase
    return  http.post(url, { data });
}

export const getGerenciasByIdPiso = async (id) => {
    const url = endpointBase + 'piso/'+id;
    return  http.get(url);
}

export const agregarGerenciaAlPiso = async (data) => {
    const url = endpointBase + 'piso'
    return  http.post(url, { data } );
}

export const editarGerenciaPiso = async (data) => {
    const url = endpointBase + 'piso'
    return  http.put(url, { data } );
}

export const deleteGerencia = async (id) => {
    const url = endpointBase + '/'+id;
    return  http.delete(url);
}

export const editarGerencia =  async (data) => {
    const url = endpointBase
    return  http.put(url, { data } );
}