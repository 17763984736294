import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import { style } from "../../styles/StylesObject";
import { MenuItem, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FormSelect } from "../../components/FormSelect";
import swal from "sweetalert2";
import { useRouteMatch } from "react-router-dom";
import { activarUsuario, desactivarUsuario } from "../../apis/UsuariosAPI";

//constante que define si el componente envia el formulario para activar o para desactivar el usuario
const ACTIVAR_USER_PATH = "/ActivarUsuario";

export function ActivarUsuario({ formik, usuarios }) {
  const { path, url } = useRouteMatch();
  console.log(path, url);
  const [formData, setFormData] = useState({});
  const { t } = useTranslation();
  //Verifica si la ruta con la api es para activar un usuario o desactivarlo
  const activarUser = path === ACTIVAR_USER_PATH ? true : false;

  //const { usuarios, activarUser, desactivarUser } = useUsuarios();
  // funcion para enviar el formulario de activar o desactivar usuario
  async function enviarFormulario(event) {
    event.preventDefault();
    if (activarUser) {
      await activarUsuario(formData.IdUsuario);
    } else {
      await desactivarUsuario(formData.IdUsuario);
    }
  }
  console.log(usuarios);
  return (
    <div>
      <br />
      <Container maxWidth="sm">
        <h1>{activarUser ? "Activar Usuario" : "Desactivar Usuario"}</h1>
        <br />
        <form
          onSubmit={(e) => enviarFormulario(e)}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <FormSelect
            lista={usuarios}
            listaVisible={2}
            id="usuario"
            text={t("reportes>label>choseCollaborator")}
          >
            {usuarios &&
              usuarios.map(
                (usuario) =>
                  usuario.DNI && (
                    <MenuItem
                      style={style.MenuItemStyle}
                      key={`usuario_${usuario.IdUsuario}`}
                      value={usuario.IdUsuario}
                      onClick={() => setFormData({ ...usuario, activarUser })}
                    >
                      {usuario.Nombre + " - (" + usuario.Email + ")"}
                    </MenuItem>
                  )
              )}
          </FormSelect>
          <div style={style.contenedorButtonConfirmarReporte}>
            <Button
              style={style.buttonConfirmarReporte}
              variant="contained"
              type="submit"
            >
              {t("reportes>button>confirm")}
            </Button>
          </div>
        </form>
      </Container>
    </div>
  );
}

ActivarUsuario.propTypes = {};
