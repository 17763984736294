import { http } from "./api";

const endpointBase = 'api/vacunas/';

export const getVacunas = async (data) => {
    const url = endpointBase
    return http.get(url);
}
export const getVacunasinvitados = async (data) => {
    const url = 'api/invitados/vacunas/'
    return http.get(url);
}

export const agregarVacuna = async (data) => {
    const url = endpointBase
    return http.post(url, { data } );
}

export const deleteVacuna = async (id) => {
    const url = endpointBase + id;
    return http.delete(url);
}

export const editarVacuna = async (data) => {
    const url = endpointBase
    return http.put(url, { data } );
}

export const getUsuariosVacunados = async () => {
    const url = endpointBase+'registro-vacunacion'
    return http.get(url);
}

export const getUsuariosNoVacunados = async () => {
    const url = endpointBase+'registro-no-vacunacion'
    return http.get(url);
}

export const getVacunasUsuario = async (id) => {
    const url = endpointBase +'dosis/'+ id
    return http.get(url);
}


/*-------------------
/*--------Dosis---------
--------------------*/

export const deleteDosisByIdDosis = async (id) => {
    const url = endpointBase +'dosis/' + id;
    return http.delete(url);
}

export const agregarDosis = async (data) => {
    const url = endpointBase +'dosis/'
    return http.post(url, { data } );
}

export const editarDosis = async (data) => {
    const url = endpointBase +'dosis/'
    return http.put(url, { data } );
}