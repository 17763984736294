import { useCallback, useEffect, useState } from 'react'
import { getTurnosByEdificio } from '../apis/TurnosAPI';

const parseTurnos = (data = []) => {
  let newData = data.map(d => {
    return {
      ...d,
      FechaTurno: d?.FechaTurno?.substring(0, 10),
      QrEscaneado: d.QrEscaneado.data[0] ? 'SI' : 'NO'
    }
  })
  return newData;
}

export const useTurnosByEdificio = (fechaTurno, IdEdificio) => {
  const [loading, setLoading] = useState(true)
  const [turnos, setTurnos] = useState([])

  const cargarTurnos = useCallback(() => {
    if (fechaTurno && IdEdificio) {
      getTurnosByEdificio({ fechaTurno, IdEdificio })
        .then(res => {
          if (res?.data) {
            setTurnos(parseTurnos(res?.data));
          } else {
            setTurnos([])
          }
        })
      setLoading(false);
    }
  }, [fechaTurno, IdEdificio])

  useEffect(() => {
    cargarTurnos();
  }, [fechaTurno, IdEdificio, cargarTurnos]);

  return { turnos, loading }
}
