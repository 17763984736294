import React, { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import {
  Card
} from '@material-ui/core';
import { PropTypes } from 'prop-types';

am4core.useTheme(am4themesAnimated);

function ChartCircle({
  name, data, value, category
}) {
  const chart = useRef(null);

  useLayoutEffect(() => {
    const x = am4core.create(name, am4charts.PieChart);

    x.data = data;

    // Add and configure Series
    const pieSeries = x.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = value;
    pieSeries.dataFields.category = category;
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;

  // This creates initial animation
  pieSeries.hiddenState.properties.opacity = 1;
  pieSeries.hiddenState.properties.endAngle = -90;
  pieSeries.hiddenState.properties.startAngle = -90;

    // x.legend = new am4charts.Legend();

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [data]);

  return (
    <Card
      sx={{ height: '100%', textAlign: 'center' }}
    >
      <div id={name} style={{ width: '100%', fontSize: 8, backgroundColor: 'white' }} />
    </Card>
  );
}

ChartCircle.propTypes = {
  name: PropTypes.string,
  data: PropTypes.object,
  category: PropTypes.string,
  value: PropTypes.string
};

export default ChartCircle;
