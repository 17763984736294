import React, { useState, useEffect, useCallback, memo } from "react";

import {
    Button,
    ButtonGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    makeStyles,
    TextField,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete';
import { Pagination } from "@material-ui/lab";
import {PropTypes } from "prop-types";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
    formControl: {
        margin: theme.spacing(3),
    },
    table: {
        tableLayout: 'fixed',

    }
}));

const paginate = (array, pageSize, pageNumber) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}


export const MyTable = memo(({
    data,
    customKey,
    edit = false,
    customBtn = false,
    customBtnIcon = <EditIcon />,
    headers = [],
    fields = [],
    borrar = false,
    search = false,
    handleSelect = () => { },
    handleDelete = () => { },
    handleEdit = () => { },
    handleCustomBtn = () => { },
    PageSize = 50
}) => {

    const classes = useStyles();
    const [textSearch, setTextSearch] = useState(null)
    const [filterData, setfilterData] = useState(data)
    const [currentPage, setCurrentPage] = useState(1)

    const searchInRow = useCallback((row) => {
        let jsonstring = JSON.stringify(row);
        return jsonstring.toLowerCase().includes(textSearch)
    }, [textSearch])

    useEffect(() => {
        const filter = () => {
            if (search && textSearch) {
                setfilterData(data.filter(row => searchInRow(row)))
                setCurrentPage(1)
            } else {
                setfilterData(data)
            }
        }
        filter()
    }, [textSearch, data, search, searchInRow])


    return (
        <>
            {search && <TextField onChange={e => setTextSearch(e.target.value.toLowerCase())} label="Buscar..." variant="outlined" />}
            <TableContainer style={{ maxWidth: '90vw', height: '40vh', marginBottom: 30, display: 'inline-block', overflow: 'auto', border: '1px solid' }}>
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            {headers.map(header => <TableCell key={header} style={{ width: 100 }}>{header}</TableCell>)}
                            {(edit || borrar) && <TableCell style={{ width: 150 }} />}
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {filterData && paginate(filterData, PageSize, currentPage).map((row, index) => {
                            return (
                                <TableRow hover key={customKey ? row[customKey] : index} onClick={() => handleSelect(row)}>

                                    {fields.map(field => <TableCell key={field} style={{ width: 100 }}>{row[field]}</TableCell>)}

                                    {(edit || borrar) && <TableCell style={{ width: 150 }}>
                                        <ButtonGroup>
                                            {edit && <Button
                                                style={{ border: 'none' }}
                                                component="span"
                                                startIcon={<EditIcon />}
                                                onClick={() => handleEdit(row)}
                                            />}
                                            {borrar && <Button
                                                style={{ border: 'none' }}
                                                component="span"
                                                startIcon={<DeleteIcon />}
                                                onClick={() => handleDelete(row)}
                                            />}
                                            {customBtn && <Button
                                                style={{ border: 'none' }}
                                                component="span"
                                                startIcon={customBtnIcon}
                                                onClick={() => handleCustomBtn(row)}
                                            />}
                                        </ButtonGroup>
                                    </TableCell>}

                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={(parseInt(data.length / PageSize))}
                page={currentPage}
                onChange={(e, number) => setCurrentPage(number)}
            />
            <br />
        </>
    )
})

MyTable.propTypes = {
    data: PropTypes.array,
    customKey: PropTypes.string,
    edit: PropTypes.bool,
    customBtn: PropTypes.bool,
    customBtnIcon: PropTypes.element,
    headers: PropTypes.array,
    fields: PropTypes.array,
    borrar: PropTypes.bool,
    search: PropTypes.bool,
    handleSelect: PropTypes.func,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
    handleCustomBtn: PropTypes.func,
    PageSize: PropTypes.number
}
