import React from 'react'
import ReactLoading from 'react-loading';
import { Box } from "@material-ui/core";

export const MySpinner = () => {
    return (
        <Box
            maxWidth="sm"
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="60vh"
        >
            <ReactLoading type={"spin"} color={"#000000"} height={'50px'} width={'50px'} />
        </Box>
    )
}
