import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import ReactLoading from 'react-loading';
import { Formik, Form } from 'formik';
import { useHistory } from "react-router";

import { getGerencias, getTiposDeUsuarios, getUsuariosDependientes, postUsuario } from '../../apis/UsuariosAPI';
import { SWAL_ERROR, SWAL_SUCCESS } from '../../components/SwalMessages';
import { BtnBack } from "../../components/BtnBack";
import { validationNuevoUsuario } from "./validations";
import Header from '../../shared/Header';
import Sidebar from '../Sidebar2';
import { MyCheckbox, MyTextInput, MySelect } from '../../components'

import { useAbmStyles } from "./styles";
import '../../styles/styles.css';
import { usePermisosUsuario } from "../../hooks/usePermisosUsuario";


export const NuevoUsuario = () => {
    usePermisosUsuario([4])
    const [loading, setLoading] = useState(true)
    const classes = useAbmStyles();
    const [gerencias, setGerencias] = useState([]);
    const [tiposDeUsuario, setTiposDeUsuario] = useState([]);
    const [usuariosDependientes, setUsuariosDependientes] = useState([]);
    let history = useHistory()

    useEffect(() => {
        setLoading(true);
        cargarGerencias();
        cargarTiposDeUsuario();
        cargarUsuariosDependientes();
    }, []);

     function cargarGerencias() {
        getGerencias()
        .then(res => {
            if(res?.data){
                setGerencias(res?.data);
            }
        })
        setLoading(false);
    }

     function cargarTiposDeUsuario() {
        getTiposDeUsuarios()
        .then(res => {
            if(res?.data){
                setTiposDeUsuario(res?.data);
            }
        })
        setLoading(false);
    }

     function cargarUsuariosDependientes() {
        getUsuariosDependientes()
        .then(res => {
            if(res?.data){
                setUsuariosDependientes(res?.data);
            }
        })
        setLoading(false);
    }

     function submit(data) {
        postUsuario(data)
            .then(res => {
                if(res?.data?.status === 400){
                    SWAL_ERROR()
                } else {
                  SWAL_SUCCESS()
                  history.push('/Usuarios')
                }
                setLoading(false);
            });
      }

  
    return (
        <div>
            <Header />
            <Sidebar />
            {loading ? (
                <Container maxWidth="sm">
                    <ReactLoading type={"spin"} color={"#fff"} height={'50px'} width={'50px'} />
                </Container>
            ) : (
                <div>
                    <Container maxWidth="sm">
                        <h1 className='ExpertaText'>Nuevo usuario:</h1>
                        <BtnBack to='/Usuarios'/>
                        <div className={classes.root}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    marginBottom: 30
                                }}
                            >
                                <Formik
                                    initialValues={{
                                        DNI: '',
                                        Nombre: '',
                                        email: '',
                                        Password: '',
                                        Password2: '',
                                        idTipoDeUsuario: '',
                                        idGerencia: '',
                                        idJefeDirecto: '',
                                        Activo: '',
                                        IdEdificioLector: '',
                                    }}
                                    onSubmit={(values) => {
                                        submit(values)
                                    }}
                                    validationSchema={validationNuevoUsuario}>

                                    {(formik) => (
                                        <Form>
                                            <MyTextInput
                                                label="DNI"
                                                name="DNI"
                                                placeholder=""
                                                type='number'
                                            />

                                            <MyTextInput
                                                label="Nombre"
                                                name="Nombre"
                                                placeholder="Santiago"
                                            />

                                            <MyTextInput
                                                label="Email"
                                                name="email"
                                                placeholder="jonh@google.com"
                                                type="email"
                                            />

                                            <MySelect label="Tipo de usuario" name="idTipoDeUsuario" >
                                                <option value="">Seleccionar...</option>
                                                {tiposDeUsuario && tiposDeUsuario.map(tipo => {
                                                    return <option key={tipo.Id} value={tipo.Id}>{tipo.Descripcion}</option>
                                                })}
                                            </MySelect>

                                            <MySelect label="Gerencia" name="idGerencia" >
                                                <option value="">Seleccionar...</option>
                                                {gerencias && gerencias.map(gerencia => {
                                                    return <option key={gerencia.id} value={gerencia.id}>{gerencia.Nombre}</option>
                                                })}
                                            </MySelect>

                                            <MySelect label="Jefe Directo" name="idJefeDirecto" >
                                                <option value="">Ninguno</option>
                                                {usuariosDependientes && usuariosDependientes.map(user => {
                                                    return <option key={user.dni} value={user.dni}>{user.nombre}</option>
                                                })}
                                            </MySelect>

                                            <MyTextInput
                                                label="Password"
                                                name="Password"
                                                placeholder="********"
                                                type="password"
                                            />

                                            <MyTextInput
                                                label="Repetir Password"
                                                name="Password2"
                                                placeholder="********"
                                                type="password"
                                            />

                                            <MyTextInput
                                                label="IdEdificioLector"
                                                name="IdEdificioLector"
                                                placeholder="Id del edificio..."
                                            />

                                            <MyCheckbox label="Activo" name="Activo" />

                                            <button type="submit" className={classes.btn} disabled={loading}>Submit</button>

                                        </Form>
                                    )
                                    }
                                </Formik>

                            </div>
                        </div>
                    </Container>
                </div>)}
        </div>
    )
}
