import swal from "sweetalert2";

export const SWAL_ERROR = () => swal.fire({
  title: "Error",
  text: "Ha ocurrido un error!",
  icon: "warning",
  confirmButtonColor: "#009bdb",
  confirmButtonText: "OK",
  animation: true,
})

export const SWAL_SUCCESS = () => swal.fire({
  title: 'OK',
  text: 'Se ha creado correctamente.',
  icon: "success",
  showConfirmButton: false,
  animation: true,
  timer: 4000
})

export const SWAL_SUCCESS_EDIT = () => swal.fire({
  title: 'OK',
  text: 'Se han guardado los cambios.',
  icon: "success",
  showConfirmButton: false,
  animation: true,
  timer: 4000
})
