import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Container from "@material-ui/core/Container"
import Header from "../../shared/Header"
import Sidebar from "../Sidebar2"
import { style } from '../../styles/StylesObject'
import { MenuItem, Button } from '@material-ui/core'
import { FormSelect } from '../../components/FormSelect'
import { useTranslation } from 'react-i18next'
import { editUsuario } from '../../apis/UsuariosAPI';
import { SWAL_ERROR, SWAL_SUCCESS_EDIT } from '../../components/SwalMessages';

export function CambioGerencia({usuarios, gerencias, formik}) {
 
  const {t} = useTranslation();

  const [formData, setFormData] = useState({})

  function enviarFormulario (event) {
    event.preventDefault();
    editUsuario(formData)
    .then(res => {
      if (res?.data?.status === 400) {
          SWAL_ERROR()
      } else {
          SWAL_SUCCESS_EDIT()
      }
  })
  .catch(err => {
      console.log(err)
  })

  }
  return (
      <div>
        <br />
        <Container maxWidth="sm">
          <h1>Cambio de Gerencia</h1>
          <br />
          <form onSubmit={(e) => enviarFormulario(e)}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}>
            <FormSelect 
              lista={usuarios}
              listaVisible={2}
              id="usuario"
              text={t("reportes>label>choseCollaborator")}
              >
                {usuarios &&
                    usuarios.map((usuario) => usuario.DNI && (
                      <MenuItem
                        style={style.MenuItemStyle}
                        key={`usuario_${usuario.IdUsuario}`}
                        value={usuario.IdUsuario}
                        onClick={() => setFormData({...formData, usuario})}
                      >
                        {usuario.Nombre+' - ('+usuario.Email+')'}
                      </MenuItem>
                ))}
              </FormSelect>
            <FormSelect 
              lista={gerencias}
              listaVisible={2}
              id="usuario"
              text={t("reportes>label>choseManagement")}
              >
                {gerencias &&
                    gerencias.map((gerencia) =>(
                      <MenuItem
                        style={style.MenuItemStyle}
                        key={`usuario_${gerencia.id}`}
                        value={gerencia.id}
                        onClick={() => setFormData({...formData, gerencia})}
                      >
                        {gerencia.Nombre}
                      </MenuItem>
                ))}
              </FormSelect>
            <div style={style.contenedorButtonConfirmarReporte}>
              <Button
                style={style.buttonConfirmarReporte}
                variant="contained"
                type='submit'>{t("reportes>button>confirm")}</Button>

            </div>
          </form>
        </Container>
      </div>
);
}

CambioGerencia.propTypes = {
  usuarios: PropTypes.array,
  gerencias: PropTypes.array,
  formik: PropTypes.object,
}


