import React from 'react';
import ReactDOM from 'react-dom';
import './utils/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

import i18n from "./i18n/index";
import { I18nextProvider } from "react-i18next";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://cf9a78e75c0344ecac5cc0e21f45e981@o1326959.ingest.sentry.io/6587550",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


const options = {
  position: 'bottom center',
  timeout: 5000,
  offset: '30px',
  transition: 'scale'
}


ReactDOM.render(
  
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </AlertProvider>
  </React.StrictMode>,

  document.getElementById('root')
);

reportWebVitals();
