import { http } from "./api";

const endpointBase = 'api/monitoreo/';

export const getCochera = (id) => {
    const url = endpointBase+ "cochera/" + id
    return http.get(url);
}

export const getVacunados = () => {
    const url = endpointBase+ "vacunados"
    return http.get(url);
}

export const getConsumosEdificio = (idEdificio, fecha) => {
    const url = endpointBase+ `consumos/${idEdificio}/${fecha}`;
    return http.get(url);
}