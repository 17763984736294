import { useCallback, useEffect, useState } from 'react'
import { getListadoDiagnosticosPositivos } from '../apis/DiagnosticosAPI'


export const useListaDiagnosticosPositivos = (lastDays = 7) => {
    const [positivos, setPositivos] = useState([])

    const cargarDiagnosticos = useCallback(() => {
        getListadoDiagnosticosPositivos(lastDays)
            .then(res => {
                if (res?.data) {
                    setPositivos(res?.data);
                }
            })
    }, [lastDays])

    useEffect(() => {
        cargarDiagnosticos();
    }, [lastDays, cargarDiagnosticos]);

    

    return { positivos }
}
