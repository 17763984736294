import React, { useState, useEffect, useCallback } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import '../utils/Sidebar.css';
import { IconContext } from 'react-icons';
import * as ReportesAPI from "../apis/ReportesAPI";
import { useAlert } from 'react-alert';

import {
  logoutUser,
  getUser,
  // getToken 
} from "../utils/auth-helper";
import { Redirect } from "react-router-dom";

import IconButton from '@material-ui/core/IconButton';
import {
  IconFlagES,
  IconFlagBR,
  IconFlagUS
} from 'material-ui-flags';
import { useTranslation } from "react-i18next";


function Navbar() {
  const { t, i18n } = useTranslation();
  const alert = useAlert();
  const CONFIG_TURNOS = "TurnosActivo";
  const CONFIG_DIAGNOSTICOS = "DiagnosticosActivo";

  const [turnosActivo, setTurnosActivo] = useState(1)
  const [diagnosticosActivo, setDiagnosticosActivo] = useState(1)

  const changeLanguage = useCallback((lng) => {
    i18n.changeLanguage(lng);
  }, [i18n])

  const checkVersion = useCallback(() => {
    let version = getUser()?.version;
    if (parseInt(version) !== 2) {
      logoutUser()
    }
  }, [])

  // console.log(getUser())

  let company = localStorage.getItem("company")
  
  const setConfig = useCallback((nombreConfig, valorConfig) => {
    switch (nombreConfig) {
      case CONFIG_TURNOS:
        setTurnosActivo(valorConfig);
        break;
      case CONFIG_DIAGNOSTICOS:
        setDiagnosticosActivo(valorConfig);
        break;
      default:
        break;
    }
  },[setTurnosActivo, setDiagnosticosActivo])
  
  const getConfig = useCallback((nombreConfig) => {
    ReportesAPI.getConfig(nombreConfig)
      .then(response => {
        setConfig(nombreConfig, response.data.valor)
      })
      .catch(function (error) {
        if (!error.response)
          alert.show("" + error);
        else
          alert.show("" + error.response.data.error);
      });
  }, [alert, setConfig])


  useEffect(() => {
    checkVersion()
    getConfig(CONFIG_TURNOS);
    getConfig(CONFIG_DIAGNOSTICOS);

    if(!localStorage.getItem("company")){
      handleLogOut()
    }
    
  }, [checkVersion, getConfig]);

  const handleLogOut = () => {
    logoutUser();
  }

  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>

        <div className='navbar'>
          <b className='txtmenu'>
            <Link to='#' className='menu-bars'>
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
            <div className="menuName">
              Menu
            </div>
          </b>
          <b className='textUser' onClick={() => { window.location.pathname = '/Perfil'; }}>
            <div style={{ textAlign: 'right' }}>
              {getUser() ? getUser().Nombre : <Redirect to="/" />}
              <br />
              {getUser() ? getUser().Email : <Redirect to="/" />}
            </div>
          </b>
        </div>

        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <AiIcons.AiOutlineClose />
              </Link>
              <b className='txtmenu'> Menu</b>
            </li>
            {SidebarData(diagnosticosActivo, turnosActivo).map((item, index) => {
              return (
                <li key={index} className={item.cName} onClick={() => {
                  if (item.type === "LOGOUT") {
                    handleLogOut()
                  };
                  window.location.pathname = item.path;
                }}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{t(item.title)}</span>
                  </Link>
                </li>

              );
            })}
            <br />
            <br />
            <div style={{ textAlign: 'center' }}>
              {company === 'experta' ? 
                  <a style={{ fontSize: 12, color: '#fff' }} href="mailto:safedesk@werthein-tech.com?Subject=Reporte%20Incidente%20SafeDesk">
                    Reportar Incidente</a> 
              : company === 'directv' ? <a style={{ fontSize: 12, color: '#fff' }} href="https://directvla.jira.com/servicedesk/customer/portal/18/group/322/create/1929">
                Reportar Incidente</a>
              : <></>  
            }
            </div>
            <div style={{position:'relative', margin: '10px 0'}}>
              <IconButton onClick={() => changeLanguage("en")}>
                <IconFlagUS />
              </IconButton>
              <IconButton onClick={() => changeLanguage("es")}>
                <IconFlagES  />
              </IconButton>
              <IconButton onClick={() => changeLanguage("pt")}>
                <IconFlagBR  />
              </IconButton>
            </div>
          </ul>

        </nav>

      </IconContext.Provider>
    </>
  );
}

export default Navbar;