import React, { useEffect, useState } from "react";
import { MyLink } from "../../components/MyLink";
import { useAbmStyles } from "./styles";
import { DefaultLayout } from "./container/DefaultLayout";
import { usePermisosUsuario } from "../../hooks/usePermisosUsuario";
import { useTranslation } from "react-i18next";
import { getReportes } from "../../apis/ReportesAPI";
import { DesactivarUsuario } from "../reporte/DesactivarUsuario";

const Abms = (props) => {
  const { t } = useTranslation();
  const [reportes, setReportes] = useState([]);
  const classes = useAbmStyles();
  usePermisosUsuario([4]);

  function filtrarReportes(reportes) {
    return reportes.filter(
      (reporte) =>
        reporte.Id == 6 ||
        reporte.Id == 7 ||
        reporte.Id == 8 ||
        reporte.Id == 10
    );
  }
  async function cargarReportes() {
    const res = await getReportes();
    const data = filtrarReportes(res.data);
    setReportes(data);
  }
  useEffect(() => {
    cargarReportes();
  }, []);

  // tengo que seguir en la asignación de texto a cada Link del abms.
  const textos = [
    t("abms>title>desactivateuser"),
    t("abms>title>activateuser"),
    t("abms>title>setpassword"),
    t("abms>title>changemanagement"),
  ];
  console.log(textos[2]);

  return (
    <DefaultLayout title="Abms" maxWidth={"md"}>
      <div
        style={{
          margin: "auto",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          gap: "15px",
          width: "90%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 style={{ textAlign: "center" }}>Parametrización</h2>
          <MyLink
            title={t("abms>title>build")}
            className={classes.btnAbm}
            url="/Edificios"
          />
          {/* <MyLink title='Pisos' className={classes.btnAbm} url='/Pisos' /> */}
          <MyLink
            title={t("abms>title>vaccines")}
            className={classes.btnAbm}
            url="/Vacunas"
          />
          <MyLink
            title={t("abms>title>management")}
            className={classes.btnAbm}
            url="/Gerencias"
          />
          <MyLink
            title={t("abms>title>types")}
            className={classes.btnAbm}
            url="/TiposDeUsuario"
          />
          <MyLink
            title={t("abms>title>countries")}
            className={classes.btnAbm}
            url="/Paises"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2 style={{ textAlign: "center" }}>ABMs Usuarios</h2>
          <MyLink
            title={t("abms>title>users")}
            className={classes.btnAbm}
            url="/Usuarios"
          />
          {/* TERMINR DE DEFINIR COMO ARMAR EL MAP DEL ARRAY */}
          {reportes && (
            <>
              <MyLink
                title={textos[0]}
                className={classes.btnAbm}
                url={"/DesactivarUsuario"}
              />
              <MyLink
                title={textos[1]}
                className={classes.btnAbm}
                url={"/ActivarUsuario"}
              />
              <MyLink
                title={textos[2]}
                className={classes.btnAbm}
                url={"/SetPassword"}
              />
              <MyLink
                title={textos[3]}
                className={classes.btnAbm}
                url={"/CambioGerencia"}
              />
            </>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Abms;
// {/* <MyLink title={t("abms>title>build")} className={classes.btnAbm} url='/Edificios' />
// {/* <MyLink title='Pisos' className={classes.btnAbm} url='/Pisos' /> */}
// <MyLink title={t("abms>title>vaccines")} className={classes.btnAbm} url='/Vacunas' />
// <MyLink title={t("abms>title>management")} className={classes.btnAbm} url='/Gerencias' />
// <MyLink title={t("abms>title>types")} className={classes.btnAbm} url='/TiposDeUsuario' />
// <MyLink title={t("abms>title>countries")} className={classes.btnAbm} url='/Paises' /> */}
// reportes.map((reporte,index) =>{
//   console.log(reporte,index);
//   return (
//     <MyLink key={reporte.Id}
//     title={textos[index]}
//     className={classes.btnAbm}
//     url={'/Reporte/' + reporte.Id}
//     />
//   )
// })
