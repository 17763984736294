import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { style } from "../styles/StylesObject";

export function CustomSelectForm({
  title,
  value, handleChange = () => { },
  options = [],
  customKey = 'id',
  fieldValue = 'id',
  fieldTextValue = 'id',
  fieldTextValue2 = '',
  name = '',
  traduccion = false,
  ...props
}) {

  const { t } = useTranslation();
  return (
    <FormControl
      style={{
        marginTop: "3%",
        alignSelf: "center",
      }}
    >
      <InputLabel>
        <b>{title}</b>
      </InputLabel>
      <Select
        required
        style={{
          marginBottom: "15px",
          minWidth: "50",
        }}
        value={value}
        onChange={handleChange}
        name={name}
        {...props}
      >
        {options &&
          options.map((option) => (
            <MenuItem
              style={style.MenuItemStyle}
              key={name + '_' + option[customKey]}
              value={option[fieldValue]}
            >
              {traduccion ? t(option[fieldTextValue]) : option[fieldTextValue]}
              {fieldTextValue2 && ' - ' + option[fieldTextValue2]}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}
CustomSelectForm.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.array,
  customKey: PropTypes.string,
  fieldValue: PropTypes.string,
  fieldTextValue: PropTypes.string,
  fieldTextValue2: PropTypes.string,
  name: PropTypes.string,
}
