import { http } from "./api";

const endpointBase = 'api/horarios/';

export const getHorariosEntradaByIdEdificio = async (id) => {
    const url = endpointBase+'edificio/'+id
    return  http.get(url);
}

export const createHorarioEntrada = async (data) => {
    const url = endpointBase
    return  http.post(url, { data } );
}

export const deleteHorario = async (id) => {
    const url = endpointBase + '/'+id;
    return  http.delete(url);
}

export const editarHorario = async (data) => {
    const url = endpointBase
    return  http.put(url, {data});
}
