import React from 'react'
import ReactLoading from 'react-loading';
import { Container } from '@material-ui/core';

import Header from '../../../shared/Header';
import Sidebar from '../../Sidebar2';
import { useAbmStyles } from '../styles';
import { BtnBack, MySpinner } from '../../../components';

export const DefaultLayout = ({ children, urlBack='', title, loading, maxWidth="sm"}) => {
    const classes = useAbmStyles();
    
    return (
        <div>
            <Header />
            <Sidebar />
            {loading ? (
                <Container maxWidth="sm">
                    {/* <ReactLoading type={"spin"} color={"#fff"} height={'50px'} width={'50px'} /> */}
                    <MySpinner />
                </Container>
            ) : (
                <div>
                    <Container maxWidth={maxWidth}>
                        <h1 className='ExpertaText'>{title}</h1>
                        {urlBack && <BtnBack to={urlBack} /> }
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                {children}
                            </div>
                    </Container>
                </div>)}
        </div>
    );
}

export default DefaultLayout