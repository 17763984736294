import React from 'react'
import { Button } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { PropTypes } from 'prop-types';
export const BtnNew = ({ onClick = () => { }, title = '', ...props }) => {
  return (
    <Button
      onClick={onClick}
      component="span"
      startIcon={<AddCircleIcon />}
      variant="contained"
      {...props}
    >{title}</Button>

  )
}

BtnNew.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
}