import React, { memo } from 'react'
import { Button } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { PropTypes } from 'prop-types';

export const BtnNewSmall = memo(({ onClick = () => { }, title = '', ...props }) => {
    return (
      <Button
        onClick={onClick}
        component="span"
        startIcon={<AddCircleIcon />}
        variant="contained"
        style={{ float: 'right' }}
        {...props}
      >{title}</Button>
    )
  })

  BtnNewSmall.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
  }