import React, { useEffect, useState } from "react";
import { Formik, Form } from 'formik';

import { getGerencias, getTiposDeUsuarios, editUsuario } from '../../apis/UsuariosAPI';
import { SWAL_ERROR, SWAL_SUCCESS_EDIT } from '../../components/SwalMessages';
import { validacionEditarUsuario  } from "./validations";
import { MyCheckbox, MyTextInput, MySelect } from '../../components'

import { useAbmStyles } from "./styles";
import '../../styles/styles.css';
import { usePermisosUsuario } from "../../hooks/usePermisosUsuario";
import { MySpinner } from "../../components/MySpinner";
import { useHistory } from "react-router-dom";


export const EditarUsuario = ({ usuario, handleEditSuccess }) => {
    usePermisosUsuario([4])
    const [loadingGerencias, setLoadingGerencias] = useState(false)
    const [loadingTiposUsuario, setLoadingTiposUsuario] = useState(false)
    const classes = useAbmStyles();
    const [gerencias, setGerencias] = useState([]);
    const [tiposDeUsuario, setTiposDeUsuario] = useState([]);
    const [submitting, setSubmitting] = useState(false)
    // const [usuariosDependientes, setUsuariosDependientes] = useState([]);
    const history = useHistory()

    useEffect(() => {
        cargarGerencias();
        cargarTiposDeUsuario();
        // cargarUsuariosDependientes();
    }, []);

    const cargarGerencias = () => {
        setLoadingGerencias(true)
        getGerencias()
            .then(res => {
                if (res?.data) {
                    setGerencias(res?.data);
                }
                setLoadingGerencias(false);
            })
            .catch(err => setLoadingGerencias(false))
    }

    const cargarTiposDeUsuario = () => {
        setLoadingTiposUsuario(true)
        getTiposDeUsuarios()
            .then(res => {
                if (res?.data) {
                    setTiposDeUsuario(res?.data);
                }
                setLoadingTiposUsuario(false);
            })
            .catch(err => setLoadingTiposUsuario(false))

    }

    // function cargarUsuariosDependientes() {
    //     getUsuariosDependientes()
    //         .then(res => {
    //             if (res?.data) {
    //                 setUsuariosDependientes(res?.data);
    //             }
    //         })
    //     setLoading(false);
    // }

    function submit(data) {
        setSubmitting(true)
        editUsuario(data)
            .then(res => {
                if (res?.data?.status === 400) {
                    SWAL_ERROR()
                } else {
                    SWAL_SUCCESS_EDIT()
                    history.push('/Usuarios')
                }
                setSubmitting(false);
                handleEditSuccess()
            })
            .catch(err => {
                console.log(err)
                setSubmitting(false);
            })
    }

    return (
        loadingGerencias || loadingTiposUsuario ?
            <MySpinner />
            :
            <div align='center' style={{ paddingTop: 50 }}>
                <Formik
                    initialValues={usuario}
                    onSubmit={(values) => {
                        submit(values)
                    }}
                    validationSchema={validacionEditarUsuario}
                    >

                    {(formik) => (
                        <Form>
                            <MyTextInput
                                label="Nombre"
                                name="Nombre"
                                placeholder="Santiago"
                            />
                             <MyTextInput
                                label="DNI"
                                name="DNI"
                                placeholder="Numero de DNI"
                            />
                             <MyTextInput
                                label="Email"
                                name="Email"
                                placeholder="Email"
                            />

                            <MySelect label="Tipo de usuario" name="IdTipoDeUsuario" >
                                <option value="">Seleccionar...</option>
                                {tiposDeUsuario && tiposDeUsuario.map(tipo => {
                                    return <option key={tipo.Id} value={tipo.Id}>{tipo.Descripcion}</option>
                                })}
                            </MySelect>

                            <MySelect label="Gerencia" name="IdGerencia" >
                                <option value="">Seleccionar...</option>
                                {gerencias && gerencias.map(gerencia => {
                                    return <option key={gerencia.id} value={gerencia.id}>{gerencia.Nombre}</option>
                                })}
                            </MySelect>

                            {/* <MySelect label="Jefe Directo" name="idJefeDirecto" >
                            <option value="">Ninguno</option>
                            {usuariosDependientes && usuariosDependientes.map(user => {
                                return <option key={user.dni} value={user.dni}>{user.nombre}</option>
                            })}
                        </MySelect> */}

                            <MyTextInput
                                label="IdEdificioLector"
                                name="IdEdificioLector"
                                placeholder="Id del edificio..."
                            />

                            <MyCheckbox label="Activo" name="Activo" />

                            <button type="submit" className={classes.btn} disabled={submitting}>Submit</button>

                        </Form>
                    )
                    }
                </Formik>
            </div>
    )
}
