import React, { useEffect, useState } from 'react'
// import { useAllEdificios } from '../../hooks/useAllEdificios'
import DefaultLayout from '../abms/container/DefaultLayout'
import DatePicker, { registerLocale } from "react-datepicker";
import { getDay, formatISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { FormControl, InputLabel } from '@material-ui/core';
import es from "date-fns/locale/es";
import { useTurnosByEdificio } from '../../hooks/useTurnosByEdificio';
import { MyTable } from '../../components';
import { useTranslation } from 'react-i18next';
import { getUser } from '../../utils/auth-helper';

registerLocale("es", es);

const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
};

export const ListadoTurnosEdificio = () => {
    const user = getUser();
    // console.log(user);
    const { t } = useTranslation();
    // const { edificios } = useAllEdificios()
    const [fecha, setFecha] = useState(formatISO(new Date(), { representation: "date" }))
    const [edificio, setEdificio] = useState(0)
    const { turnos } = useTurnosByEdificio(fecha, edificio)


    useEffect(() => {
      if(user?.IdEdificioLector){
        setEdificio(user?.IdEdificioLector)
      }
    }, [user])
    

    const handleDateChange = (date) => {
        const fechaAux = formatISO(new Date(`${date}`), {
            representation: "date",
        });
        setFecha(fechaAux);
    }

    return (
        <DefaultLayout title={t("listadoDeTurnos>title>list")}>
            <form
            style={{width:'100%'}}
            >
                <FormControl style={{ alignSelf: "center" }}>
                    <InputLabel>{t("listadoDeTurnos>label>date")}</InputLabel>
                    <br />
                    <div id="datePicker">
                        <DatePicker
                            style={{
                                marginTop: "10%",
                                marginRight: "10%",
                                width: "100%",
                                alignSelf: "center",
                                justifyContent: "center",
                            }}
                            id="fecha"
                            locale="es"
                            //selected={fecha}
                            filterDate={isWeekday}
                            name="fecha"
                            placeholderText={t("listadoDeTurnos>label>date")}
                            onChange={(date) => handleDateChange(date)}
                            dateFormat="MMMM d, yyyy"
                            showDisabledMonthNavigation
                            inline={true}
                        />
                    </div>
                </FormControl>
                {/* <FormControl style={{ alignSelf: "center", width:'100%' }}>
                    <select style={{ width:'100%' }} value={edificio} onChange={e => setEdificio(e.target.value)}>
                        <option value=''>{t("listadoDeTurnos>option>select")}</option>
                        {edificios && edificios.map(e => {
                            return <option value={e.id}>{e.nombre}</option>
                        })}
                    </select>
                </FormControl> */}
            </form>
            <div style={{ margin: '20px 0 100px 0' }}>
                <MyTable
                    search={true}
                    data={turnos}
                    headers={[`${t("listadoDeTurnos>header>user")}`, `${t("listadoDeTurnos>header>schedule")}`, `${t("listadoDeTurnos>header>edifice")}`, `${t("listadoDeTurnos>header>data")}`, `${t("listadoDeTurnos>header>scanning")}`]}
                    fields={['Usuario', 'Horario', 'Edificio', 'FechaTurno', 'QrEscaneado']}
                />
            </div>


        </DefaultLayout>
    )
}
