import React, { useEffect, useState } from 'react'
import Container from "@material-ui/core/Container"
import { getUsuarios } from '../../apis/UsuariosAPI'
import { style } from '../../styles/StylesObject'
import { MenuItem, Button } from '@material-ui/core'
import { useTranslation } from "react-i18next";
import { FormSelect } from '../../components/FormSelect'
import { blanquearPassword } from '../../apis/ReportesAPI'
import { useAlert } from 'react-alert';

export function SetPassword({ formik, usuarios }) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({})
  const alert = useAlert();

  function enviarFormulario(event) {
    event.preventDefault();
    console.log(formData)

    blanquearPassword(formData.IdUsuario)
      .then((resp) => alert.show(resp.toString()))
      .catch(function (error) {
        if (error.response == undefined)
          alert.show("" + error);
        else
          alert.show("" + error.response.data.error);
      });
  }

  return (
    <div>
      <br />
      <Container maxWidth="sm">
        <h1>Reporte</h1>
        <p >Blanquear Password</p>
        <br />
        <form onSubmit={(e) => enviarFormulario(e)}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <FormSelect
            lista={usuarios}
            listaVisible={2}
            id="usuario"
            text={t("reportes>label>choseCollaborator")}
          >
            {usuarios &&
              usuarios.map((usuario) => usuario.DNI && (
                <MenuItem
                  style={style.MenuItemStyle}
                  key={`usuario_${usuario.IdUsuario}`}
                  value={usuario.IdUsuario}
                  onClick={() => setFormData({ ...usuario })}
                >
                  {usuario.Nombre + ' - (' + usuario.Email + ')'}
                </MenuItem>
              ))}
          </FormSelect>
          <div style={style.contenedorButtonConfirmarReporte}>
            <Button
              style={style.buttonConfirmarReporte}
              variant="contained"
              type='submit'>{t("reportes>button>confirm")}
            </Button>
          </div>
        </form>
      </Container>
    </div>
  );
}

SetPassword.propTypes = {}


