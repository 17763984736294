import React from 'react'
import DefaultLayout from '../abms/container/DefaultLayout'
import { MyTable } from '../../components';
import { useRegistroVacunacion } from '../../hooks/useRegistroVacunacion';
import { useTranslation } from 'react-i18next';

export const ListadoVacunaciones = () => {
    const { t } = useTranslation();
    const { vacunados, noVacunados }= useRegistroVacunacion()

    return (
        <DefaultLayout title="Registro de vacunación" maxWidth='lg'>
            <h4>{t("listadoVacunaciones>title>vaccinated")}</h4>
            <MyTable
                search={true}
                data={vacunados}
                headers={[`${t("listadoVacunaciones>header>dni")}`, `${t("listadoVacunaciones>header>user")}`, `${t("listadoVacunaciones>header>vaccinated")}`, `${t("listadoVacunaciones>header>dosis")}`, `${t("listadoVacunaciones>header>vaccined")}`, `${t("listadoVacunaciones>header>mail")}`]}
                fields={[ 'DNI', 'Usuario', 'Vacunado', 'DosDosis', 'Vacuna', 'Email']}
            />
            <br/><br/>
            <h4>{t("listadoVacunaciones>title>notvaccinated")}</h4>
            <MyTable
                search={true}
                data={noVacunados}
                headers={[ `${t("listadoVacunaciones>header>dni")}`,`${t("listadoVacunaciones>header>user")}`, `${t("listadoVacunaciones>header>vaccinated")}`,`${t("listadoVacunaciones>header>mail")}`]}
                fields={[ 'DNI', 'Usuario', 'Vacunado', 'Email']}
            />
        </DefaultLayout>
    )
}
