import React, { useEffect, useState } from "react";
import { Formik, Form } from 'formik';
// import { useHistory } from "react-router";
import { MyCheckbox, MyTextInput, MySelect } from '../../components'
import { postEdificio, editarEdificio } from '../../apis/EdificiosAPI';
import { getPaises } from "../../apis/PaisesAPI";
import { validationNuevoEdificio } from "./validations";
import { useTranslation } from "react-i18next";
import '../../styles/styles.css';
import { useAbmStyles } from "./styles";
// import DefaultLayout from "./container/DefaultLayout";
import { usePermisosUsuario } from "../../hooks/usePermisosUsuario";
import { SWAL_ERROR, SWAL_SUCCESS, SWAL_SUCCESS_EDIT } from '../../components/SwalMessages';
// import { propTypes } from "qrcode.react";


const NuevoEdificio = ({ onClose, editar, selectedEdificio }) => {
    usePermisosUsuario([4])
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)
    const [paises, setPaises] = useState([])
    const classes = useAbmStyles();
    // let history = useHistory();

    useEffect(() => {
        cargarPaises()
    }, []);

    function submit(data) {
        console.log('NUEVO EDIFICIO')
        postEdificio(data)
            .then(res => {
                if (res?.data?.status === 400) {
                    SWAL_ERROR()
                } else {
                    SWAL_SUCCESS()
                }
                onClose()
            })
        setLoading(false);
    }

    function guardarCambios(data) {
        console.log('guardarCambios')
        editarEdificio(data)
            .then(res => {
                res?.data?.status === 400 ? SWAL_ERROR() : SWAL_SUCCESS_EDIT();
                onClose()
            })
    }

    function cargarPaises() {
        getPaises()
            .then(res => {
                if (res?.data) {
                    setPaises(res?.data);
                }
            })
        setLoading(false);
    }
    return (
        <div align='center'>
            <h1>{editar ? 'Editar' : 'Crear'} {t("edificios>title>building")}</h1>
            <Formik
                initialValues={editar ?
                    { ...selectedEdificio, conEstacionamiento: selectedEdificio.conEstacionamiento ? true : false }
                    : {
                        nombre: '',
                        direccion: '',
                        lat: '',
                        long: '',
                        conEstacionamiento: '',
                        cupoEstacionamiento: 0,
                    }}
                onSubmit={(values) => editar ? guardarCambios(values) : submit(values)}
                validationSchema={validationNuevoEdificio}>

                {(formik) => (
                    <Form>
                        <MyTextInput
                            label="Nombre"
                            name="nombre"
                            placeholder="ingresar un nombre..."
                        />

                        <MyTextInput
                            label="Direccion"
                            name="direccion"
                            placeholder="ingresar una Direccion..."
                        />

                        <MySelect label="País" name="idPais" >
                            <option value="">Seleccionar...</option>
                            {paises && paises.map(e => {
                                return <option key={e.id} value={e.id}>{e.Nombre}</option>
                            })}
                        </MySelect>

                        <MyTextInput
                            label="Provincia"
                            name="provincia"
                            placeholder="ingresar una provincia..."
                        />

                        {/* <MyTextInput
                            label="Latitud"
                            name="Lat"
                            placeholder="ingresar una Latitud..."
                        />

                        <MyTextInput
                            label="Longitud"
                            name="Long"
                            placeholder="ingresar una Longitud..."
                        /> */}

                        <MyCheckbox
                            label="Con Estacionamiento"
                            name="conEstacionamiento"
                        />

                        <MyTextInput
                            label="Cupo Estacionamiento"
                            name="cupoEstacionamiento"
                            placeholder=""
                            type='number'
                        />

                        {/* <button type="submit" className={classes.btn} disabled={loading}>Submit</button> */}
                        <button type="submit" className={classes.btn} >
                            {editar ? `${t("gerencia>button>changes")}` : `${t("gerencia>button>confirm")}`}
                        </button>
                    </Form>
                )
                }
            </Formik>
        </div>
    )
}

export default NuevoEdificio;