import React, { useState } from "react";
import Header from "../shared/Header";
import Sidebar from './Sidebar2';
import ChartCircle from "../components/chart/circleChart";
import ChartSemiCircle from "../components/chart/semiCircleChart";
import ChartDonat from "../components/chart/donatsChart";
import exampleOcupacionPuestos from "../_mocks_/exampleOcupacionPuestos";
import exampleOcupacionpiso from "../_mocks_/exampleOcupacionpiso";
import exampleOcupacionGerencia from "../_mocks_/exampleOcupacionGerencia";
import { BarChart2 } from "../components/chart/BarChart2";
import { useAllEdificios } from "../hooks/useAllEdificios";
import { CustomSelectForm } from "../components/CustomSelectForm";
import { useGetCocherasMonitor } from "../hooks/useGetCocherasMonitor";
import { useGetVacMonitor } from "../hooks/useGetVacMonitor";


const Monitoreo = (props) => {
  const { edificios } = useAllEdificios()
  const [edificioSelected, setEdificioSelected] = useState(1)
  const { cocheras, LoadingCochera } = useGetCocherasMonitor(edificioSelected)
  const { vacunados, LoadingVacunas } = useGetVacMonitor()

  return (
    <div className="App">
      <Header />
      <Sidebar />
      <div>
        <div style={{ padding: 10 }}>
          <CustomSelectForm
            title="Seleccionar edificio..."
            value={edificioSelected}
            handleChange={e => setEdificioSelected(e.target.value)}
            options={edificios}
            customKey='id'
            fieldValue='id'
            fieldTextValue='nombre'
            name="edificio"
          />
        </div>
      </div>

      <div className="containers">

        {LoadingVacunas ?
          <div className="a">
            <h3>Vacunas</h3>
          </div> :
          <div className="a">
            <h3>Vacunas</h3>
            <ChartCircle
              name="graph_covid"
              data={vacunados}
              value="value"
              category="dosis"
            />
          </div>}

        {LoadingCochera ?
          <div className="b">
            <h3>Ocupacion de gerencia</h3>
          </div> :
          <div className="b">
            <h3>Ocupacion de gerencia</h3>
            <ChartDonat
              name="graph_gerenciaEdificio"
              data={exampleOcupacionGerencia}
              value="value"
              category="country"
            />
          </div>}

        <div className="c">
          <h3>Ocupacion de pisos de edificio</h3>
          <ChartDonat
            name="graph_pisoEdificio"
            data={exampleOcupacionpiso}
            value="value"
            category="country"
          />
        </div>


        {LoadingCochera ?
          <div className="d">
            <h3>Ocupacion de cocheras</h3>
          </div> :
          <div className="d">
            <h3>Ocupacion de cocheras</h3>
            <ChartSemiCircle
              name="graph_cochera"
              data={cocheras}
              value="value"
              category="country"
            />
          </div>}

        {LoadingCochera ?
          <div className="e">
            <h3>Ocupacion de puestos</h3>
          </div> :
          <div class="e">
            <h3>Ocupacion de puestos</h3>
            <ChartSemiCircle
              name="graph_puesto"
              data={exampleOcupacionPuestos}
              value="value"
              category="country"
            />
          </div>}

      </div>
      <div>
        <div style={{ padding: 20 }}>
          <h3>Detalle de ocupacion de pisos por día</h3>
          <BarChart2 />
        </div>
      </div>
    </div>

  );
}

export default Monitoreo;