import { useCallback, useEffect, useState } from 'react'
import { getCochera } from '../apis/MonitoreoApi'

export const useGetCocherasMonitor = (idEdificio, fecha) => {
    const [LoadingCochera, setLoading] = useState(false)
    const [cocheras, setCocheras] = useState([])

    const cargarCocheras = useCallback((idEdificio, fecha) => {
        if (idEdificio && fecha) {
            setLoading(true);
            getCochera(idEdificio)
                .then(res => {
                    if (res?.data) {
                        let cocheras = [{
                            country: 'Cocheras Ocupadas',
                            value: res?.data[0].ConEstacionamiento.data[0]
                        }, {
                            country: 'Cocheras Desocupadas',
                            value: (res?.data[0].CupoEstacionamiento - res?.data[0].ConEstacionamiento.data[0])
                        }]
                        setCocheras(cocheras);
                    }
                    setLoading(false)
                })
                .catch(err => {
                    console.error(err)
                    setLoading(false)
                })
        }
    }, [])


    useEffect(() => {
        cargarCocheras(idEdificio, fecha);
    }, [idEdificio, fecha, cargarCocheras]);


    return { cocheras, LoadingCochera }
}
