import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { PropTypes } from "prop-types";

export const MyLink = ({ title, url, className}) => {
    return (
      <Link to={url} style={{textDecoration:'none'}}>
        <Button
        style={{width: '100%'}}
          className={className}
          variant="contained"
        >{title}</Button>
      </Link>
    )
  }

  MyLink.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    className: PropTypes.string,
  }