import { useState, useEffect, useCallback } from 'react'
import { getEmpresas } from '../apis/EmpresasAPI'

export const useEmpresas = () => {
    const [empresas, setEmpresas] = useState([])
    const [loadingEmpresas, setLoadingEmpresas] = useState(false)

    const cargarEmpresas = useCallback(() => {
        setLoadingEmpresas(true)
        getEmpresas()
            .then(resp => {
                setEmpresas(resp.data)
                setLoadingEmpresas(false)
            })
            .catch(err => {
                console.error(err.message)
                setLoadingEmpresas(false)
            })
    }, [])

    useEffect(() => {
        cargarEmpresas()
    }, [cargarEmpresas])


    return { empresas, loadingEmpresas }
}
