import React, { useCallback, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setMinutes, getDay, addDays, formatISO } from "date-fns";
import { useFormik } from "formik";
import Container from "@material-ui/core/Container";
import { useAlert } from 'react-alert';
import swal from "sweetalert2";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";
import { getUser } from '../utils/auth-helper';
import { Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import { style } from "../styles/StylesObject";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";

import Header from '../shared/Header';
import Sidebar from './Sidebar2';
import { getPisos, getHoras, saveTurno, getEdificios } from '../apis/TurnosAPI';
// import { getUserDiagnosticoValido } from '../apis/DiagnosticosAPI';
import { useGerencias } from "../hooks/useGerencias";
import { useUsuariosDependientes } from "../hooks/useUsuariosDependientes";
import { useEdificios } from "../hooks/useEdificios";
// import { usePaises } from "../hooks/usePaises";
import { MySpinner } from "../components/MySpinner";
import { useEmpresas } from "../hooks/useEmpresas";
import { useTranslation } from "react-i18next";
import { useCupoCocheras } from "../hooks/useCupoCocheras";
import { usePermisosEstacionamiento } from "../hooks/usePermisoEstacionamiento";
import { useValidateCampAntigripal } from "../hooks/useValidateCampAntigripal";
import { BtnBack } from "../components";
import { StyledInputLabel } from "../styled-components/StyledInputLabel";
import { CustomSelectForm } from "../components/CustomSelectForm";
import i18next from "i18next";

registerLocale("es", es);
registerLocale("pt", pt);

const isWeekday = (date) => {
  const day = getDay(date);
  return day !== 0 && day !== 6;
};

function populateFeriados(holiday) {
  const feriadoData = [];
  if (holiday) {
    for (let a = 0; a < holiday.length; a++) {
      const day = holiday[a].fecha.replace(/-/g, "/");
      feriadoData.push(new Date(day));
    }
  }
  return feriadoData;
}

const validateVacunacionAntigripal = (edificio, fecha) => {
  if (edificio != 26 || edificio != 30) {
    return true
  } else {
    if (fecha == '2022-07-07') {
      return true
    } else {
      return false
    }
  }
}
// TODO - Exportar a un componente
// export const CustomSelectForm = ({
//   title,
//   value,
//   handleChange = () => { },
//   options = [],
//   customKey = 'id',
//   fieldValue = 'id',
//   fieldTextValue = 'id',
//   fieldTextValue2 = '',
//   name = '',
//   ...props
// }) => {
//   return (
//     <FormControl
//       style={{
//         marginTop: "3%",
//         alignSelf: "center",
//       }}
//     >
//       <InputLabel>
//         <b>{title}</b>
//       </InputLabel>
//       <Select
//         required
//         style={{
//           marginBottom: "15px",
//           minWidth: "50",
//         }}
//         value={value}
//         onChange={handleChange}
//         name={name}
//         {...props}
//       >
//         {options &&
//           options.map((option) => (
//             <MenuItem
//               style={{ fontSize: "11pt", fontFamily: "Armata" }}
//               key={name + '_' + option[customKey]}
//               value={option[fieldValue]}
//             >
//               {option[fieldTextValue]?.toUpperCase()}
//               {fieldTextValue2 && ' - ' + option[fieldTextValue2]?.toUpperCase()}
//             </MenuItem>
//           ))}
//       </Select>
//     </FormControl>
//   )
// }


// const defaultSelEdificio = [{ pID: 0, Nombre: "Seleccionar Edificio", id: 0, horario: "Seleccionar Edificio" }]


const ValidateHorariosCampAntigripal = (edificio, idHorario, fecha) => {
  if ((edificio == 26 || edificio == 30) && fecha == '2022-05-10') {
    return ((idHorario >= 843 && idHorario <= 848) || (idHorario >= 801 && idHorario <= 806))
  }

  if ((edificio == 26) && fecha == '2022-05-19') {
    return ((idHorario > 787 && idHorario < 795))
  }

  return false
}

//en este metodo se envia la fecha a la base para recuperar el resto de los datos
const Reserva = () => {
  const { t, i18n } = useTranslation();
  const [submit, setSubmit] = useState(false);
  const [feriados] = useState([]);
  const [pisos, setPisos] = useState(null);
  const [horarios, setHorarios] = useState(null);
  const [fechaSel, setFechaSel] = useState(formatISO(new Date(), { representation: "date" }))
  const alert = useAlert();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cupoE, setCupoE] = useState(0);
  const [cupoP, setCupoP] = useState(0);
  const [cupoH, setCupoH] = useState(0);
  const [conEstacionamiento, setConEstacionamiento] = useState(false);
  const [edificioConEstacionamiento, setEdificioConEstacionamiento] = useState(false);
  const [IdGerencia, setGerencia] = useState('');
  // const [gerenciaEdit, setGerenciaEdit ] = useState(false)
  // const [pais, setPais] = useState(1) // default Argentina

  const { gerencias, loadingGerencias } = useGerencias();
  const { usuarios, loadingUsuarios } = useUsuariosDependientes();
  const { edificios, loadingEdificios } = useEdificios(fechaSel, IdGerencia)
  // const { paises } = usePaises();
  const { empresas, loadingEmpresas } = useEmpresas();
  const [empresa, setEmpresa] = useState(1);
  const user = getUser()
  const [permisoEstacionamiento] = usePermisosEstacionamiento(user?.Email)
  const boolCampAntigripal = useValidateCampAntigripal(user?.Email, fechaSel)

  console.log('boolCampAntigripal', boolCampAntigripal)
  let company = localStorage.getItem('company')

  const formik = useFormik({
    initialValues: {
      fecha: "",
      usuario: getUser().userId,
      edificio: "",
      piso: "",
      hora: "",
    },
    onSubmit: (values) => {
      const { usuario, edificio, piso, hora } = values;
      if (!validateVacunacionAntigripal(edificio, fechaSel)) {
        swal
          .fire({
            title: "Advertencia",
            text: "Estará disponible los dias: 07/07 (10 a 17hs)",
            icon: "warning",
            confirmButtonColor: "#009bdb",
            confirmButtonText: "OK",
            animation: true,
          })
      } else {
        setLoading(true);
        guardarReserva(usuario, fechaSel, piso, edificio, hora, conEstacionamiento);
      }

    },
  });

  const [cupoCochera, loadingCupoCochera] = useCupoCocheras(fechaSel, formik.values.edificio)

  useEffect(() => {
    //isDiagActive();
    let usuario = getUser();
    if (usuario?.IdGerencia) {
      setGerencia(usuario?.IdGerencia)
      // setGerenciaEdit(true)
    }
    setLoading(false)
  }, []);

  const resetValues = useCallback(() => {
    formik.values.edificio = "";
    formik.values.usuario = getUser().userId;
    formik.values.piso = "";
    formik.values.hora = "";
    setSubmit(false);
    setConEstacionamiento(false);
  }, [setSubmit, setConEstacionamiento, formik]);

  // const isDiagActive = () => {

  //   getUserDiagnosticoValido().then(response => {
  //     if (!response.data) {
  //       swal
  //         .fire({
  //           title: "Advertencia",
  //           text: "Debe realizar el autodiagóstico para poder reservar un turno.",
  //           icon: "warning",
  //           confirmButtonColor: "#009bdb",
  //           confirmButtonText: "OK",
  //           animation: true,
  //         })
  //         .then((result) => {
  //           if (result.value) {
  //             window.location.replace("/AutoDiagnostico");
  //           }
  //         });
  //     }
  //   })
  //     .catch(function (error) {
  //       if (error.response == undefined)
  //         alert.show("" + error);
  //       else
  //         alert.show("" + error.response.data.error);
  //     });

  // }

  const handleDateChange = useCallback(async (date) => {
    resetValues();
    const fechaAux = formatISO(new Date(`${date}`), {
      representation: "date",
    });
    setFechaSel(fechaAux);
  }, [resetValues, setFechaSel])

  const setInfoEdificio = useCallback((value) => {
    let _edificio = edificios.find(e => e.eID === value)
    if (_edificio) {
      setEdificioConEstacionamiento(_edificio.ConEstacionamiento === 1 ? true : false)
      setCupoE(_edificio.Cupo);
    }
  }, [edificios])

  const setCupoPiso = useCallback((value) => {
    let piso = pisos.find(p => p.pID === value)
    if (piso) {
      setCupoP(piso.Cupo)
    }
  }, [pisos])

  const setCupoHorario = useCallback((value) => {
    let hr = horarios.find(h => h.id === value)
    if (hr) {
      setCupoH(hr.Cupo);
    }
  }, [horarios])

  const handleEdificiosChange = useCallback(async (idEdificio) => {
    if (idEdificio) {
      const [pisos, horas] = await Promise.all([
        getPisos(idEdificio, fechaSel, IdGerencia),
        getHoras(idEdificio, fechaSel)
      ])
      setPisos(pisos.data);
      setHorarios(horas.data);
      setInfoEdificio(idEdificio);
      setConEstacionamiento(false)
    }
  }, [IdGerencia, fechaSel, setInfoEdificio])

  const handleGerenciasChange = useCallback((idGerencia) => {
    if (idGerencia) {
      setGerencia(idGerencia);
      resetValues()
    }
  }, [setGerencia, resetValues])

  const handleEmpresaChange = useCallback((idEmpresa) => {
    if (idEmpresa) {
      setEmpresa(idEmpresa);
      resetValues()
    }
  }, [setEmpresa, resetValues])

  // function handlePaisChange(idPais) {
  //   if (idPais) {
  //     setPais(idPais);
  //     resetValues()
  //   }
  // }

  const handleChangeEstacionamiento = useCallback(() => {
    setConEstacionamiento(!conEstacionamiento);
  }, [setConEstacionamiento, conEstacionamiento])

  const handleSubmit = () => {
    setSubmit(true);
  }

  const guardarReserva = (idUsuario, FechaTurno, IdPiso, IdEdificio, idHorarioEntrada, conEstacionamiento) => {
    saveTurno(idUsuario, FechaTurno, IdPiso, IdEdificio, idHorarioEntrada, conEstacionamiento, IdGerencia)
      .then(response => {
        alert.show("Reserva grabada con exito!");
        setLoading(false);
        setDone(true);
      })
      .catch(function (error) {
        if (error.response === undefined || !error.response) {
          alert.show("" + error);
          setLoading(false);
        }
        else {
          alert.show("" + error.response.data.error);
          setLoading(false);
        }
      });
  };

  const getEdificiosCampAntigripal = (edificios, fecha) => {
    if (fecha === '2022-07-07') {
      return edificios.filter(e => e.eID != 30)
    }
    // else {
    //   return edificios.filter(e => e.eID != 30)
    // }
  }

  return (

    <div>
      <Header />
      <Sidebar />
      {loading ||
        loadingGerencias ||
        loadingUsuarios ||
        loadingEmpresas ? (
        <MySpinner />
      ) : (
        <div>
          <br />
          <Container maxWidth="sm">
            <h1 className='ExpertaText'>{t("reserva>title>turn")}</h1>
            <p >{t("reserva>paragraph>reserve")}</p>
            <br />
            <BtnBack to={'/Reporte'} />
            <br />
            <form onSubmit={formik.handleSubmit}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}>


              <FormControl style={{ alignSelf: "center" }}>
                <InputLabel>{t("reserva>titlelabel>choseDate")}</InputLabel>
                <div id="datePicker">
                  <DatePicker
                    style={style.datePicker}
                    id="fecha"
                    locale={i18n.language}
                    selected={formik.values.fecha}
                    name="fecha"
                    placeholderText={t("reserva>titlelabel>choseDate")}
                    onChange={(date) => handleDateChange(date)}
                    dateFormat="MMMM d, yyyy"
                    filterDate={isWeekday}
                    minDate={setMinutes(addDays(new Date(), 0), 30)}
                    maxDate={setMinutes(addDays(new Date(), 30), 30)}
                    showDisabledMonthNavigation
                    inline={formik.values.sucursalId !== ""}
                    excludeDates={populateFeriados(feriados)}
                  />

                </div>

                {!permisoEstacionamiento ?
                  < InputLabel style={style.inputLabel}>
                    {t('reserva>titlelabel>permisoEstacionamiento')}
                  </InputLabel>
                  :
                  company == 'directv' &&
                  <BtnBack
                    style={style.btnBack}
                    to={'/ReservaCochera'}
                    title={t("reserva>button>soloestacionamiento")}
                  />
                }
              </FormControl>

              {/* <CustomSelectForm
                title='País'
                value={pais}
                handleChange={(e) => { handlePaisChange(e.target.value) }}
                options={paises}
                customKey='Id'
                fieldValue='Id'
                fieldTextValue='Nombre'
                name="pais"
              // disabled={true} //esto se va desactivar en los usuarios experta
              /> */}

              {empresas && <CustomSelectForm
                title={t("reserva>titlelabel>business")}
                value={empresa}
                handleChange={(e) => { handleEmpresaChange(e.target.value) }}
                options={empresas}
                customKey='Id'
                fieldValue='Id'
                fieldTextValue='Nombre'
                name="empresa"
              />}


              {gerencias && <CustomSelectForm
                title={t("reserva>titlelabel>managfement")}
                value={IdGerencia}
                handleChange={(e) => { handleGerenciasChange(e.target.value) }}
                options={gerencias.filter(ger => ger.idEmpresa === empresa)}
                customKey='Id'
                fieldValue='Id'
                fieldTextValue='Nombre'
                name="Gerencia"
                traduccion
              />
              }

              {!loadingEdificios ?
                <>
                  {edificios && <CustomSelectForm
                    title={t("reserva>titlelabel>site")}
                    value={formik.values.edificio}
                    handleChange={(e) => {
                      formik.handleChange(e);
                      handleEdificiosChange(e.target.value);
                    }}
                    //options={boolCampAntigripal ? edificios : edificios.filter(e => e.eID!=26)}
                    options={boolCampAntigripal ? getEdificiosCampAntigripal(edificios, fechaSel) : edificios.filter(e => e.eID != 26 && e.eID != 30)}
                    customKey='eID'
                    fieldValue='eID'
                    fieldTextValue='Nombre'
                    fieldTextValue2='Direccion'
                    name="edificio"
                  />}

                  {cupoE != null && (
                    <InputLabel style={{ fontSize: "10pt", textAlign: "right", color: "rgb(63, 80, 97)" }}>
                      <b>{`Cupo: ${cupoE}`}</b>
                    </InputLabel>
                  )}

                  {usuarios && <CustomSelectForm
                    title={t("reserva>titlelabel>collaborate")}
                    value={formik.values.usuario}
                    handleChange={(e) => formik.handleChange(e)}
                    options={usuarios}
                    customKey='IdUsuario'
                    fieldValue='IdUsuario'
                    fieldTextValue='nombre'
                    name="usuario"
                  />}


                  {pisos && <CustomSelectForm
                    title={t("reserva>titlelabel>floor")}
                    value={formik.values.piso}
                    handleChange={(e) => {
                      formik.handleChange(e);
                      setCupoPiso(e.target.value);
                    }}
                    options={pisos}
                    customKey='pID'
                    fieldValue='pID'
                    fieldTextValue='Nombre'
                    name="piso"
                  />}

                  {pisos && cupoP != null && (
                    <InputLabel style={{ fontSize: "10pt", textAlign: "right", color: "rgb(63, 80, 97)" }}>
                      <b>{`Cupo: ${cupoP}`}</b>
                    </InputLabel>
                  )}

                  {horarios && <CustomSelectForm
                    title={t("reserva>titlelabel>schedule")}
                    value={formik.values.hora}
                    handleChange={(e) => {
                      formik.handleChange(e);
                      setCupoHorario(e.target.value);
                      handleSubmit() // activa el boton submit
                    }}
                    // options={horarios}
                    options={boolCampAntigripal ? horarios.filter(h => !ValidateHorariosCampAntigripal(formik.values.edificio, h.id, fechaSel)) : horarios}
                    customKey='id'
                    fieldValue='id'
                    fieldTextValue='horario'
                    name="hora"
                  />}

                  {horarios && cupoH != null && (
                    <InputLabel style={{ fontSize: "10pt", textAlign: "right", color: "rgb(63, 80, 97)" }}>
                      <b>{`Cupo: ${cupoH}`}</b>
                    </InputLabel>
                  )}

                  {!permisoEstacionamiento &&
                    <InputLabel style={{ fontSize: "10pt", textAlign: "right", color: "rgb(63, 80, 97)", marginTop: 40 }}>
                      {t('reserva>titlelabel>permisoEstacionamiento')}
                    </InputLabel>
                  }
                  {edificioConEstacionamiento &&
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={conEstacionamiento} onChange={handleChangeEstacionamiento} value="conEstacionamiento" />}
                          label={t("reserva>label>parking") + ` - Cupo: ${cupoCochera}`}
                          disabled={(cupoCochera === 0 || loadingCupoCochera || !permisoEstacionamiento)}
                        />
                      </FormGroup>
                    </FormControl>
                  }

                  <div style={{ marginTop: "3%", alignSelf: "center" }}>
                    {submit && (
                      <Button
                        style={{ alignSelf: "center", textTransform: "none", textAlign: "center", backgroundColor: "#2f4050", color: "white" }}
                        variant="contained"
                        type='submit'>{t("reserva>button>confirm")}</Button>
                    )}
                  </div>
                </>
                : <MySpinner />
              }


            </form>
          </Container>
        </div>)
      }
      <br />
      {done ? <Redirect to="/MisReservas" /> : null}
    </div >
  );
}
export default Reserva;