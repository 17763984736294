import React, { useCallback, useEffect, useState } from "react";
import swal from "sweetalert2";

import { getUsuarios, deleteUsuario } from "../../apis/UsuariosAPI";
import { MyTable } from "../../components/MyTable";
import { useAbmStyles } from "./styles";
import { DefaultLayout } from "./container/DefaultLayout";
import { BtnNew } from "../../components/BtnNew";
import { useHistory } from "react-router";
import { usePermisosUsuario } from "../../hooks/usePermisosUsuario";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@material-ui/core";
import { EditarUsuario } from "./EditarUsuario";

const Usuarios = (props) => {
  const { t } = useTranslation();
  usePermisosUsuario([4])
  const [loading, setLoading] = useState(true)
  const classes = useAbmStyles();
  const [usuarios, setUsuarios] = useState([])
  let history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState()

  const cargarUsuarios = useCallback(() => {
    setLoading(true);
    getUsuarios()
      .then(res => {
        setLoading(false)
        res?.data && setUsuarios(res?.data)
      })
      .catch(err => setLoading(false))
  }, [])

  useEffect(() => {
    cargarUsuarios();
  }, [cargarUsuarios]);



  const handleDelete = useCallback((usuario) => {
    swal.fire({
      title: '¿Estás seguro que deseas desactivar al usuario?',
      // text: "No podrás revertir los cambios",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUsuario(usuario.IdUsuario)
          .then(res => cargarUsuarios())
      }
    })
  }, [cargarUsuarios])

  const handleEdit = useCallback((user) => {
    setSelectedUser(user)
    console.log(user);
    setModalOpen(true)
  }, [])

  const handleEditSuccess = () => {
    setSelectedUser()
    setModalOpen(false)
    cargarUsuarios();
  }

  return (
    <DefaultLayout
      urlBack='/Abms'
      title={t("usuarios>title>users")}
      loading={loading}
    >
      <BtnNew onClick={() => history.push('/nuevoUsuario')} title={t("usuarios>title>newUsers")} className={classes.btn} />

      <MyTable
        data={usuarios.filter(u => u.Activo === 1)}
        edit
        headers={['IdUsuario', 'Tipo', 'Email']}
        fields={['IdUsuario', 'IdTipoDeUsuario', 'Email']}
        borrar
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        PageSize={8}
        search={true}
      />

      <Modal open={modalOpen}>
        <div className={classes.paper}>
          <Button className={classes.btnCancel} onClick={() => setModalOpen(false)}>x</Button>
          <div align='center' style={{ paddingTop: 50, overflowX: 'scroll', height: '100%' }}>
            {<EditarUsuario
              usuario={selectedUser}
              handleEditSuccess={handleEditSuccess}
            />}
          </div>
        </div>
      </Modal>

    </DefaultLayout>

  );
}

export default Usuarios;