import React from 'react';
import { BarChart } from 'reaviz';

const data = [
  { key: '1', data: 0 },
  { key: '2', data: 0 },
  { key: '3', data: 0 },
  { key: '4', data: 14 },
  { key: '5', data: 11 },
  { key: '6', data: 18 },
  { key: '7', data: 20 },
  { key: '8', data: 0 },
  { key: '9', data: 0 },
  { key: '10', data: 0 },
  { key: '11', data: 15 },
  { key: '12', data: 18 },
  { key: '13', data: 20 },
  { key: '14', data: 2},
  { key: '15', data: 0 },
  { key: '16', data: 0 },
  { key: '17', data: 0 },
  { key: '18', data: 6 },
  { key: '19', data: 8 },
  { key: '20', data: 9 },
  { key: '21', data: 12},
  { key: '22', data: 0 },
  { key: '23', data: 0 },
  { key: '24', data: 0 },
  { key: '25', data: 14 },
  { key: '26', data: 5 },
  { key: '27', data: 18 },
];
export const BarChart2 = () => {
  return <div style={{margin:20}}>
    <BarChart width={'90vw'} height={250} data={data} />
  </div>
};
