import { useCallback, useEffect } from 'react'
import { getUser } from "../utils/auth-helper";
import { useHistory } from "react-router";

export const usePermisosUsuario = (tiposPermitidos = []) => {
    const history = useHistory()

    const GetTipoDeUsuario = useCallback(async () => {
        const userTipo = await getUser()?.IdTipoDeUsuario;
        if (!userTipo || !tiposPermitidos.includes(userTipo)) {
            history.push('/')
        }
    }, [tiposPermitidos, history])

    useEffect(()=>{
        GetTipoDeUsuario()
    }, [tiposPermitidos, GetTipoDeUsuario])

   

    return []
}
