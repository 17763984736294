import { v4 as uuid } from 'uuid';

export default [{
  id: uuid(),
  country: 'Comercial seguros',
  value: 13
}, {
  id: uuid(),
  country: 'Agro',
  value: 3
}, {
  id: uuid(),
  country: 'Auditoria',
  value: 2
}, {
  id: uuid(),
  country: 'Judicial',
  value: 12
}, {
  id: uuid(),
  country: 'Marketing',
  value: 3
}, {
  id: uuid(),
  country: 'ART',
  value: 43
}, {
  id: uuid(),
  country: 'RRHH/Compras',
  value: 7
}, {
  id: uuid(),
  country: 'Salud Prestaciones',
  value: 26
}, {
  id: uuid(),
  country: 'Tecnica',
  value: 3
}, {
    id: uuid(),
    country: 'Admin y Finanzas',
    value: 3
  }, {
    id: uuid(),
    country: 'Alimentos',
    value: 1
  }, {
    id: uuid(),
    country: 'IT desarrollo',
    value: 3
  }, {
    id: uuid(),
    country: 'Legal Corpo',
    value: 1
  }, {
    id: uuid(),
    country: 'Demas',
    value: 3
  }];
