import React, { useState, useEffect, useCallback } from "react";

import {
  Container,
  Grid,
  Button,
  Modal
} from '@material-ui/core';

import { Formik, Form } from 'formik';
import swal from "sweetalert2";

import Header from '../../shared/Header';
import Sidebar from '../Sidebar2';
import { useEdificioDetails } from "../../hooks/useEdificioDetails";
import { MyTextInput, MySelect, MyTable, BtnBack, BtnNewSmall } from '../../components'
import { getGerencias } from "../../apis/UsuariosAPI";
import { SWAL_ERROR, SWAL_SUCCESS, SWAL_SUCCESS_EDIT } from '../../components/SwalMessages';
import { agregarGerenciaAlPiso, editarGerenciaPiso } from "../../apis/GerenciasAPI";
import { NuevoPisoEdificioForm } from "./NuevoPisoEdificio";
import { deleteGerenciaPiso, deletePiso } from "../../apis/PisosAPI";
import { createHorarioEntrada, deleteHorario, editarHorario } from "../../apis/HorariosEntradaAPI";
import { validationGerenciaPiso, validationHorario } from './validations'
import { useAbmStyles } from "./styles";
import { usePermisosUsuario } from "../../hooks/usePermisosUsuario";

//TODO: sacar a un archivo
export const NuevaGerenciaPiso = ({ IdPiso, onClose, editar, selectedGerPiso }) => {
  const classes = useAbmStyles();
  const [gerencias, setGerencias] = useState([]);

  useEffect(() => {
    getGerencias()
      .then(res => res ? setGerencias(res?.data) : setGerencias([]))
  }, [])

  function submit(data) {
    agregarGerenciaAlPiso(data)
      .then(res => {
        res?.data?.status === 400 ?
          SWAL_ERROR()
          : SWAL_SUCCESS()
        onClose()
      })
  }

  function guardarCambios(data) {
    editarGerenciaPiso(data)
      .then(res => {
        res?.data?.status === 400 ? SWAL_ERROR() : SWAL_SUCCESS_EDIT()
        onClose()
      })

  }


  return (
    <div align='center' style={{ paddingTop: 50 }}>
      <h1>{editar ? 'Editar' : 'Agregar'} gerencia en piso </h1>
      <Formik
        initialValues={editar ? selectedGerPiso : {
          IdPiso: IdPiso,
          Cupo: 0,
          IdGerencia: ''
        }}
        onSubmit={(values) => editar ? guardarCambios(values) : submit(values)}
        validationSchema={validationGerenciaPiso}>

        {(formik) => (
          <Form>

            <MyTextInput
              label="Cupo"
              name="Cupo"
              placeholder="ingresar un Cupo..."
              type='number'
            />

            <MySelect label="Gerencia" name="IdGerencia" >
              <option value='' >Seleccionar gerencia...</option>
              {gerencias && gerencias.map(gerencia => {
                return <option key={gerencia.id} value={gerencia.id}>{gerencia.Nombre}</option>
              })}
            </MySelect>

            <button type="submit" className={classes.btn} >
              {editar ? 'Guardar cambios' : 'Confirmar'}
            </button>

          </Form>
        )}
      </Formik>
    </div>
  )
}
//TODO: sacar a un archivo
export const NuevoHorario = ({ IdEdificio, onClose, editar, selectedHorario }) => {
  const classes = useAbmStyles();

  function submit(data) {
    createHorarioEntrada(data)
      .then(res => {
        res?.data?.status === 400 ? SWAL_ERROR() : SWAL_SUCCESS()
        onClose()
      })

  }

  function guardarCambios(data) {
    editarHorario(data)
      .then(res => {
        res?.data?.status === 400 ? SWAL_ERROR() : SWAL_SUCCESS_EDIT()
        onClose()
      })
  }


  return (
    <div align='center' style={{ paddingTop: 50 }}>
      <h1>{editar ? 'Editar' : 'Agregar'} horario de entrada en el edificio</h1>
      <Formik
        initialValues={editar ? selectedHorario : {
          IdEdificio: IdEdificio,
          Cupo: 0,
          Horario: ''
        }}
        onSubmit={(values) => editar ? guardarCambios(values) : submit(values)}
        validationSchema={validationHorario}
      >

        {(formik) => (
          <Form>

            <MyTextInput
              label="Cupo"
              name="Cupo"
              placeholder="ingresar un Cupo..."
              type='number'
            />

            <MyTextInput
              label="Horario"
              name="Horario"
              type='time'
            />

            <button type="submit" className={classes.btn} >
              {editar ? 'Guardar cambios' : 'Confirmar'}
            </button>

          </Form>
        )}
      </Formik>
    </div>
  )
}

const EditarEdificio = ({ match }) => {
  usePermisosUsuario([4])
  const classes = useAbmStyles();
  const [selectedPiso, setSelectedPiso] = useState('')

  const handlequery = () => {
    return new URLSearchParams(window.location.href.split("?")[1]);
  };

  const { edificio, pisos, gerencias, horariosEntrada, refresh } = useEdificioDetails(handlequery().get("id"), selectedPiso)

  const [modalOpenPiso, setModalOpenPiso] = useState(false)
  const [modalOpenGeren, setModalOpenGeren] = useState(false)
  const [modalOpenHorario, setModalOpenHorario] = useState(false)
  const [editar, setEditar] = useState(false)

  const [selectedGerPiso, setSelectedGerPiso] = useState()
  const [selectedHorario, setSelectedHorario] = useState()


  const handleSelect = (item) => {
    setSelectedPiso(item)
  }

  const handleRefresh = useCallback(() => {
    setModalOpenGeren(false)
    setModalOpenPiso(false)
    setModalOpenHorario(false)
    refresh()
  }, [setModalOpenGeren, setModalOpenPiso, setModalOpenHorario, refresh])

  const handleDeleteGerenciaPiso = useCallback((gerenciapiso) => {
    swal.fire({
      title: '¿Estás seguro que deseas eliminar?',
      text: "No podrás revertir los cambios",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteGerenciaPiso(gerenciapiso.Id)
          .then(res => refresh())
      }
    })
  }, [refresh])

  const handleDeletePiso = useCallback((piso) => {
    swal.fire({
      title: '¿Estás seguro que deseas eliminar?',
      text: "No podrás revertir los cambios",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        deletePiso(piso.Id)
          .then(res => refresh())
      }
    })
  }, [refresh])

  const handleDeleteHorario = useCallback((horario) => {
    swal.fire({
      title: '¿Estás seguro que deseas eliminar?',
      text: "No podrás revertir los cambios",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteHorario(horario.Id)
          .then(res => refresh())
      }
    })
  }, [refresh])


  // TODO: hacer hooks de todo esto
  const handleNuevaGerencia = useCallback(() => {
    setModalOpenGeren(true)
    setEditar(false)
    setSelectedGerPiso()
  }, [setModalOpenGeren, setEditar, setSelectedGerPiso])

  const handleEditGerencia = useCallback((gerencia) => {
    setEditar(true);
    setSelectedGerPiso(gerencia)
    setModalOpenGeren(true)
  }, [setEditar, setSelectedGerPiso, setModalOpenGeren])

  const handleNuevoHorario = useCallback(() => {
    setModalOpenHorario(true)
    setEditar(false)
    setSelectedHorario()
  }, [setModalOpenHorario, setEditar, setSelectedHorario])

  const handleEditHorario = useCallback((hr) => {
    setEditar(true);
    setSelectedHorario(hr)
    setModalOpenHorario(true)
  }, [setEditar, setSelectedHorario, setModalOpenHorario])
  //---->

  return (
    <div>
      <Header />
      <Sidebar />
      {edificio && <div>
        <Container>

          <h1 className='ExpertaText'>Edificio: {edificio.Nombre} </h1>

          <BtnBack to='/Edificios' />
          <br />
          
          <div className={classes.root}>


            <Grid container spacing={3}>
              <Grid item xs={12} md={6} className={classes.border}>

                <h3 className='ExpertaText'>Pisos</h3>

                <BtnNewSmall onClick={() => setModalOpenPiso(true)} />

                <MyTable
                  data={pisos}
                  edit
                  headers={['Id', 'Nombre', 'Piso']}
                  fields={['Id', 'Nombre', 'Numero']}
                  handleSelect={handleSelect}
                  customKey={'Id'}
                  borrar
                  handleDelete={handleDeletePiso}
                />

              </Grid>

              <Grid item xs={12} md={6} className={classes.border}>

                <h3 className='ExpertaText'>Gerencias en el piso: {selectedPiso && selectedPiso.Numero}</h3>

                <BtnNewSmall onClick={handleNuevaGerencia} disabled={!selectedPiso} />

                <MyTable
                  data={gerencias}
                  edit
                  handleEdit={handleEditGerencia}
                  headers={['Id', 'Gerencia', 'Cupo']}
                  fields={['Id', 'gerencia', 'Cupo']}
                  customKey='Id'
                  borrar
                  handleDelete={handleDeleteGerenciaPiso}
                />

              </Grid>


              <Grid item xs={12} md={6} className={classes.border}>

                <h3 className='ExpertaText'>Horarios de entrada</h3>

                <BtnNewSmall onClick={handleNuevoHorario} />

                <MyTable
                  data={horariosEntrada}
                  edit
                  handleEdit={handleEditHorario}
                  headers={['Id', 'Horario', 'Cupo']}
                  fields={['Id', 'Horario', 'Cupo']}
                  customKey={'Id'}
                  borrar
                  handleDelete={handleDeleteHorario}
                />

              </Grid>

            </Grid>

            {/*TODO: extraer a componente estos modales*/}
            <Modal onClose={() => setModalOpenPiso(false)} open={modalOpenPiso}>
              <div className={classes.paper}>
                <Button className={classes.btnCancel} onClick={() => setModalOpenPiso(false)}>x</Button>
                <h1>Crear nuevo piso en Edificio</h1>
                <div align='center' style={{ paddingTop: 50 }}>
                  <NuevoPisoEdificioForm
                    idEdificio={handlequery().get("id")}
                    onClose={handleRefresh}
                  />
                </div>
              </div>
            </Modal>

            <Modal open={modalOpenGeren} onClose={() => setModalOpenGeren(false)}>
              <div className={classes.paper}>
                <Button className={classes.btnCancel} onClick={() => setModalOpenGeren(false)}>x</Button>
                <NuevaGerenciaPiso
                  IdPiso={selectedPiso?.Id}
                  onClose={handleRefresh}
                  editar={editar}
                  selectedGerPiso={selectedGerPiso}

                />
              </div>
            </Modal>

            <Modal open={modalOpenHorario} onClose={() => setModalOpenHorario(false)}>
              <div className={classes.paper}>
                <Button className={classes.btnCancel} onClick={() => setModalOpenHorario(false)}>x</Button>
                <NuevoHorario
                  IdEdificio={handlequery().get("id")}
                  onClose={handleRefresh}
                  editar={editar}
                  selectedHorario={selectedHorario}
                />
              </div>
            </Modal>
          </div>
        </Container>
      </div>}



    </div>
  );
}

export default EditarEdificio;