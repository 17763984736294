import { useState, useEffect, useCallback } from 'react'

const USUARIOS_PERMITIDOS = [
    "santiagoalarcon2@hotmail.com",
    "federico.demarco@experta.com.ar",
    "aldana.antinori@experta.com.ar",
    "analia.silva@experta.com.ar",
    "roberto.avello@experta.com.ar",
    "guillermo.rackevicius@experta.com.ar",
    "wright@experta.com.ar",
    "lucam@experta.com.ar",
    "furiasse@experta.com.ar",
    "gerdel@experta.com.ar",
    "aban@experta.com.ar",
    "dominguez@experta.com.ar",
    "favre@experta.com.ar",
    "gandos@experta.com.ar",
    "rodriguezniebla@experta.com.ar",
    "romero@experta.com.ar",
    "boscaglia@experta.com.ar",
    "mantova@experta.com.ar",
    "maldonado@experta.com.ar",
    "pasayo@experta.com.ar",
    "cahe@experta.com.ar",
    "buscaldi@experta.com.ar",
    "pisani@experta.com.ar",
    "moro@experta.com.ar",
    "cocimano@experta.com.ar",
    "fedrizzie@experta.com.ar",
    "barreram@experta.com.ar",
    "gonzalezm@experta.com.ar",
    "badaracco@experta.com.ar",
    "escobara@experta.com.ar",
    "derrico@experta.com.ar",
    "dellitalo@experta.com.ar",
    "spassoff@experta.com.ar",
    "roz@experta.com.ar",
    "carrivale@experta.com.ar",
    "rota@experta.com.ar",
    "rodriguezc@experta.com.ar",
    "fernandezs@experta.com.ar",
    "pepe@experta.com.ar",
    "stella@experta.com.ar",
    "camposc@experta.com.ar",
    "barrera@experta.com.ar",
    "varela@experta.com.ar",
    "scarantino@experta.com.ar",
    "sauser@experta.com.ar",
    "torry@experta.com.ar",
    "redondo@experta.com.ar",
    "cano@experta.com.ar",
    "guerra@experta.com.ar",
    "goldstein@experta.com.ar",
    "zeisel@experta.com.ar",
    "podesta@experta.com.ar",
    "callegaris@experta.com.ar",
    "ciraolo@experta.com.ar",
    "grynszpan@experta.com.ar",
    "pastor@experta.com.ar",
    "coto@experta.com.ar",
    "fedrizzi@experta.com.ar",
    "durante@experta.com.ar",
    "amilibia@experta.com.ar",
    "pavese@experta.com.ar",
    "altube@experta.com.ar",
    "arnaud@experta.com.ar",
    "brito@experta.com.ar",
    "delamo@experta.com.ar",
    "irrazabal@experta.com.ar",
    "rico@experta.com.ar",
    "caielli@experta.com.ar",
    "ward@experta.com.ar",
    "colomboj@experta.com.ar",
    "damato@experta.com.ar",
    "pena@experta.com.ar",
    "olaechea@experta.com.ar",
    "aramburu@experta.com.ar",
    "defelice@experta.com.ar",
    "denapoli@experta.com.ar",
    "galarza@experta.com.ar",
    "semelis@experta.com.ar",
    "gauto@experta.com.ar",
    "canedi@experta.com.ar",
    "indovino@experta.com.ar",
    "saenzvaliente@experta.com.ar",
    "amabile@experta.com.ar",
    "fernandezc@experta.com.ar",
    "chevalier@experta.com.ar",
    "ferrer@experta.com.ar",
    "sosa@experta.com.ar",
    "escande@experta.com.ar",
    "casal@experta.com.ar",
    "alganaraz@experta.com.ar",
    "ostanel@experta.com.ar",
    "simoine@experta.com.ar",
    "lopezn@experta.com.ar",
    "blancom@experta.com.ar",
    "lanteri@experta.com.ar",
    "daniel_pelegri@yahoo.com.ar",
    "innecco@experta.com.ar",
    "palacios@experta.com.ar",
    "rolando@experta.com.ar",
    "capistrano@experta.com.ar",
    "digangi@experta.com.ar",
    "segalatf@experta.com.ar",
    "barrientos@experta.com.ar",
    "valero@experta.com.ar",
    "peguito@experta.com.ar",
    "verdi@experta.com.ar",
    "leivak@experta.com.ar",
    "bregliano@experta.com.ar",
    "rey@experta.com.ar",
    "luconi@experta.com.ar",
    "santillana@experta.com.ar",
    "zalba@experta.com.ar",
    "hirschfeldt@experta.com.ar",
    "aranda@experta.com.ar",
    "keena@experta.com.ar",
    "fichera@experta.com.ar",
    "miler@experta.com.ar",
    "rodriguezg@experta.com.ar",
    "pirri@experta.com.ar",
    "marcovecchio@experta.com.ar",
    "cabral@experta.com.ar",
    "aldana@experta.com.ar",
    "botta@experta.com.ar",
    "salto@experta.com.ar",
    "blancog@experta.com.ar",
    "alvarado@experta.com.ar",
    "cisiano@experta.com.ar",
    "garciaj@experta.com.ar",
    "garciam@experta.com.ar",
    "pirone@experta.com.ar",
    "stein@experta.com.ar",
    "aguirre@experta.com.ar",
    "haber@experta.com.ar",
    "kesseler@experta.com.ar",
    "bringas@experta.com.ar",
    "fogel@experta.com.ar",
    "coro@experta.com.ar",
    "martinez@experta.com.ar",
    "amendolara@experta.com.ar",
    "monastras@experta.com.ar",
    "peralta@experta.com.ar",
    "verardo@experta.com.ar",
    "loetti@experta.com.ar",
    "palermo@experta.com.ar",
    "dalmolin@experta.com.ar",
    "ibarrap@experta.com.ar",
    "ibanez@experta.com.ar",
    "lanzillota@experta.com.ar",
    "pacheco@experta.com.ar",
    "genazzini@experta.com.ar",
    "sottil@experta.com.ar",
    "haiek@experta.com.ar",
    "quiroga@experta.com.ar",
    "massollo@experta.com.ar",
    "franco@experta.com.ar",
    "guardias@experta.com.ar",
    "coppola@experta.com.ar",
    "fagliano@experta.com.ar",
    "garciad@experta.com.ar",
    "munozib@experta.com.ar",
    "ldegiorgio@experta.com.ar",
    "ramos@experta.com.ar",
    "gala.berizzo@grupowerthein.com",
    "grisel.manca@experta.com.ar",
    "mahmud@experta.com.ar",
    "natalia.munoz@experta.com.ar",
    "perretta@experta.com.ar",
    "cena@experta.com.ar",
    "carranza@experta.com.ar",
    "marcelo.castillo@experta.com.ar",
    "ndorreis@experta.com.ar",
    "millan.fabyhuska@experta.com.ar",
    "mariaeugenia.rojas@experta.com.ar",
    "natalia.dragonetti@experta.com.ar",
    "nicolas.caicedo@experta.com.ar",
    "angeles.suffriti@experta.com.ar",
    "maria.pieroni@experta.com.ar",
    "nicolas.solari@experta.com.ar",
    "valeria.marrelli@experta.com.ar",
    "stefania.roige@experta.com.ar",
    "emmanuel.bazan@experta.com.ar",
    "florencia.trimboli@experta.com.ar",
    "pablo.pini@experta.com.ar",
    "agustin.Iachino@experta.com.ar",
    "lomoc@experta.com.ar",
    "vladimir.carranza@experta.com.ar",
    "maria.pascolutti@experta.com.ar",
    "rocio.attadia@experta.com.ar",
    "marina.alessi@experta.com.ar",
    "SMORELLI@EXPERTA.COM.AR",
    "GUSTAVO.MIRANDA@EXPERTA.COM.AR",
    "SANTIAGO.CUBA@EXPERTA.COM.AR",
    "soledad.fernandez@experta.com.ar",
    "matias.mariani@experta.com.ar",
    "veggetti@experta.com.ar",
    "frean@experta.com.ar",
    "vilasalo@experta.com.ar",
    "cintran@experta.com.ar",
    "uelan@experta.com.ar",
    "zamora@experta.com.ar",
    "bergel@experta.com.ar",
    "orbegoso@experta.com.ar",
    "pidre@experta.com.ar",
    "romeros@experta.com.ar",
    "danesino@experta.com.ar",
    "garciap@experta.com.ar",
    "azcurrain@experta.com.ar",
    "basualdo@experta.com.ar",
    "correia@experta.com.ar",
    "bao@experta.com.ar",
    "rivello@experta.com.ar",
    "godoyl@experta.com.ar",
    "alderete@experta.com.ar",
    "menendez@experta.com.ar",
    "marelli@experta.com.ar",
    "jesica.torres@experta.com.ar",
    "maria.isola@experta.com.ar",
    "german.fernandez@experta.com.ar",
    "romina.nobilia@experta.com.ar",
    "gabriela.garzon@experta.com.ar",
    "sandra.teruel@experta.com.ar",
    "juan.saravi@experta.com.ar",
    "paula.balbuena@experta.com.ar",
    "mperalta@experta.com.ar",
    "maria.giraudo@experta.com.ar",
    "matias.blazquez@experta.com.ar",
    "diego.pineiro@grupowerthein.com",
    "juan.miano@experta.com.ar",
    "ignacio.raggio@experta.com.ar",
    "carla.melo@experta.com.ar",
    "MARIA.GURIDI@EXPERTA.COM.AR",
    "barros@experta.com.ar",
    "agustina.avila@experta.com.ar",
    "noelia.aguilera@experta.com.ar",
    "jpuissant@experta.com.ar",
    "fgiuseppe@experta.com.ar",
    "sboikoski@experta.com.ar",
    "analia.mateo@experta.com.ar",
    "crodriguez@experta.com.ar",
    "lperello@experta.com.ar",
    "agaleano@experta.com.ar",
    "gfuentes@experta.com.ar",
    "fernandezi@experta.com.ar",
    "flores.yesica@experta.com.ar",
    "guillermo.enclusa@experta.com.ar",
    "cristina.giorgi@experta.com.ar",
    "dramisino@experta.com.ar",
    "diazga@experta.com.ar",
    "maestri@experta.com.ar",
    "kmalito@experta.com.ar",
    "fabiana.villa@experta.com.ar",
    "FABIAN.FROMET@EXPERTA.COM.AR",
    "marcelo.franceschini@experta.com.ar",
    "iarreceygor@experta.com.ar",
    "vertel@experta.com.ar",
    "jcasartelli@experta.com.ar",
    "mdesouza@experta.com.ar",
    "jmarconi@experta.com.ar",
    "luciana.bish@experta.com.ar",
    "fruecco@experta.com.ar",
    "lsalvatierra@experta.com.ar",
    "loliva@experta.com.ar",
    "jrodriguez@experta.com.ar",
    "mgiolo@experta.com.ar",
    "mgiorgetti@experta.com.ar",
    "mendez@experta.com.ar",
    "mmaza@experta.com.ar",
    "cbravo@experta.com.ar",
    "esalvioni@experta.com.ar",
    "taipioa@experta.com.ar",
    "gsosa@experta.com.ar",
    "rdipaolo@experta.com.ar",
    "viglesias@experta.com.ar",
    "aferrari@experta.com.ar",
    "msauca@experta.com.ar",
    "jaguilera@experta.com.ar",
    "pcblanco@experta.com.ar",
    "ysaurin@experta.com.ar",
    "mmariani@experta.com.ar",
    "smorinigo@experta.com.ar",
    "nperelman@experta.com.ar",
    "eduardo.loureiro@experta.com.ar",
    "juana.esteban@experta.com.ar",
    "rocio.borras@experta.com.ar",
    "lbachino@experta.com.ar",
    "belen.cludius@experta.com.ar",
    "hugo.perea@experta.com.ar",
    "guillermo.sansone@experta.com.ar",
    "rodrigo.magaz@experta.com.ar",
    "maria.miguel@experta.com.ar",
    "gustavo.lopez@experta.com.ar",
    "melina.alescio@experta.com.ar",
    "maria.castro@experta.com.ar",
    "horacio.santcovsky@experta.com.ar",
    "leonardo.salvai@experta.com.ar",
    "bexalys.torrebilla@experta.com.ar",
    "miravalles@experta.com.ar",
    "pirosuarez@experta.com.ar",
    "luchini@experta.com.ar",
    "gomezjo@experta.com.ar",
    "vecchio@experta.com.ar",
    "savoini@experta.com.ar",
    "loustaun@experta.com.ar",
    "alvado@experta.com.ar",
    "carlos.porzio@grupowerthein.com",
    "piolo@experta.com.ar",
    "juan.charlot@grupowerthein.com",
    "panusopulos@experta.com.ar",
    "santagso@experta.com.ar",
    "carruthers@experta.com.ar",
    "malbran@experta.com.ar",
    "garciaok@experta.com.ar",
    "perezvi@experta.com.ar",
    "garciac@experta.com.ar",
    "perezfl@experta.com.ar",
    "damiano@experta.com.ar",
    "moralesj@experta.com.ar",
    "olivajl@experta.com.ar",
    "trujillo@experta.com.ar",
    "rodriguk@experta.com.ar",
    "rojass@experta.com.ar",
    "amaro@experta.com.ar",
    "otero@experta.com.ar",
    "sangil@experta.com.ar",
    "diogo@experta.com.ar",
    "lichtman@experta.com.ar",
    "maneiro@experta.com.ar",
    "garza@experta.com.ar",
    "alvarezal@experta.com.ar",
    "humphreys@experta.com.ar",
    "carrara@experta.com.ar",
    "gioia@experta.com.ar",
    "papola@experta.com.ar",
    "saavedraa@experta.com.ar",
    "gentilef@experta.com.ar",
    "destefanis@experta.com.ar",
    "domingueznimo@experta.com.ar",
    "colombo@experta.com.ar",
    "insaurralde@experta.com.ar",
    "pizarro@experta.com.ar",
    "ellena@experta.com.ar",
    "maquieira@experta.com.ar",
    "alonsoh@experta.com.ar",
    "lirussi@experta.com.ar",
    "nieto@experta.com.ar",
    "dangeli@experta.com.ar",
    "daboser@experta.com.ar",
    "guemes@experta.com.ar",
    "rossic@experta.com.ar",
    "tomassone@experta.com.ar",
    "juarezml@experta.com.ar",
    "morap@experta.com.ar",
    "trivi@experta.com.ar",
    "bavioc@experta.com.ar",
    "roca@experta.com.ar",
    "safronchik@experta.com.ar",
    "converso@experta.com.ar",
    "brandan@experta.com.ar",
    "ruizj@experta.com.ar",
    "rodrigueztraverso@experta.com.ar",
    "vieu@experta.com.ar",
    "grimm@experta.com.ar",
    "zabalae@experta.com.ar",
    "basilem@experta.com.ar",
    "anderson@experta.com.ar",
    "pierri@experta.com.ar",
    "ferraris@experta.com.ar",
    "mandarano@experta.com.ar",
    "otamendi@experta.com.ar",
    "revellino@experta.com.ar",
    "novosad@experta.com.ar",
    "koselevich@experta.com.ar",
    "marotto@experta.com.ar",
    "balsamo@experta.com.ar",
    "asadauskas@experta.com.ar",
    "arce@experta.com.ar",
    "delunardo@experta.com.ar",
    "gabardi@experta.com.ar",
    "bouza@experta.com.ar",
    "palazzoe@experta.com.ar",
    "jarulakis@experta.com.ar",
    "forgnoni@experta.com.ar",
    "tilli@experta.com.ar",
    "licastro@experta.com.ar",
    "getteg@experta.com.ar",
    "wolovich@experta.com.ar",
    "galuppo@experta.com.ar",
    "sposito@experta.com.ar",
    "aravena@experta.com.ar",
    "rodrigueznr@experta.com.ar",
    "oyarzun@experta.com.ar",
    "cinellit@experta.com.ar",
    "causse@experta.com.ar",
    "ceballosh@experta.com.ar",
    "postigo@experta.com.ar",
    "berenstein@experta.com.ar",
    "oliveraa@experta.com.ar",
    "crevatini@experta.com.ar",
    "visser@experta.com.ar",
    "maslinauskas@experta.com.ar",
    "borellini@experta.com.ar",
    "pitonia@experta.com.ar",
    "navarrom@experta.com.ar",
    "torresl@experta.com.ar",
    "encina@experta.com.ar",
    "cantisano@experta.com.ar",
    "perezbaumann@experta.com.ar",
    "cesare@experta.com.ar",
    "marquezv@experta.com.ar",
    "scalzo@experta.com.ar",
    "sanzl@experta.com.ar",
    "limbertifiore@experta.com.ar",
    "yonamine@experta.com.ar",
    "gutmann@experta.com.ar",
    "souzad@experta.com.ar",
    "arroqui@experta.com.ar",
    "ospitale@experta.com.ar",
    "fernandezgaioli@experta.com.ar",
    "mossuto@experta.com.ar",
    "muller@experta.com.ar",
    "fulle@experta.com.ar",
    "elizondo@experta.com.ar",
    "gordillo@experta.com.ar",
    "lezcanoc@experta.com.ar",
    "perezna@experta.com.ar",
    "contrerasm@experta.com.ar",
    "castroca@experta.com.ar",
    "montiel@experta.com.ar",
    "aguilarr@experta.com.ar",
    "santorovan@experta.com.ar",
    "delmauro@experta.com.ar",
    "gallardogianni@experta.com.ar",
    "buenaventura@experta.com.ar",
    "colombof@experta.com.ar",
    "ader@experta.com.ar",
    "selesanl@experta.com.ar",
    "maiello@experta.com.ar",
    "tarquini@experta.com.ar",
    "blancof@experta.com.ar",
    "reinaldom@experta.com.ar",
    "roelg@experta.com.ar",
    "maciel@experta.com.ar",
    "paulom@experta.com.ar",
    "dellarosciae@experta.com.ar",
    "duartem@experta.com.ar",
    "giusti@experta.com.ar",
    "colombatti@experta.com.ar",
    "sandersm@experta.com.ar",
    "albertini@experta.com.ar",
    "rappenecker@experta.com.ar",
    "conserva@experta.com.ar",
    "dukardt@experta.com.ar",
    "acostama@experta.com.ar",
    "durane@experta.com.ar",
    "zsauter@experta.com.ar",
    "petracci@experta.com.ar",
    "perissinotto@experta.com.ar",
    "montenegrom@experta.com.ar",
    "larregina@experta.com.ar",
    "campins@experta.com.ar",
    "rivasma@experta.com.ar",
    "gerbers@experta.com.ar",
    "lohrmann@experta.com.ar",
    "gejena@experta.com.ar",
    "lucia.ridolfi@grupowerthein.com",
    "borgognoni@experta.com.ar",
    "guziuk@experta.com.ar",
    "luque@experta.com.ar",
    "saladal@experta.com.ar",
    "aguilarf@experta.com.ar",
    "pando@experta.com.ar",
    "pimentele@experta.com.ar",
    "ferre@experta.com.ar",
    "ruized@experta.com.ar",
    "trovato@experta.com.ar",
    "arduino@experta.com.ar",
    "munizf@experta.com.ar",
    "dedios@experta.com.ar",
    "dossantos@experta.com.ar",
    "manchinelli@experta.com.ar",
    "gallego@experta.com.ar",
    "lucero@experta.com.ar",
    "bernio@experta.com.ar",
    "carrasco@experta.com.ar",
    "dominguezb@experta.com.ar",
    "pizarroc@experta.com.ar",
    "costa@experta.com.ar",
    "basiloff@experta.com.ar",
    "mamino@experta.com.ar",
    "cardozo@experta.com.ar",
    "llop@experta.com.ar",
    "bermudez@experta.com.ar",
    "peraltaluna@experta.com.ar",
    "pboccuti@sml.com.ar",
    "madiaz@sml.com.ar",
    "aruiz@sml.com.ar",
    "lsanchez@sml.com.ar",
    "cgallo@sml.com.ar",
    "mmartinez@sml.com.ar",
    "cuelan@sml.com.ar",
    "jbriones@sml.com.ar",
    "gzittermann@sml.com.ar",
    "abenvenuto@sml.com.ar",
    "jgarreffa@sml.com.ar",
    "gvaras@sml.com.ar",
    "amarsico@sml.com.ar",
    "lzitterman@sml.com.ar",
    "erobles@sml.com.ar",
    "cpiedrabuena@sml.com.ar",
    "fcalabria@sml.com.ar",
    "mbuitron@sml.com.ar",
    "cnombela@sml.com.ar",
    "cpeluso@sml.com.ar",
    "oromero@sml.com.ar",
    "gnicoletti@sml.com.ar",
    "jdavid@sml.com.ar",
    "coradazzi@experta.com.ar",
    "vjuarez@sml.com.ar",
    "jcastello@sml.com.ar",
    "blopez@sml.com.ar",
    "gfarrapeira@sml.com.ar",
    "rmielniczuk@sml.com.ar",
    "castroalejandro@arnet.com.ar",
    "lgesso@sml.com.ar",
    "gcosentino@sml.com.ar",
    "sramos@sml.com.ar",
    "mbongiovanni@sml.com.ar",
    "mgalarza@sml.com.ar",
    "giapalucci@sml.com.ar",
    "mdagostino@sml.com.ar",
    "cquinone@sml.com.ar",
    "afreire@sml.com.ar",
    "jbarbeito@sml.com.ar",
    "fdelaoliva@sml.com.ar",
    "nfernandez@sml.com.ar",
    "mbonilla@sml.com.ar",
    "bmartinez@sml.com.ar",
    "cmartin@sml.com.ar",
    "clazaro@sml.com.ar",
    "rbullio@sml.com.ar",
    "gbonazzi@sml.com.ar",
    "ysaravia@experta.com.ar",
    "epicarelli@sml.com.ar",
    "mchprintz@sml.com.ar",
    "jsoler@sml.com.ar",
    "sbertuzzi@sml.com.ar",
    "ayslas@sml.com.ar",
    "kpark@sml.com.ar",
    "fbustamante@sml.com.ar",
    "nmoreno@sml.com.ar",
    "ccrosetti@sml.com.ar",
    "eguyleguy@yahoo.com.ar",
    "pfernandez@sml.com.ar",
    "fvillanustre@sml.com.ar",
    "mzavalia@sml.com.ar",
    "ggonzalez@sml.com.ar",
    "rgimenez@sml.com.ar",
    "iserfas@sml.com.ar",
    "sfanton@sml.com.ar",
    "ggerman@sml.com.ar",
    "pceruolo@sml.com.ar",
    "lbucci@sml.com.ar",
    "yvicente@sml.com.ar",
    "mfrench@sml.com.ar",
    "lbenitez@sml.com.ar",
    "mcasal@sml.com.ar",
    "aleguizamon@sml.com.ar",
    "ikulikowski@sml.com.ar",
    "mdomenella@sml.com.ar",
    "fnosti@sml.com.ar",
    "barredondo@sml.com.ar",
    "jblanco@sml.com.ar",
    "dmolli@sml.com.ar",
    "cgfernandez@sml.com.ar",
    "mbindella@sml.com.ar",
    "abate@experta.com.ar",
    "bcarrizo@experta.com.ar",
    "aplaceres@sml.com.ar",
    "cdirube@sml.com.ar",
    "mgatto@sml.com.ar",
    "sgalvan@sml.com.ar",
    "asilvestroni@sml.com.ar",
    "erodriguez@sml.com.ar",
    "pfigueroa@sml.com.ar",
    "njara@sml.com.ar",
    "dferrante@sml.com.ar",
    "froldan@sml.com.ar",
    "cvinote@sml.com.ar",
    "peljechin@sml.com.ar",
    "ycaceres@sml.com.ar",
    "jtraversaro@sml.com.ar",
    "gfreiria@sml.com.ar",
    "yscovenna@sml.com.ar",
    "giglesias@sml.com.ar",
    "ggastiazoro@sml.com.ar",
    "pstariha@sml.com.ar",
    "dgutierrez@sml.com.ar",
    "alopez@sml.com.ar",
    "ffernandez@sml.com.ar",
    "bfurlan@sml.com.ar",
    "aarce@sml.com.ar",
    "jmalvarez@sml.com.ar",
    "amatienzo@sml.com.ar",
    "hschirripa@sml.com.ar",
    "lmicucci@sml.com.ar",
    "dyaps@sml.com.ar",
    "dalvarez@sml.com.ar",
    "pmassola@sml.com.ar",
    "msilva@sml.com.ar",
    "acalle@sml.com.ar",
    "fpalma@sml.com.ar",
    "dsolavaggione@sml.com.ar",
    "mjuarez@sml.com.ar",
    "cblanco@sml.com.ar",
    "avalenzuela@sml.com.ar",
    "nnaya@sml.com.ar",
    "cclar@sml.com.ar",
    "epalacios@sml.com.ar",
    "cintia.galarza@sml.com.ar",
    "nkatopodis@sml.com.ar",
    "fcardozo@sml.com.ar",
    "lcillo@sml.com.ar",
    "hmolinatti@sml.com.ar",
    "yricci@sml.com.ar",
    "aolaechea@sml.com.ar",
    "cpastorini@sml.com.ar",
    "vsantacruz@sml.com.ar",
    "jgiraut@sml.com.ar",
    "mfredes@experta.com.ar",
    "lbiagioli@sml.com.ar",
    "avalle@sml.com.ar",
    "gcasali@sml.com.ar",
    "gquiero@sml.com.ar",
    "mcastel@sml.com.ar",
    "gbradley@sml.com.ar",
    "cziegler@sml.com.ar",
    "ngiordani@sml.com.ar",
    "mmarandino@sml.com.ar",
    "nblanco@jelper.com.ar",
    "dario.durand@grupowerthein.com",
    "pchifflet@sml.com.ar",
    "daniel.marcial@experta.com.ar",
    "mariano.battaglia@experta.com.ar",
    "lbermudez@experta.com.ar",
    "claudio.caparroz@experta.com.ar",
    "rovira@experta.com.ar",
    "breitenberger@experta.com.ar",
    "pablo.loberto@experta.com.ar",
    "consuelo.sztyrle@sml.com.ar",
    "marcela.maluenda@sml.com.ar",
    "gcastro@sml.com.ar",
    "tamara.areco@sml.com.ar",
    "tsciarreta@experta.com.ar",
    "gferrer@soibaires.com.ar",
    "mtassone@soibaires.com.ar",
    "muranga@soibaires.com.ar",
    "ogualdoni@soibaires.com.ar",
    "cnieto@soibaires.com.ar",
    "mortiz@soibaires.com.ar",
    "cotazua@soibaires.com.ar",
    "epaturlanne@soibaires.com.ar",
    "cdaher@soibaires.com.ar",
    "randreozzi@soibaires.com.ar",
    "rzaffaroni@soibaires.com.ar",
    "mdefelippe@soibaires.com.ar",
    "fderdoy@soibaires.com.ar",
    "lrosales@soibaires.com.ar",
    "msaadi@soibaires.com.ar",
    "mcumbaire@soibaires.com.ar",
    "fquinteros@sml.com.ar",
    "ccalvitti@experta.com.ar",
    "gfreiberg@experta.com.ar",
    "fdiaz@experta.com.ar",
    "rsilva@experta.com.ar",
    "ffabrizio@experta.com.ar",
    "lcolonel@experta.com.ar",
    "tjardas@experta.com.ar",
    "lpalopoli@experta.com.ar",
    "pbarzola@experta.com.ar",
    "nsanabria@experta.com.ar",
    "gaspar.werthein@experta.com.ar",
    "ccampero@experta.com.ar",
    "ysamar.gomez@experta.com.ar",
    "micaela.paez@experta.com.ar",
    "mariana.navas@experta.com.ar",
    "mariano.caravello@experta.com.ar",
    "mariano.caravello@experta.com.ar",
    "sgarcia@cachamai.com",
    "raltamirano@cachamai.com",
    "gspivak@cachamai.com",
    "cnazzario@cachamai.com",
    "mcarstens@cachamai.com",
    "lsalas@cachamai.com",
    "gdolimpio@cachamai.com",
    "cjmontero@cachamai.com",
    "lroverano@cachamai.com",
    "vtelleria@cachamai.com",
    "mwenzel@cachamai.com",
    "nrodriguez@cachamai.com",
    "ldossantos@cachamai.com",
    "aciorceri@cachamai.com",
    "scattaneo@cachamai.com",
    "mlapczuk@cachamai.com",
    "eponce@cachamai.com",
    "etolsa@cachamai.com",
    "mgallardo@cachamai.com",
    "psalvio@cachamai.com",
    "gvalenziano@cachamai.com",
    "msarria@cachamai.com",
    "mminakowski@cachamai.com",
    "mlazorenco@cachamai.com",
    "mjdominguez@cachamai.com",
    "ggarcia@cachamai.com",
    "federico.germino@cachamai.com",
    "eugenia.belluccini@cachamai.com",
    "gaston.llabre@cachamai.com",
    "agustin.carminatti@cachamai.com",
    "puente@wertheinagro.com",
    "sanchez@wertheinagro.com",
    "vega@wertheinagro.com",
    "marasco@wertheinagro.com",
    "scaturro@wertheinagro.com",
    "maggi@wertheinagro.com",
    "ratti@wertheinagro.com",
    "dominguez@wertheinagro.com",
    "jensen@wertheinagro.com",
    "frias@wertheinagro.com",
    "paredes@wertheinagro.com",
    "farias@wertheinagro.com",
    "gjud@wertheinagro.com",
    "rago@wertheinagro.com",
    "duek@wertheinagro.com",
    "sedelli@wertheinagro.com",
    "anzalone@wertheinagro.com",
    "beguelin@wertheinagro.com",
    "demarco@wertheinagro.com",
    "cerletti@wertheinagro.com",
    "castro@wertheinagro.com",
    "baudena@wertheinagro.com",
    "madera@wertheinagro.com",
    "lmoyano@wertheinagro.com",
    "jlizarralde@wertheinagro.com",
    "eguerrero@wertheinagro.com",
    "rmagnani@wertheinagro.com",
    "olie@wertheinagro.com",
    "quarin@wertheinagro.com",
    "cyasuhara@wertheinagro.com",
    "irigoyen@wertheinagro.com",
    "zalazar@wertheinagro.com",
    "torres@wertheinagro.com",
    "manueli@wertheinagro.com",
    "maldonado@wertheinagro.com",
    "voisard@wertheinagro.com",
    "rodriguez@wertheinagro.com",
    "gloriadios@wertheinagro.com",
    "mnapoli@wertheinagro.com",
    "catalinay@wertheinagro.com",
    "cgonzalez@wertheinagro.com",
    "villalobo@wertheinagro.com",
    "curti@wertheinagro.com",
    "guido.chighizola@carteravsa.com.ar",
    "natalia.guelman@carteravsa.com.ar",
    "ana.abramoff@grupowerthein.com",
    "gabriela.vazquez@grupowerthein.com",
    "mara@grupowerthein.com",
    "maria.polino@grupowerthein.com",
    "virginia.garrido@grupowerthein.com",
    "eduardo.navaza@grupowerthein.com",
    "pombo@experta.com.ar",
    "dmiceli@experta.com.ar",
    "marcelo.villegas@grupowerthein.com",
    "fabian.suffern@grupowerthein.com",
    "marcelo.wegbrait@grupowerthein.com",
    "ricardo.perezruiz@grupowerthein.com",
    "av@grupowerthein.com",
    "eduardo.bauer@grupowerthein.com",
    "blancogu@experta.com.ar",
    "jorge.espoueys@grupowerthein.com",
    "ricardo.ferreiro@grupowerthein.com",
    "alejandro.furst@grupowerthein.com",
    "proveedores@wimg.com.ar",
    "julio.rovegno@grupowerthein.com",
    "sabarinocarlos@gmail.com",
    "dclaudio.schwartzman@grupowerthein.com",
    "millan@experta.com.ar",
    "bachino@wertheinagro.com",
    "Gustavo.lopez@experta.com.ar",
    "bexalys@gmail.com",
    "porzio@experta.com.ar",
    "charlot@experta.com.ar",
    "ridolfi@experta.com.ar",
    "blopez@jelper.com.ar",
    "ysaravia@sml.com.ar",
    "lbucci@jelper.com.ar",
    "lbermudez@asap-consulting.com.ar",
    "ccaparroz@asap-consulting.com.ar",
    "ploberto@asap-consulting.com.ar",
    "mmluendaq@gmail.com",
    "tnareco@experta.com.ar",
    "fquinteros@jelper.com.ar",
    "gwerthein@experta.com.ar",
    "Mariano.caravello@experta.com.ar",
    "carminatti@gmail.com",
    "magnani@wertheinagro.com",
    "becerra@wertheinagro.com",
    "ana.abramoff@grupowerthien.com",
    "graciela.sisnero@segw.com.ar",
    "31010602avillalbav@tecnosoftware.com.ar",
    "lpiro@asap-consulting.net",
    "epiro@asap-consulting.net",
    "lpiro@asap-consulting.net",
    "lpiro@asap-consulting.net",
    "lpiro@asap-consulting.net",
    "lpiro@asap-consulting.net",
    "ifarreda@asap-consulting.net",
    "iferradas@asap-consulting.net",
    "rurribarri@asap-consulting.net",
    "amartinezrojas@asap-consulting.net",
    "pmendez@asap-consulting.net",
    "rarguello@asap-consulting.net",
    "dojeda@asap-consulting.net",
    "jboschi@asap-consulting.net",
    "cdejesus@asap-consulting.net",
    "sardiles@asap-consulting.net",
    "djalil@asap-consulting.net",
    "djalin@asap-consulting.net",
    "cnardone@asap-consulting.net",
    "dmiceli@experta.com.ar,",
    "fs@grupowerthein.com",
    "wegbraitm@wimg.com.ar, marcelo.wegbrait@grupowerthein.com",
    "pedroebarzaghi@gmail.com",
    "eduardofedericobauer@gmail.com",
    "Ricardo.Ferreiro@experta.com.ar",
    "julio.rovegno@carteravsa.com.ar",
]

const validateFecha = (fecha) => {
    if (fecha == '2022-07-07') {
        return true
    } else {
        return false
    }
}

export const useValidateCampAntigripal = (emailUsuario, fecha) => {
    const [campAntigripal, setCampAntigripal] = useState(false)

    const cargarUsuarios = useCallback(async () => {
        let fechaValida = await validateFecha(fecha)
        if (fechaValida && emailUsuario) {
            console.log('lower', (emailUsuario?.trim()).toLowerCase())
            setCampAntigripal(USUARIOS_PERMITIDOS.includes((emailUsuario?.trim()).toLowerCase()))
        } else {
            setCampAntigripal(false)
        }
    }, [emailUsuario, fecha])

    useEffect(() => {
        cargarUsuarios()
    }, [cargarUsuarios])

    return campAntigripal
}

export const useValidateCampAntigripalDNI = (dni, fecha) => {
    const [campAntigripal, setCampAntigripal] = useState(false)

    const cargarUsuarios = useCallback(async () => {
        let fechaValida = await validateFecha(fecha)
        if (fechaValida && dni) {
            setCampAntigripal(USUARIOS_PERMITIDOS.includes((dni?.trim())))
        } else {
            setCampAntigripal(false)
        }
    }, [dni, fecha])

    useEffect(() => {
        cargarUsuarios()
    }, [cargarUsuarios])

    return campAntigripal
}