import React, { useState, useEffect, useCallback } from 'react';
import Container from "@material-ui/core/Container"
import * as TurnosAPI from "../apis/TurnosAPI";
import * as TurnosCocherasAPI from '../apis/TurnosCocherasAPI'
import Header from "../shared/Header"
import Sidebar from "./Sidebar2"
import { FixedSizeList as List } from 'react-window';
import { useAlert } from 'react-alert';
import TodayIcon from '@material-ui/icons/Today';
import Divider from "@material-ui/core/Divider";
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Button from "@material-ui/core/Button";
import swal from '@sweetalert/with-react'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { getUser } from '../utils/auth-helper';
// import { getUserDiagnosticoValido } from '../apis/DiagnosticosAPI';
import {
  // decode as base64_decode, 
  encode as base64_encode
} from 'base-64';
import { MySpinner } from '../components/MySpinner';
import { useTranslation } from 'react-i18next';
import { useSospechoso } from '../hooks/useSospechoso';
import QRCode from 'qrcode.react';
import { useHistory } from 'react-router-dom';
import { UseDiagnosticoValido } from '../hooks/useDiagnosticoValido';
import * as Sentry from "@sentry/react";

const getParsedDate = (date) => {
  let _date = String(date).split(' ');
  var days = String(_date[0]).split('-');
  return [parseInt(days[2]) + '/' + parseInt(days[1]) + '/' + parseInt(days[0])];
}

const MisReservas = (prop) => {
  const alert = useAlert();
  const [turnosActivos, setTurnosActivos] = useState([]);
  const [turnosActivosData, setTurnosActivosData] = useState([]);
  const [turnosCocheraActivos, setTurnosCocherasActivos] = useState([])
  const [turnosHistoricos, setTurnosHistoricos] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [turnoActivo, setTurnoActivo] = useState(0);
  const sospechoso = useSospechoso();
  const diagnostivoActivo = UseDiagnosticoValido()
  const history = useHistory()
  const { t } = useTranslation();
  let company = localStorage.getItem('company')

  const getTurnosHistorico = useCallback(() => {
    TurnosAPI.getTurnosHistoricos()
      .then(response => {
        var result = [];
        for (var i in response.data)
          result.push(`${t("misReserva>text>date")}` + getParsedDate(response.data[i].FechaTurno.substr(0, 10)) + ` ${t("misReserva>text>site")}` + response.data[i].Edificio + " " + response.data[i].Piso + `, ${t("misReserva>text>schedule")}` + response.data[i].Horario);
        setLoading(false);
        setTurnosHistoricos(result.reverse());
      })
      .catch(function (error) {
        if (!error.response)
          alert.show("" + error);
        else
          alert.show("" + error.response.data.error);
      });
  }, [setTurnosHistoricos, alert, t])

  //TODO: mover a hooks
  const getTurnosActivos = useCallback(() => {
    TurnosAPI.getTurnos()
      .then(response => {
        var result = [];
        for (var i in response.data) {
          result.push(`${t("misReserva>text>date")}` + getParsedDate(response.data[i].FechaTurno.substr(0, 10)) + ` ${t("misReserva>text>site")}` + response.data[i].Edificio + " " + response.data[i].Piso + `, ${t("misReserva>text>schedule")}` + response.data[i].Horario);
        }
        setLoading(false);
        setTurnosActivos(result);
        setTurnosActivosData(response.data);
      })
      .catch(function (error) {
        if (!error.response)
          alert.show("" + error);
        else
          alert.show("" + error.response.data.error);
      });
  }, [alert, t])

  //TODO: mover a hooks
  const getTurnosCocherasActivos = useCallback(() => {
    TurnosCocherasAPI
      .getTurnosCochera()
      .then(response => {
        var result = [];
        for (var i in response.data) {
          result.push(`${t("misReserva>text>date")} ` + getParsedDate(response.data[i].FechaTurno.substr(0, 10)) + ` ${t("misReserva>text>site")} ` + response.data[i].Edificio + ` ${"(cochera)"} `);
        }
        setLoading(false);
        setTurnosCocherasActivos(result);
      })
      .catch(function (error) {
        if (!error.response)
          alert.show("" + error);
        else
          alert.show("" + error.response.data.error);
      });
  }, [alert, t])

  //TODO: mover a hooks
  useEffect(() => {
    setLoading(true);
    getTurnosActivos();
    getTurnosCocherasActivos();
    getTurnosHistorico();
  }, [getTurnosActivos, getTurnosCocherasActivos, getTurnosHistorico]);

  const clickQR = () => {
    if (sospechoso) {
      return swal(<div><h1>Alerta de diagnóstico sospechoso</h1></div>)
    }
    swal(
      <div>
        <h1>{t("misReserva>title>qrGenerated")}</h1>
        <br />
        <h3>{getUser().Nombre}</h3>
        <br />
        <p>
          <QRCode value={"" + base64_encode("" + base64_encode(getUser().userId))} />
        </p>
      </div>
    )

  };

  //TODO: mover a hooks
  const borrarTurno = useCallback((turnoId) => {
    TurnosAPI.deleteTurno(turnoId)
      .then(response => {
        alert.show(`${t("misReserva>delete>turnDeleted")}`);
        setLoading(true);
        getTurnosActivos();
      })
      .catch(function (error) {
        if (!error.response)
          alert.show("" + error);
        else
          alert.show("" + error.response.data.error);
      });
  }, [alert, t, getTurnosActivos])


  //TODO: mover a hooks
  const clickDelete = useCallback((index) => {
    swal(
      <p>Estas seguro de eliminar el turno para {turnosActivosData[index].Edificio} con fecha {turnosActivosData[index].FechaTurno.substr(0, 10)}?</p>,
      {
        icon: "warning",
        buttons: {
          yes: {
            text: t("misReserva>text>yes"),
            value: "si"
          },
          no: {
            text: t("misReserva>text>no"),
            value: "no"
          }
        }
      }).then((value) => {
        if (value === "si") {
          borrarTurno(turnosActivosData[index].TurnoId);
        }
        return false;
      });
  }, [borrarTurno, t, turnosActivosData])

  //TODO: mover a componente externo
  const RowA = ({ index, style }) => (
    //turnosActivosData[index].ConEstacionamiento
    <div style={{ fontWeight: 'bold', textAlign: "center", borderColor: 'black', borderWidth: 1 }}>
      <Button>
        <TodayIcon style={{ color: "rgb(34, 87, 138)", height: '20px', width: '20px', marginRight: '10px' }} />
        {turnosActivosData[index] && turnosActivosData[index].ConEstacionamiento == true && <DriveEtaIcon style={{ color: "rgb(34, 87, 138)", height: '20px', width: '20px', marginRight: '10px' }} />}
        {turnosActivos[index]}
        <DeleteForeverIcon onClick={() => { clickDelete(index) }} style={{ color: "rgb(100, 5, 5)", height: '20px', width: '20px', marginLeft: '4px' }} />
      </Button>
      <Divider />
    </div>

  );
  //TODO: mover a componente externo
  const RowH = ({ index, style }) => (
    <div style={{ fontWeight: 'bold', textAlign: "center", borderColor: 'black', borderWidth: 1 }}>
      <Button >
        <TodayIcon style={{ color: "rgb(134, 87, 80)", height: '20px', width: '20px', marginRight: '10px' }} />
        {turnosHistoricos[index]}
      </Button>
      <Divider />
    </div>
  );
  //TODO: mover a componente externo
  const RowC = ({ index, style }) => (
    <div style={{ fontWeight: 'bold', textAlign: "left", borderColor: 'black', borderWidth: 1 }}>
      <Button style={{ width: '100%', textAlign: 'left !important' }}>
        <TodayIcon style={{ color: "rgb(34, 87, 138)", height: '20px', width: '20px', marginRight: '10px' }} />
        {turnosCocheraActivos[index]}
        {/* <DeleteForeverIcon onClick={() => { clickDelete(index) }} style={{ color: "rgb(100, 5, 5)", height: '20px', width: '20px', marginLeft: '4px' }} /> */}
      </Button>
      <Divider />
    </div>
  );

  //TODO: mover a componente externo
  const ListaTurnosActivos = () => (
    <List className="reservasActivas" style={{ fontWeight: 'bold', textAlign: "center", borderColor: 'black', borderWidth: 1 }}
      height={90 * turnosActivos.length}
      width={550}
      itemSize={35}
      itemCount={turnosActivos.length} >
      {RowA}
    </List>
  );
  //TODO: mover a componente externo
  const ListaTurnosCocheraActivos = () => (
    <List className="reservasActivas" style={{ textAlign: "center", borderColor: 'black', borderWidth: 1 }}
      height={90 * turnosCocheraActivos.length}
      width={550}
      itemSize={35}
      itemCount={turnosCocheraActivos.length} >
      {RowC}
    </List>
  );
  //TODO: mover a componente externo
  const ListaTurnosHistoricos = () => (
    <List className="reservasHistoricas" style={{ fontWeight: 'bold', textAlign: "center" }}
      height={70 * turnosHistoricos.length}
      width={550}
      itemSize={35}
      itemCount={turnosHistoricos.length}>
      {RowH}
    </List>
  );

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <div>
        <Header />
        <Sidebar />

        {loading ? (
          <MySpinner />
        ) : (
          <div>
            <Container maxWidth="sm">

              <br />
              <h1 className='ExpertaText' style={{ marginTop: 0, marginBottom: 20 }}>{t("misReserva>title>reservations")}</h1>

              {!diagnostivoActivo ?
                <p style={{ color: 'red', fontSize: 20, marginBottom: 40 }}>{t("misReserva>text>diagVencido")}</p>
                : sospechoso ?
                  <p style={{ color: 'red', fontSize: 20, marginBottom: 40 }}>{t("misReserva>text>sospechoso")}</p>
                  :
                  <>
                    {company == 'directv' &&
                      <>
                        <Button
                          style={{ alignSelf: "center", textTransform: "none", textAlign: "center", backgroundColor: "#2f4050", color: "white" }}
                          variant="contained"
                          type='submit' onClick={clickQR}>{t("misReserva>button>qrIncome")}
                        </Button>
                        <p style={{ margin: 20 }}>o</p>
                      </>
                    }
                    <Button
                      style={{ alignSelf: "center", textTransform: "none", textAlign: "center", backgroundColor: "#2f4050", color: "white" }}
                      variant="contained"
                      type='submit' onClick={() => history.push('/LectorQROficina')}>
                      {"Escanear QR"}
                    </Button>
                    <p style={{ margin: 20 }}>*Recordá revisar la configuración en tu dispositivo para asegurarte de que el Acceso a la cámara está activado</p>
                  </>
              }

              {/* <Button
                style={{ alignSelf: "center", textTransform: "none", textAlign: "center", backgroundColor: "#2f4050", color: "white" }}
                variant="contained"
                type='submit' onClick={() => history.push('/LectorQRUsuario')}>
                {t("misReserva>button>qrScan")}
              </Button> */}

              <p className='HomeDescr' style={{ marginTop: 25 }}>{t("misReserva>paragraph>activeReserver")}</p>
              <ListaTurnosActivos />
              <ListaTurnosCocheraActivos />
              <p className='HomeDescr' style={{ marginTop: 10, marginBottom: 25 }}>{t("misReserva>paragraph>historialReserver")}</p>
              <ListaTurnosHistoricos />
              <br />

            </Container>
          </div>)}
      </div>
    </Sentry.ErrorBoundary>
  )
}

export default MisReservas;