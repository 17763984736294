import { useCallback, useEffect, useState } from 'react'
import { getUsuariosVacunados, getUsuariosNoVacunados } from '../apis/VacunasAPI'

const parser = (data) => {
    return data.map(d => {
        return {
            ...d,
            Vacunado: d.Vacunado.data[0] ? 'SI' : 'NO',
            DosDosis: d.DosDosis.data[0] ? 'SI' : 'NO',
        }
    })
}


export const useRegistroVacunacion = () => {
    const [vacunados, setVacunados] = useState([])
    const [noVacunados, setNoVacunados] = useState([])

    const cargarVacunados = useCallback(() => {
        getUsuariosVacunados()
            .then(res => {
                if (res?.data) {
                    setVacunados(parser(res?.data));
                }
            })
    }, [])

    const cargarNoVacunados = useCallback(() => {
        getUsuariosNoVacunados()
            .then(res => {
                if (res?.data) {
                    setNoVacunados(parser(res?.data));
                }
            })
    }, [])

    useEffect(() => {
        cargarVacunados();
        cargarNoVacunados();
    }, [cargarVacunados, cargarNoVacunados]);

    
    return { vacunados, noVacunados }
}
