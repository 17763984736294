import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Header from '../shared/Header'
import { useFormik } from "formik";
import { FormControl, TextField, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import { Link, Redirect } from "react-router-dom";
import { setToken, setUser, getToken } from "../utils/auth-helper";
import * as Auth from "../apis/AuthAPI";
import { useAlert } from 'react-alert';
// import logoTmp from '../img/DIABANDERA.png';
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { HelpOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  textField: {
    marginTop: theme.spacing(6),
  },
  btn: {
    alignSelf: "center",
    textTransform: "none",
    textAlign: 'center',
    backgroundColor: "#2f4050",
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: ({ hoverBackgroundColor, backgroundColor }) =>
        hoverBackgroundColor
          ? hoverBackgroundColor
          : '#4c4e68'
    }
  },
  btnVrio: {
    alignSelf: "center",
    textTransform: "none",
    textAlign: 'center',
    backgroundColor: "#006898",
    color: "white",
    width: "100%"
  },
  btnInvitado: {
    alignSelf: "center",
    textTransform: "none",
    textAlign: 'center',
    backgroundColor: "#6b6b6b",
    color: "white",
    width: "100%",
    marginTop: 5
  },
  btnHelp: {
    alignSelf: "center",
    textTransform: "none",
    textAlign: 'center',
    backgroundColor: "#6b6b6b",
    color: "white",
    width: "100%",
    marginTop: 5
  },
}));
const company = (company) => {
  localStorage.setItem("company", company)
}

const validation = Yup.object().shape({
  dni: Yup.string()
    .min(3, "El dni debe contener 3 caracteres o más")
    .required("requerido"),
  password: Yup.string().required("requerido"),
});

const Login = () => {
  const alert = useAlert();
  const [cargando, setCargando] = useState(false)
  const [logged, setLogged] = useState(false)
  // const [empresa, setEmpresa] = useState('')
  const classes = useStyles();
  const { t } = useTranslation()

  useEffect(() => {
    if (getToken()) {
      setLogged(true);
    }
  }, []);

  const LogIn = (dni, password) => {
    setCargando(true);

    Auth.loginUser(dni, password)
      .then(response => {
        setToken(response.data.token);
        setUser(response.data);
        setCargando(false);
        //alert.show("Bienvenido " +  getUser().Nombre + process.env.REACT_APP_API_URL == "https://safedesk.werthein-tech.com/" ? "TEST" : "");
        setLogged(true);
      })
      .catch(function (error) {

        if (error.response == undefined)
          alert.show("" + error);
        else
          alert.show("" + error.response.data.error);

        setCargando(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      dni: "",
      password: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const { dni, password } = values;
      LogIn(dni, password);
    },
  });

  const handleVrio = () => {
    company("directv");
    Axios({
      method: 'GET',
      url: 'https://safedesk.werthein-tech.com/api/auth/whoami', //TODO: poner en .env
      withCredentials: true
    })
      .then(response => {
        if (response.data.user.email) {
          alert('is logged!')
        } else {
          RedirectToLogin()
        }
      })
      .catch(error => {
        RedirectToLogin()
      })
  }

  const RedirectToLogin = () => {
    // window.location.replace('http://localhost:3000/api/auth/login') //TODO: poner en .env
    window.location.replace('https://safedesk.werthein-tech.com/api/auth/login') //TODO: poner en .env
  }

  return (
    <div>
      <Header />
      <div className="loginBox">
        <Container maxWidth="sm">

          <h1 className='ExpertaText' style={{ fontSize: "50px" }}>SAFE DESK {process.env.REACT_APP_API_URL == "https://safedesk-dev.werthein-tech.com/" ? "TEST" : ""}</h1>
          <p>{t('login>paragraph>document')}</p>
          <form
            onSubmit={formik.handleSubmit}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <FormControl className={classes.formControl}>
              <InputLabel>Usuario</InputLabel>
              <TextField
                className={classes.textField}
                onChange={formik.handleChange}
                style={{ marginBottom: "2%" }}
                placeholder="Ingresá tu DNI o Código edificio..."
                //disabled={true}
                id="dni"
                name="dni"
                variant="outlined"
                helperText={formik.errors.dni}
                error={formik.errors.dni}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel>{t('login>label>password')}</InputLabel>
              <TextField
                onChange={formik.handleChange}
                className={classes.textField}
                style={{ marginBottom: "2%" }}
                placeholder="Ingresá la contraseña"
                type="password"
                //disabled={true}
                id="password"
                name="password"
                variant="outlined"
                helperText={formik.errors.password}
                error={formik.errors.password}
              />
            </FormControl>
            <FormControl>
              <Button
                className={classes.btn}
                onClick={()=>company("experta")}
                variant="contained"
                disableElevation
                size="large"
                type='submit'>{t('login>button>into')}</Button>
            </FormControl>

            <h4 style={{ alignSelf: 'center', margin: '10px 0' }}>o</h4>
            <FormControl>
              <Button
                className={classes.btnVrio}
                variant="contained"
                size="large"
                onClick={handleVrio}
              >{t('login>title>loginVrio')}</Button>
            </FormControl>
            <FormControl>
              <Link to='/Invitado' style={{ textDecoration: 'none' }}>
                <Button
                  className={classes.btnInvitado}
                  variant="contained"
                  size="large"
                  onClick={() => { }}
                >{t('login>button>loginGuest')}</Button>
              </Link>
              <Link to='/Help' style={{ textDecoration: 'none' ,display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      marginTop: 5}}
                      variant="contained"
                      size="large"><HelpOutline  fontSize="small"/>Ayuda
              </Link>
            </FormControl>

          </form>
          

        </Container>
        {logged ? <Redirect to="/Home" /> : null}

      </div>
    </div>
  );
};
export default Login;