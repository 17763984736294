import { useState, useEffect } from 'react'
import { getUsuariosDependientes } from '../apis/UsuariosAPI'

export const useUsuariosDependientes = () => {
    const [usuarios, setUsuarios] = useState([])
    const [loadingUsuarios, setLoadingUsuarios] = useState(false)

    const cargarUsuarios = () => {
        setLoadingUsuarios(true)
        getUsuariosDependientes()
            .then((res) => {
                setUsuarios(res.data)
                setLoadingUsuarios(false)
            })
            .catch(err => {
                console.error(err.message)
                setLoadingUsuarios(false)
            })
    }

    useEffect(() => {
        cargarUsuarios()
    }, [])



    return { usuarios, loadingUsuarios }
}
