import React, { useEffect, useState } from "react";
import ReactLoading from 'react-loading';
import {
  Container,
  Button,
  Modal
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import swal from "sweetalert2";

import Header from '../../shared/Header';
import Sidebar from '../Sidebar2';
import { MyTable } from "../../components/MyTable";
import { BtnBack } from "../../components/BtnBack";
import { agregarVacuna, getVacunas, deleteVacuna, editarVacuna } from "../../apis/VacunasAPI";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { SWAL_ERROR, SWAL_SUCCESS, SWAL_SUCCESS_EDIT } from '../../components/SwalMessages';
import { MyTextInput } from '../../components'
import { validationVacuna } from "./validations";
import { useAbmStyles } from "./styles";
import { usePermisosUsuario } from "../../hooks/usePermisosUsuario";
import { useTranslation } from "react-i18next";


const NuevaVacuna = ({ onClose, editar, selectedVac }) => {
  const { t } = useTranslation();
  const classes = useAbmStyles();

  function submit(data) {
    agregarVacuna(data)
      .then(res => {
        onClose()
        res?.data?.status === 400 ? SWAL_ERROR() : SWAL_SUCCESS();
      })
  }

  function guardarCambios(data) {
    editarVacuna(data)
      .then(res => {
        onClose()
        res?.data?.status === 400 ? SWAL_ERROR() : SWAL_SUCCESS_EDIT();
      })
  }

  return (
    <div align='center' style={{ paddingTop: 50 }}>
      <h1>{editar ? 'Editar' : 'Crear'} {t("vacunas>title>vaccines")}</h1>
      <Formik
        initialValues={editar ? selectedVac : {
          Nombre: ''
        }}
        onSubmit={(values) => editar ? guardarCambios(values) : submit(values)}
        validationSchema={validationVacuna}>

        {(formik) => (
          <Form>

            <MyTextInput
              label="Nombre Vacuna"
              name="Nombre"
              placeholder="ingresar un nombre..."
              type='string'
            />

            <button type="submit" className={classes.btn} >
              {editar ? 'Guardar cambios' : 'Confirmar'}
            </button>

          </Form>
        )
        }
      </Formik>
    </div>
  )
}

const Vacunas = (props) => {
  const { t } = useTranslation();
  usePermisosUsuario([4])
  const [loading, setLoading] = useState(true)
  const classes = useAbmStyles();
  const [vacunas, setVacunas] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [editar, setEditar] = useState(false)
  const [selectedVac, setSelectedVac] = useState()

  useEffect(() => {
    setLoading(true);
    cargarVacunas();
  }, []);

  function cargarVacunas() {
    getVacunas()
      .then(res => {
        if (res?.data) {
          setVacunas(res?.data);
        }
      })
    setLoading(false);
  }

  const handleRefresh = () => {
    setModalOpen(false)
    setEditar(false);
    setSelectedVac()
    cargarVacunas()
  }

  const handleDelete = (vacuna) => {
    swal.fire({
      title: '¿Estás seguro que deseas eliminar?',
      text: "No podrás revertir los cambios",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteVacuna(vacuna.Id)
          .then(res => cargarVacunas())
      }
    })
  }

  const handleEdit = (vacuna) => {
    setEditar(true);
    setSelectedVac(vacuna)
    setModalOpen(true)
  }

  const handleNuevaVac = () => {
    setModalOpen(true)
    setEditar(false)
    setSelectedVac()
  }

  return (
    <div>
      <Header />
      <Sidebar />
      {loading ? (
        <Container maxWidth="sm">
          <ReactLoading type={"spin"} color={"#fff"} height={'50px'} width={'50px'} />
        </Container>
      ) : (
        <div>
          <Container maxWidth="sm">


            <h1 className='ExpertaText'>{t("vacunas>title>vaccines")}</h1>

            <BtnBack to='/Abms' />


            <Button
              onClick={handleNuevaVac}
              component="span"
              startIcon={<AddCircleIcon />}
              variant="contained"
              className={classes.btn}
            >Nueva vacuna</Button>

            <div className={classes.root}>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >

                <MyTable
                  data={vacunas}
                  headers={[`${t("vacunas>headers>id")}`, `${t("vacunas>headers>name")}`]}
                  fields={['Id', 'Nombre']}
                  borrar
                  handleDelete={handleDelete}
                  edit
                  handleEdit={handleEdit}
                />

              </div>

            </div>

          </Container>

          <Modal open={modalOpen}>
            <div className={classes.paper}>
              <Button className={classes.btnCancel} onClick={() => setModalOpen(false)}>x</Button>
              <div align='center' style={{ paddingTop: 50 }}>
                <NuevaVacuna
                  onClose={handleRefresh}
                  editar={editar}
                  selectedVac={selectedVac}
                />
              </div>
            </div>
          </Modal>

        </div>)}
    </div>
  );
}

export default Vacunas;