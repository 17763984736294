import { Fab, Input } from '@material-ui/core';
import React from 'react';
import AddIcon from "@material-ui/icons/Add";
import { PropTypes } from 'prop-types';
export const UploadFile = ({ onClick = () => { }, title,accept, ...props }) => {


    return(
        <>
        <label htmlFor="upload-photo">
        <Input type="file"
            accept={accept}
            id="upload-photo"
            name="upload-photo"
            style={{ display: "none"}}
            />
            <p id='demo'></p>
            <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
                style={{ alignSelf: "center",
                textTransform: "none",
                textAlign: 'center',
                backgroundColor: "#2f4050",
                color: "white",
                justifyContent: "center",
                margin: 4,
                paddingLeft: 0,
                paddingRight: 25 }}
                >
                <AddIcon /> {title}
            </Fab>
        </label>
        </>
    )

}

UploadFile.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    accept: PropTypes.string,
}

