import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { setToken, setUser, getToken } from "../utils/auth-helper";
import { useAlert } from 'react-alert';
import Axios from "axios";
import { useHistory } from "react-router";


const Sso = () => {
  const alert = useAlert();
  const [logged, setLogged] = useState(false)
  let history = useHistory()

  useEffect(() => {
    if (getToken()) {
      setLogged(true);
    }
  }, []);

  useEffect(() => {
    handleSSO(true);
  }, []);

  const handleSSO = () => {
    Axios({
      method: 'GET', //TODO: poner en .env
      url: 'https://safedesk.werthein-tech.com/api/auth/whoami', // 'http://localhost:3000/api/auth/whoami',
      withCredentials: true,
    })
      .then(response => {
        if (response.status === 200) {
          setToken(response.data.token);
          setUser(response.data);
          setLogged(true);
          history.push('/Home')
        }
      })
      .catch(error => {
        if (error.response == undefined)
          alert.show("" + error);
        else
          alert.show("" + error.response.data.error);
      })
  }


  return (
    <div>
      {logged ? <Redirect to="/Home" /> : null}
    </div>
  );
};
export default Sso;