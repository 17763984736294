import { http } from "./api";

const endpointBase = 'api/diagnosticos/';


export const getUserDiagnosticoValido = async (values) => {
    const url = endpointBase + 'valido'
    return http.get(url);
}

export const getUserDiagnosticoValidoSemanal = async (values) => {
    const url = endpointBase + 'valido/semanal'
    return http.get(url);
}

export const getUserDiagnosticoValidoById = async (id) => {
    const url = endpointBase + '/covidById/'+id
    return http.get(url);
}


export const getVacunas = async (values) => {
    const url = endpointBase + 'vacunas'
    return http.get(url);
}


export const getVacunasOperador = async (values) => {
    const url = endpointBase + 'vacuna/operador'
    return http.get(url);
}

export const getUserDiagnosticoCovid = async (values) => {
    const url = endpointBase + 'covid'
    return http.get(url);
}

export const getListadoDiagnosticosPositivos = async (lastDays) => {
    const url = endpointBase + `covid/${lastDays}`
    return http.get(url);
} 

export const saveDiagnostico = async (temp, perdioGusto, contacto, estoyEmbarazada,
    cancer, diabetes, hepatica, perdioOlfato,
    dolorGarganta, dificultadRespiratoria, Vacunado, VacunaId, DosDosis, MeQuieroVacunar) => {
    const url = endpointBase
    let data = {
        "Temperatura": temp, "PerdioGusto": perdioGusto, "ContactoCercano": contacto,
        "EstoyEmbarazada": estoyEmbarazada, "Cancer": cancer, "Diabetes": diabetes,
        "Hepatica": hepatica, "PerdioOlfato": perdioOlfato, "DolorGarganta": dolorGarganta, "DificultadRespiratoria": dificultadRespiratoria,
        "Vacunado": Vacunado, "DosDosis": DosDosis, "MeQuieroVacunar": MeQuieroVacunar, "VacunaId": VacunaId
    }

    return http.post(url, data);
}

export const saveInvitado = async (temp, perdioGusto, contacto, estoyEmbarazada,
    cancer, diabetes, hepatica, perdioOlfato,
    dolorGarganta, dificultadRespiratoria, Vacunado, VacunaId, DosDosis, MeQuieroVacunar, apellido, email, empresa) => {
    let data = {
        "Temperatura": temp,
        "PerdioGusto": perdioGusto,
        "ContactoCercano": contacto,
        "EstoyEmbarazada": estoyEmbarazada,
        "Cancer": cancer,
        "Diabetes": diabetes,
        "Hepatica": hepatica,
        "PerdioOlfato": perdioOlfato,
        "DolorGarganta": dolorGarganta,
        "DificultadRespiratoria": dificultadRespiratoria,
        "Vacunado": Vacunado,
        "DosDosis": DosDosis,
        "MeQuieroVacunar": MeQuieroVacunar,
        "VacunaId": VacunaId,
        "Apellido": apellido,
        "Email": email,
        "Empresa": empresa
        
    }

    return http.post('api/invitados/', data);
}
