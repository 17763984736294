import { v4 as uuid } from 'uuid';

export default [{
  id: uuid(),
  country: 'Ocupacion',
  value: 123
}, {
  id: uuid(),
  country: 'Puestos desocupados',
  value: 225
}];
