import { useCallback, useEffect, useState } from 'react'
import { getAllEdificios } from '../apis/EdificiosAPI'
import { parseAllEdificios } from '../utils/formatHelper'


export const useAllEdificios = () => {
    const [loading, setLoading] = useState(true)
    const [edificios, setEdificios] = useState([])
  
    const cargarEdificios = useCallback(() => {
      getAllEdificios()
        .then(res => {
          if (res?.data) {
            const formatAllEdificios = parseAllEdificios(res?.data) || []
            setEdificios(formatAllEdificios);
          }
        })
      setLoading(false);
    },[])

    useEffect(() => {
      setLoading(true);
      cargarEdificios();
    }, [cargarEdificios]);

    return { edificios, loading }
}
