import { http } from "./api";

const endpointBase = 'api/edificios/';

export const postEdificio = async (data) => {
    return http.post(endpointBase, { data } );
}

export const getAllEdificios = async (data) => {
    return http.get(endpointBase);
}

export const getEdificio = async (id) => {
    return http.get(endpointBase+id);
}

export const editarEdificio = async (data) => {
    return http.put(endpointBase, { data } );
}

