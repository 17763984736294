import { useState, useEffect } from 'react'
import { 
    getUserDiagnosticoCovid,
    // getUserDiagnosticoValido
 } from '../apis/DiagnosticosAPI'

export const useSospechoso = () => {
    const [sospechoso, setSospechoso] = useState(false)

    useEffect(() => {
        const cargar = () => {
            // getUserDiagnosticoCovid()
            //      .then(resp => setSospechoso(resp?.data))
         }
        cargar()
    }, [])

    
    return sospechoso
}