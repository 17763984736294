import { http } from "./api";

const endpointBase = 'api/usuarios/';

export const getUsuarios = (values) => {
    const url = endpointBase
    return http.get(url);
}

export const getUsuariosDependientes = (values) => {
    const url = endpointBase + 'dependientes'
    return http.get(url);
}

export const getGerencias = (values) => {
    const url = endpointBase + 'gerencias'
    return http.get(url);
}

export const changePassword = (dni, passOld, passNew) => {
    const url = endpointBase + 'password'
    var data = { "dni": dni, "oldPassword": passOld, "newPassword": passNew }
    return http.put(url, data);
}

export const getTiposDeUsuarios = (values) => {
    const url = endpointBase + 'tipos'
    return http.get(url);
}

export const postUsuario = (data) => {
    const url = endpointBase
    return http.post(url, { data });
}

export const editUsuario = (data) => {
    const url = endpointBase
    return  http.put(url, { data });
}

export const agregarNuevoTipo = async (data) => {
    const url = endpointBase + 'tipos'
    return  http.post(url, { data });
}

export const deleteUsuario = async (id) => {
    const url = endpointBase + 'desactivar';
    return  http.post(url, { id });
}

export const desactivarUsuario = async (id) => {
    const url = endpointBase + 'desactivar';
    return  http.post(url, { id });
}

export const activarUsuario = async (id) => {
    const url = endpointBase + 'activar';
    return  http.post(url, { id });
}

export const deleteTipoUsuario = async (id) => {
    const url = endpointBase + 'tipos/' + id;
    return  http.delete(url);
}
