import React, { useEffect, useState } from "react";
import ReactLoading from 'react-loading';
import {
  Container,
  Button,
  Modal
} from '@material-ui/core';
import swal from "sweetalert2";

import Header from '../../shared/Header';
import Sidebar from '../Sidebar2';
import { MyTable } from "../../components/MyTable";
import { BtnBack } from "../../components/BtnBack";
import { getTiposDeUsuarios, agregarNuevoTipo, deleteTipoUsuario } from '../../apis/UsuariosAPI';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { SWAL_ERROR, SWAL_SUCCESS } from '../../components/SwalMessages';
import { Formik, Form } from 'formik';
import { MyTextInput } from '../../components'
import { validationTipoDeUsuario } from "./validations";
import { useAbmStyles } from "./styles";
import { usePermisosUsuario } from "../../hooks/usePermisosUsuario";
import { useTranslation } from "react-i18next";

const NuevoTipo = ({ onClose }) => {
  const { t } = useTranslation();
  const classes = useAbmStyles();

  function submit(data) {
    agregarNuevoTipo(data)
      .then(res => {
        res?.data?.status === 400 ? SWAL_ERROR() : SWAL_SUCCESS()
      })    
    onClose()
  }

  return (
    <div align='center' style={{ paddingTop: 50 }}>
      <Formik
        initialValues={{
          Descripcion: ''
        }}
        onSubmit={(values) => {
          submit(values)
        }}
        validationSchema={validationTipoDeUsuario}>

        {(formik) => (
          <Form>

            <MyTextInput
              label={t("tiposDeUsuario>label>description")}
              name="Descripcion"
              placeholder={t("tiposDeUsuario>placeholder>enterDescription")}
              type='string'
            />

            <button type="submit" className={classes.btn} >{t("tiposDeUsuario>button>confirm")}</button>

          </Form>
        )
        }
      </Formik>
    </div>
  )
}

const TiposDeUsuarios = (props) => {
  const { t } = useTranslation();
  usePermisosUsuario([4])
  const [loading, setLoading] = useState(true)
  const classes = useAbmStyles();
  const [tiposdeU, setTiposdeU] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    setLoading(true);
    cargarTipos();
  }, []);

  async function cargarTipos() {
    getTiposDeUsuarios()
      .then(res => res?.data && setTiposdeU(res?.data))
    setLoading(false);
  }

  const handleRefresh = () => {
    setModalOpen(false)
    cargarTipos()
  }

  const handleDelete = (tipo) => {
    swal.fire({
      title: `${t("tiposDeUsuario>title>delete")}`,
      text: `${t("tiposDeUsuario>text>reverse")}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${t("tiposDeUsuario>button>yes")}`
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTipoUsuario(tipo.Id)
        .then(res => cargarTipos())
      }
    })
  }

  return (
    <div>
      <Header />
      <Sidebar />
      {loading ? (
        <Container maxWidth="sm">
          <ReactLoading type={"spin"} color={"#fff"} height={'50px'} width={'50px'} />
        </Container>
      ) : (
        <div>
          <Container maxWidth="sm">
            
            <h1 className='ExpertaText'>{t("tiposDeUsuario>title>typesUser")}</h1>

            <BtnBack to='/Abms' />


            <Button
              onClick={() => setModalOpen(true)}
              component="span"
              startIcon={<AddCircleIcon />}
              variant="contained"
              className={classes.btn}
            >{t("tiposDeUsuario>button>kind")}</Button>

            <div className={classes.root}>


              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >

                <MyTable
                  data={tiposdeU}
                  // edit
                  headers={['Id', `${t("tiposDeUsuario>header>description")}`]}
                  fields={['Id', 'Descripcion']}
                  borrar
                  handleDelete={handleDelete}
                />

              </div>

            </div>

          </Container>

          <Modal open={modalOpen}>
            <div className={classes.paper}>
              <Button className={classes.btnCancel} onClick={() => setModalOpen(false)}>x</Button>
              <h1>{t("tiposDeUsuario>title>newType")}</h1>
              <div align='center' style={{ paddingTop: 50 }}>
                <NuevoTipo onClose={handleRefresh} />
              </div>
            </div>
          </Modal>

        </div>)}
    </div>
  );
}

export default TiposDeUsuarios;