import { makeStyles } from '@material-ui/core';

export const useAbmStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    table: {
        tableLayout: 'fixed'
    },
    btnCancel: {
        float: 'right'
    },
    paper: {
        position: 'absolute',
        width: '100vw',
        maxWidth: 1200,
        height: '80%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
      },
    btn: { 
        marginBottom: 30, 
        minWidth: 200,
        alignSelf: "center", 
        textTransform: "none", 
        textAlign: "center", 
        backgroundColor: "#2f4050", 
        color: "white" ,
        "&:hover": {
            backgroundColor: ({ hoverBackgroundColor, backgroundColor }) =>
              hoverBackgroundColor
                ? hoverBackgroundColor
                : '#4c4e68'
          }
    },
    btnAbm: { 
        marginTop: 10, 
        minWidth: 200, 
        alignSelf: "center", 
        textTransform: "none", 
        textAlign: "center", 
        backgroundColor: "#2f4050", 
        color: "white",
        "&:hover": {
            backgroundColor: ({ hoverBackgroundColor, backgroundColor }) =>
              hoverBackgroundColor
                ? hoverBackgroundColor
                : '#4c4e68'
          }
     }
}));