import React from "react";
import '../utils/App.css';
import Header from '../shared/Header'
import { Container, Modal, Button, Box, Typography } from "@material-ui/core";
// import { useTranslation } from "react-i18next";
import { HelpOutline, MailOutline, Phone } from "@material-ui/icons";
const boxModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  border: '2px solid #6b6b6b',
  boxShadow: 24,
  p: 4,
};
const btn = {
  alignSelf: "center",
  textTransform: "none",
  textAlign: 'center',
  backgroundColor: "#6b6b6b",
  color: "white",
  width: "100%",
  marginTop: 5
}

const h1Text = {
  fontSize: "50px", marginTop: "10%", marginBottom: '5%', color: "rgb(63, 80, 97)"
}

const Tel = ({ num, title }) => {
  return (
    <li>
      <h4>
        <a href={`tel:${num}`} style={{ textDecoration: 'none', color: 'black' }}>{title}</a>
      </h4>
    </li>
  )
}

const Help = (props) => {
  // const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openVrio, setOpenVrio] = React.useState(false);
  const handleOpenVrio = () => setOpenVrio(true);
  const handleCloseVrio = () => setOpenVrio(false);

  return (
    <div className="App">
      <Header />
      <Container maxWidth="sm">
        <h1 style={h1Text}>SAFE DESK </h1>
        <div>
          <Button
            startIcon={<HelpOutline />}
            onClick={handleOpen} style={btn}>Ayuda Experta</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={boxModal}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Ayuda <b>Experta</b>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <h3>¿Dudas? ¿Consultas? </h3>
                <h3>Contacta a Service Desk:</h3>
                <ul style={{ marginTop: '5%', marginLeft: '5%' }}>
                  <li><h4><MailOutline style={{ marginBottom: '-6px' }} /> <a href="mailto:safedesk@werthein-tech.com" style={{ textDecoration: 'none', color: 'black' }}>safedesk@werthein-tech.com</a></h4></li>
                </ul>
              </Typography>
            </Box>
          </Modal>
          <Button
            startIcon={<HelpOutline />}
            style={btn} onClick={handleOpenVrio}>Ayuda Vrio</Button>
          <Modal
            open={openVrio}
            onClose={handleCloseVrio}
            aria-labelledby="modal-modal-Vrio"
            aria-describedby="modal-modal-Vrio"
          >
            <Box sx={boxModal}>
              <Typography id="modal-modal-Vrio" variant="h6" component="h2">
                Ayuda <b>Vrio</b>
              </Typography>
              <Typography id="modal-modal-Vrio" sx={{ mt: 2 }}>
                <h3>¿Dudas? ¿Consultas? Contacta a Service Desk llamando al 4357 (HELP)</h3>
                <br/>
                <h3>Teléfonos para comunicarse al Service Desk estando fuera de la compañía: </h3>
                <ul style={{ marginTop: '5%', marginLeft: '5%' }}>
                  <Tel num={'+541137514357'} title={'Argentina: +54 11 3751 4357'} />
                  <Tel num={'+56227407447'} title={'Chile: +56 227407447'} />
                  <Tel num={'+5715185936'} title={'Colombia: +57 1 518 5936'} />
                  <Tel num={'+5932989300'} title={'Ecuador: +593 2 989300'} />
                  <Tel num={'+5117078666'} title={'Perú: +51 17078666'} />
                  <Tel num={'+59826061361'} title={'Uruguay: +598 2606 1361'} />
                  <Tel num={'18682234388'} title={'Caribe: 18682234388 ext 4357'} />
                </ul>
              </Typography>
            </Box>
          </Modal>
        </div>
      </Container>


    </div>
  );
}

export default Help;