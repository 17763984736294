import React, { useEffect, useState } from "react";
import {
  Button,
  Modal
} from '@material-ui/core';
import { Formik, Form } from 'formik';

import { MyTable } from "../../components/MyTable";
import { agregarGerencia, getGerencias, deleteGerencia, editarGerencia } from '../../apis/GerenciasAPI'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { SWAL_ERROR, SWAL_SUCCESS, SWAL_SUCCESS_EDIT } from '../../components/SwalMessages';
import { MySelect, MyTextInput } from '../../components'
import swal from "sweetalert2";
import { validationGerencia } from "./validations";
import { useAbmStyles } from "./styles";
import { DefaultLayout } from "./container/DefaultLayout";
import { usePermisosUsuario } from "../../hooks/usePermisosUsuario";
import { useEmpresas } from "../../hooks/useEmpresas";
import { useTranslation } from "react-i18next";

const NuevaGerencia = ({ onClose, editar, selectedGer }) => {
  const { t } = useTranslation();
  const classes = useAbmStyles();
  const { empresas } = useEmpresas();

  function submit(data) {
    agregarGerencia(data)
      .then(res => {
        res?.data?.status === 400 ?
          SWAL_ERROR()
          : SWAL_SUCCESS()
        onClose()
      })

  }

  function guardarCambios(data) {
    editarGerencia(data)
      .then(res => {
        onClose()
        res?.data?.status === 400 ? SWAL_ERROR() : SWAL_SUCCESS_EDIT();
      })
  }

  return (
    <div align='center' style={{ paddingTop: 50 }}>
      <h1>{editar ? 'Editar' : 'Crear'} {t("gerencia>title>management")}</h1>
      <Formik
        initialValues={editar ? selectedGer : {
          Nombre: '',
          idEmpresa: null
        }}
        onSubmit={(values) => editar ? guardarCambios(values) : submit(values)}
        validationSchema={validationGerencia}>

        {(formik) => (
          <Form>

            <MyTextInput
              label={t("gerencia>label>management")}
              name="Nombre"
              placeholder={t("gerencia>placeholder>name")}
              type='string'
            />

            <MySelect label="Gerencia" name="idEmpresa" >
              <option value='' >{t("gerencia>option>company")}</option>
              {empresas && empresas.map(emp => {
                return <option key={emp.Id} value={emp.Id}>{emp.Nombre}</option>
              })}
            </MySelect>

            <button type="submit" className={classes.btn} >
              {editar ? `${t("gerencia>button>changes")}` : `${t("gerencia>button>confirm")}`}
            </button>
          </Form>
        )
        }
      </Formik>
    </div>
  )
}

const Gerencias = (props) => {
  const { t } = useTranslation();
  usePermisosUsuario([4])
  const [loading, setLoading] = useState(true)
  const classes = useAbmStyles();
  const [gerencias, setGerencias] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [editar, setEditar] = useState(false)
  const [selectedGer, setSelectedGer] = useState()

  useEffect(() => {
    setLoading(true);
    cargarGerencias();
  }, []);

  function cargarGerencias() {
    getGerencias()
      .then(res => {
        if (res?.data) {
          setGerencias(res?.data);
        }
      })
    setLoading(false);
  }

  const handleRefresh = () => {
    setModalOpen(false)
    setEditar(false);
    setSelectedGer();
    cargarGerencias()
  }

  const handleDelete = (gerencia) => {
    swal.fire({
      title: `${t("gerencia>title>delete")}`,
      text: `${t("gerencia>text>reverse")}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${t("gerencia>button>yes")}`
    }).then((result) => {
      if (result.isConfirmed) {
        deleteGerencia(gerencia.Id)
          .then(res => cargarGerencias())
      }
    })
  }

  const handleEdit = (gerencia) => {
    setEditar(true);
    setSelectedGer(gerencia)
    setModalOpen(true)
  }

  const handleNuevaGer = () => {
    setModalOpen(true)
    setEditar(false)
    setSelectedGer()
  }

  return (
    <DefaultLayout
      urlBack='/Abms'
      title='Gerencias'
      loading={loading}
    >
      {/* <Button
        onClick={() => setModalOpen(true)}
        component="span"
        startIcon={<AddCircleIcon />}
        variant="contained"
        className={classes.btn}
      >Nueva gerencia</Button> */}

      <Button
        onClick={handleNuevaGer}
        component="span"
        startIcon={<AddCircleIcon />}
        variant="contained"
        className={classes.btn}
      >{t("gerencia>button>newManagement")}</Button>

      <MyTable
        data={gerencias}
        headers={[`${t("gerencia>header>id")}`, `${t("gerencia>header>name")}`, `${t("gerencia>header>ideempresa")}`]}
        fields={['Id', 'Nombre', 'idEmpresa']}
        borrar
        handleDelete={handleDelete}
        edit
        handleEdit={handleEdit}
      />

      <Modal open={modalOpen}>
        <div className={classes.paper}>
          <Button className={classes.btnCancel} onClick={() => setModalOpen(false)}>x</Button>
          <div align='center' style={{ paddingTop: 50 }}>
            <NuevaGerencia
              onClose={handleRefresh}
              editar={editar}
              selectedGer={selectedGer}
            />
          </div>
        </div>
      </Modal>
    </DefaultLayout>

  );
}

export default Gerencias;